import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IParams, MULTIPLIER_MONTHLY_TO_YEARLY_INCOME, RentalRequestAssetType, Widget } from '../../../shared';
import { useRentalRequest } from '../../hooks';
import { calculateTotalIncome, formatAmount } from '../../utils';
import { DownloadAssets } from '../download-assets/download-assets.component';
import { Euro } from '@mui/icons-material';

export const RentalRequestFinancialReferenceIncome: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    const getIndex = useCallback(
        (taxYear: string) => {
            return (
                rentalRequest?.indexation?.currentIndexation
                    ?.sort((a, b) => (a.year > b.year ? -1 : 1))
                    .find((index) => index.year <= (parseInt(taxYear, 10) || 1) - 1)?.index ||
                rentalRequest?.indexation?.referenceIndexation.index ||
                1
            );
        },
        [rentalRequest],
    );

    const getIndexedAmount = useCallback(
        (income?: number, taxYear?: string) => {
            return (
                ((income || 0) / getIndex(taxYear || '')) * (rentalRequest?.indexation?.referenceIndexation?.index || 1)
            );
        },
        [rentalRequest, getIndex],
    );

    const rows = useMemo(() => {
        const result: any[] = [];
        rentalRequest?.residents
            ?.filter((res) => res.isAdult)
            .forEach((res) => {
                if (res?.financialData?.hasTaxBillForFullYear) {
                    result.push(
                        {
                            id: `${res?.id}jointTaxableIncome}`,
                            person: `${res?.firstName} ${res?.lastName}`,
                            taxYear: res?.financialData?.taxYear,
                            income: t('jointTaxableIncome'),
                            documents: res?.financialData?.rentalRequestAssets,
                            documentType: RentalRequestAssetType?.TAX_BILL_DOCUMENT,
                            yearlyAmount: res?.financialData?.jointTaxableIncome,
                            indexedAmount: getIndexedAmount(
                                res?.financialData?.jointTaxableIncome,
                                res?.financialData?.taxYear,
                            ),
                        },
                        {
                            id: `${res?.id}individualIncome}`,
                            income: t('individualIncome'),
                            yearlyAmount: res?.financialData?.individualTaxableIncome,
                            indexedAmount: getIndexedAmount(
                                res?.financialData?.individualTaxableIncome,
                                res?.financialData?.taxYear,
                            ),
                        },
                    );
                } else {
                    result.push({
                        id: `${res?.id}monthlyTaxableIncome}`,
                        person: `${res?.firstName} ${res?.lastName}`,
                        taxYear: res?.financialData?.taxYear,
                        income: t('monthlyTaxableIncome'),
                        documents: res?.financialData?.rentalRequestAssets,
                        documentType: RentalRequestAssetType?.MONTHLY_INCOME_DOCUMENT,
                        monthlyAmount: res?.financialData?.monthlyTaxableIncome || 0,
                        yearlyAmount:
                            (res?.financialData?.monthlyTaxableIncome || 0) * MULTIPLIER_MONTHLY_TO_YEARLY_INCOME,
                        indexedAmount: getIndexedAmount(
                            (res?.financialData?.monthlyTaxableIncome || 0) * MULTIPLIER_MONTHLY_TO_YEARLY_INCOME,
                            res?.financialData?.taxYear,
                        ),
                    });
                }

                if (res?.financialData?.hasLivingWage) {
                    result.push({
                        id: `${res?.id}livingWage}`,
                        income: t('livingWage'),
                        documents: res?.financialData?.rentalRequestAssets,
                        documentType: RentalRequestAssetType?.LIVING_WAGE_DOCUMENT,
                        yearlyAmount: res?.financialData?.livingWage,
                        indexedAmount: getIndexedAmount(res?.financialData?.livingWage, res?.financialData?.taxYear),
                    });
                }

                if (res?.financialData?.hasDisabilityIncome) {
                    result.push({
                        id: `${res?.id}disabilityIncome}`,
                        income: t('disabilityIncome'),
                        documents: res?.financialData?.rentalRequestAssets,
                        documentType: RentalRequestAssetType.DISABILITY_INCOME_DOCUMENT,
                        yearlyAmount: res?.financialData?.disabilityIncome,
                        indexedAmount: getIndexedAmount(
                            res?.financialData?.disabilityIncome,
                            res?.financialData?.taxYear,
                        ),
                    });
                }

                if (res?.financialData?.hasForeignIncome) {
                    result.push({
                        id: `${res?.id}foreignIncome}`,
                        income: t('foreignIncome'),
                        yearlyAmount: res?.financialData?.foreignIncome,
                        indexedAmount: getIndexedAmount(res?.financialData?.foreignIncome, res?.financialData?.taxYear),
                    });
                }
            });

        result.push({
            id: 'total',
            documents: t('total'),
            yearlyAmount: calculateTotalIncome(rentalRequest?.residents || []),
            indexedAmount: rentalRequest?.referenceIncome,
        });
        return result;
    }, [t, rentalRequest, getIndexedAmount]);

    const columns: GridColDef[] = [
        {
            field: 'person',
            headerName: t('person'),
            minWidth: 200,
            sortable: false,
            flex: 1,
        },
        {
            field: 'taxYear',
            headerName: t('incomeYear'),
            minWidth: 100,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => {
                if (params.row?.taxYear && Number(params.row?.taxYear)) {
                    return Number(params.row.taxYear) - 1;
                }
                return false;
            },
        },
        {
            field: 'income',
            headerName: t('income'),
            minWidth: 400,
            sortable: false,
            flex: 1.5,
        },
        {
            field: 'documents',
            headerName: t('documents'),
            minWidth: 120,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (typeof params?.row?.documents === 'string') {
                    return <>{params?.row?.documents}</>;
                }
                if (params?.row?.documents && params?.row?.documentType) {
                    return (
                        <DownloadAssets
                            rentalRequestAssets={params?.row?.documents}
                            rentalRequestAssetType={params?.row?.documentType}
                        />
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            field: 'monthlyAmount',
            headerName: t('monthlyAmount'),
            headerAlign: 'right',
            minWidth: 100,
            flex: 0.7,
            sortable: false,
            align: 'right',
            valueFormatter: (value: number) => value && `€ ${formatAmount(value)}`,
        },
        {
            field: 'yearlyAmount',
            headerName: t('yearlyAmount'),
            headerAlign: 'right',
            minWidth: 100,
            flex: 0.7,
            sortable: false,
            align: 'right',
            valueFormatter: (value: number) => value && `€ ${formatAmount(value)}`,
        },
        {
            field: 'indexedAmount',
            headerName: t('indexedAmount', { year: rentalRequest?.indexation?.referenceIndexation.year }),
            headerAlign: 'right',
            minWidth: 100,
            flex: 1,
            sortable: false,
            align: 'right',
            valueFormatter: (value: number) => `€ ${formatAmount(value)}`,
        },
    ];

    return (
        <Widget icon={<Euro color="primary" />} title={t('referenceIncome')} hasTable>
            <Box
                sx={{
                    '& .row-total': {
                        fontWeight: 'bold',
                        borderTop: '1px solid rgba(0, 0, 0, 0.6)',
                    },
                }}
            >
                <DataGrid
                    loading={isLoading}
                    columns={columns}
                    rows={rows}
                    autoHeight
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    getRowClassName={(params) => `row-${params.row.id}`}
                />
            </Box>
        </Widget>
    );
};
