import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, IAllocationTrack, useCloseAllocationTrack } from '../../../shared';

interface Props {
    show: boolean;
    allocationTrack: IAllocationTrack;
    handleClose: () => void;
}

export const CloseAllocationTrackModal: FC<Props> = ({ show, allocationTrack, handleClose }) => {
    const { t } = useTranslation();

    const { mutateAsync: closeAllocationTrack } = useCloseAllocationTrack();

    const onSubmit = async () => {
        await closeAllocationTrack({ id: allocationTrack.id || '' });
    };

    return (
        <Dialog open={show}>
            <form onSubmit={onSubmit} noValidate>
                <DialogTitleWithClose onClose={handleClose}>{t('closeAllocationTrack')}</DialogTitleWithClose>
                <DialogContent>
                    <Box sx={{ minWidth: 500 }}>
                        <p>
                            {t('closeAllocationTrackWarningText', {
                                propertyTypeName: allocationTrack.propertyType?.name,
                            })}
                        </p>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained">
                        {t('confirm')}
                    </Button>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
