import { Alert, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IAdditionalQuestion, Widget } from '../../../shared';

interface Props {
    unansweredQuestions: IAdditionalQuestion[];
}

export const AdditionalQuestionWarnings: FC<Props> = ({ unansweredQuestions }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('unansweredAdditionalQuestions')}>
            <Stack spacing={2}>
                {unansweredQuestions.map((question) => (
                    <Alert key={question.id} severity="warning">
                        {question.question}
                    </Alert>
                ))}
            </Stack>
        </Widget>
    );
};
