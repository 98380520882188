import { Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTemplate } from '../../../shared';

type Props = {
    id?: string;
    title?: string;
};

export const TemplatePreview: FC<Props> = ({ id, title }) => {
    const { t } = useTranslation();
    const { data: detailsTemplate } = useTemplate(id);

    return (
        <>
            <Typography variant="subtitle2">
                {t('preview')} {title}
            </Typography>
            <Typography>
                {t('subject')}: {detailsTemplate?.subject}
            </Typography>
            <Typography>{t('content')}:</Typography>
            {detailsTemplate?.thumbnail_url ? (
                <div dangerouslySetInnerHTML={{ __html: detailsTemplate.html_content }} />
            ) : (
                <Skeleton variant="rectangular" width={700} height={500} animation={id ? 'wave' : false} />
            )}
        </>
    );
};
