import { Components, createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createBaseTheme } from './base.theme';
import { ITheme } from '../../shared';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { getComponents, getPalette, getTypography } from '../utils';
import { transparentize } from 'polished';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const createLeuvenTheme = (theme: ITheme): Theme => {
    const palette = getPalette(theme.color, paletteOverride());
    const leuvenTheme = createTheme({ palette });
    const typography = getTypography(leuvenTheme, typographyOverride());
    const components = getComponents(leuvenTheme, componentOverride(leuvenTheme));

    return deepmerge(
        createBaseTheme(theme),
        createTheme(leuvenTheme, { shape: { borderRadius: 0 }, palette, typography, components }),
    );
};

function paletteOverride(): PaletteOptions {
    return { secondary: { main: '#F59C13', shade: transparentize(0.85, '#F59C13') } };
}

function typographyOverride(): TypographyOptions {
    return { fontFamily: '"Strawford", sans-serif' };
}

function componentOverride({ palette }: Theme): Components {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '0 !important',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: 0,
                    [`@media screen and (min-width: 1300px)`]: {
                        padding: '0 1rem',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: '40px',
                    [':focus']: { color: 'black', backgroundColor: palette.secondary.main },
                },
            },
        },
        MuiChip: {
            defaultProps: {
                variant: 'outlined',
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    borderWidth: 1,
                    borderRadius: 0,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: '249px',
                    paddingTop: '2rem',
                    [`@media screen and (min-width: 1300px)`]: {
                        width: '264px',
                        paddingTop: '1rem',
                        paddingLeft: '1rem',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.secondary.main,
                        },
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '& .MuiIconButton-root': { marginLeft: 12 },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: '"Strawford", sans-serif',
                },
            },
        },
    };
}
