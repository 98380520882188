import { Box, Pagination } from '@mui/material';
import {
    GridFooterContainer,
    gridPageSelector,
    gridPageSizeSelector,
    GridPagination,
    gridRowCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function CustomTableFooter() {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const page = useGridSelector(apiRef, gridPageSelector);
    const pages = Math.ceil(useGridSelector(apiRef, gridRowCountSelector) / pageSize);

    return (
        <GridFooterContainer>
            <Box sx={{ ml: 2 }}>
                {t('total')}: {apiRef.current.getRowsCount()}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <GridPagination
                    sx={{ '.MuiTablePagination-displayedRows, .MuiTablePagination-actions': { display: 'none' } }}
                />
                <Pagination
                    count={pages == 0 ? 1 : pages}
                    page={!pages || pages == 0 ? 1 : page + 1}
                    onChange={(e, value) => apiRef.current.setPage(value - 1)}
                    size="small"
                    shape="rounded"
                    siblingCount={0}
                />
            </Box>
        </GridFooterContainer>
    );
}
