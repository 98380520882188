import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AdminPage,
    CommunicationSettingsPage,
    IncomeSettingsPage,
    IndexationEditPage,
    IndexationsPage,
    PropertyTypeEditPage,
    PropertyTypesPage,
    MailMessagesPage,
    MailMessageEditPage,
} from './pages';

export const adminRoutes: IRouteConfig[] = [
    { path: '/admin', redirect: '/admin/indexations' },
    { path: '/admin/*', component: AdminPage },
];

export const adminSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/indexations', component: IndexationsPage, requiredPermissions: [PermissionKeys.INDEXATIONS_READ] },
    {
        path: '/indexations/:id/edit',
        component: IndexationEditPage,
        requiredPermissions: [PermissionKeys.INDEXATIONS_WRITE],
    },
    {
        path: '/indexations/new',
        component: IndexationEditPage,
        requiredPermissions: [PermissionKeys.INDEXATIONS_WRITE],
    },
    {
        path: '/income-settings',
        component: IncomeSettingsPage,
        requiredPermissions: [PermissionKeys.INCOME_SETTINGS_READ],
    },
    {
        path: '/mail-messages',
        component: MailMessagesPage,
        requiredPermissions: [PermissionKeys.MAIL_MESSAGES_READ],
    },
    {
        path: '/mail-messages/new',
        component: MailMessageEditPage,
        requiredPermissions: [PermissionKeys.MAIL_MESSAGES_WRITE],
    },
    {
        path: '/mail-messages/:id/edit',
        component: MailMessageEditPage,
        requiredPermissions: [PermissionKeys.MAIL_MESSAGES_WRITE],
    },
    {
        path: '/communication-settings',
        component: CommunicationSettingsPage,
        requiredPermissions: [PermissionKeys.COMMUNICATION_SETTINGS_READ],
    },
    {
        path: '/property-types',
        component: PropertyTypesPage,
        requiredPermissions: [PermissionKeys.PROPERTY_TYPES_READ],
    },
    {
        path: '/property-types/:id/edit',
        component: PropertyTypeEditPage,
        requiredPermissions: [PermissionKeys.PROPERTY_TYPES_WRITE],
    },
    {
        path: '/property-types/new',
        component: PropertyTypeEditPage,
        requiredPermissions: [PermissionKeys.PROPERTY_TYPES_WRITE],
    },
];
