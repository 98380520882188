import { IAllocation, RentalRequestStatus } from '../../shared';

export const compareCandidates = (a: IAllocation, b: IAllocation) => {
    if (a.rentalRequest && b.rentalRequest) {
        if (a.rentalRequest.status === RentalRequestStatus.ALLOCATED) {
            return 1;
        }

        if (b.rentalRequest.status === RentalRequestStatus.ALLOCATED) {
            return -1;
        }
        return (
            (b.rentalRequest.score || 0) - (a.rentalRequest.score || 0) ||
            (a.rentalRequest.referenceIncome || 0) - (b.rentalRequest.referenceIncome || 0) ||
            (a.rentalRequest.submittedDate?.toString() || '').localeCompare(
                b.rentalRequest.submittedDate?.toString() || '',
            )
        );
    } else if (a.rentalRequest) {
        return -1;
    }
    return 1;
};
