import { Button, Grid } from '@mui/material';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, RemoveModal } from '../../../shared';
import { PropertyAllocations, PropertyDetailGeneral, PropertyDetailRent } from '../../components';
import { useDeleteProperty, useProperty } from '../../hooks';

export const PropertyDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: property, isFetching } = useProperty(id);
    const { mutateAsync: deleteProperty } = useDeleteProperty();

    const onEdit = useCallback(async () => {
        navigate(`/properties/${id}/edit`);
    }, [id, navigate]);

    const onDelete = useCallback(async () => {
        await deleteProperty(id);
        navigate('/properties');
    }, [deleteProperty, navigate, id]);

    const actions = useMemo(
        () => [
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={<Button variant="outlined">{t('delete')}</Button>}
                        title={t('propertyDeleteWarningTitle')}
                        text={t('propertyDeleteWarningText')}
                    />
                )}
            </Fragment>,
            <Button key={'submit'} variant="contained" onClick={onEdit} disabled={isFetching}>
                {t('edit')}
            </Button>,
        ],
        [t, isFetching, id, onDelete, onEdit],
    );

    return (
        <Page onBack={() => navigate(-1)} title={property?.name} loading={isFetching} actions={actions}>
            {property && (
                <>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={8}>
                            <PropertyDetailGeneral property={property} />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <PropertyAllocations property={property} />
                        </Grid>
                    </Grid>
                    <PropertyDetailRent property={property} />
                </>
            )}
        </Page>
    );
};
