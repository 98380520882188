import { ContactsTwoTone } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, LabelValue, Widget } from '../../../shared';

interface Props {
    rentalRequest: IRentalRequest;
}

export const ContactInfo: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    return (
        <Widget icon={<ContactsTwoTone color="primary" />} title={t('contactInformation')}>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <LabelValue label={t('email')} value={rentalRequest.email} />
                </Grid>
                <Grid item>
                    <LabelValue label={t('phone')} value={rentalRequest.phone} />
                </Grid>
                <Grid item>
                    <LabelValue label={t('contactPreference')} value={t(rentalRequest.contactType)} />
                </Grid>
            </Grid>
        </Widget>
    );
};
