import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IParams, RentalRequestAssetType, Widget } from '../../../shared';
import { useRentalRequest } from '../../hooks';
import { DownloadAssets } from '../download-assets/download-assets.component';
import { Description } from '@mui/icons-material';
import { mapAdditionalFinancialAssets } from '../../mappers';

export const RentalRequestAdditionalFinancialAssets: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    const mappedAssets = mapAdditionalFinancialAssets(rentalRequest);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('person'),
            minWidth: 200,
            sortable: false,
            flex: 1,
        },
        {
            field: 'type',
            headerName: t('assetType'),
            minWidth: 200,
            sortable: false,
            flex: 1,
            valueFormatter: (value: RentalRequestAssetType) => t(`financialAsset.${value}`),
        },
        {
            field: 'validUntil',
            headerName: t('validUntil'),
            minWidth: 200,
            sortable: false,
            flex: 1,
        },
        {
            field: 'documents',
            headerName: t('documents'),
            minWidth: 120,
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => {
                if (row?.assets) {
                    return <DownloadAssets rentalRequestAssets={row?.assets} rentalRequestAssetType={row?.type} />;
                }
            },
        },
    ];

    return (
        <Widget icon={<Description color="primary" />} title={t('additionalFinancialAssets')} hasTable>
            <DataGrid
                loading={isLoading}
                columns={columns}
                rows={mappedAssets}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                hideFooter
                getRowClassName={(params) => `row-${params.row.id}`}
                localeText={{ noRowsLabel: t('noRows') }}
            />
        </Widget>
    );
};
