import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AllocationTrackCandidatesPage,
    AllocationTrackPage,
    AllocationTrackPropertiesPage,
    AllocationTracksPage,
} from './pages';

export const allocationRoutes: IRouteConfig[] = [
    {
        path: '/allocation-tracks',
        component: AllocationTracksPage,
        requiredPermissions: PermissionKeys.ALLOCATION_TRACKS_READ,
    },
    {
        path: '/allocation-tracks/:id',
        redirect: 'candidates',
    },
    {
        path: '/allocation-tracks/:id/*',
        component: AllocationTrackPage,
        requiredPermissions: PermissionKeys.ALLOCATION_TRACKS_READ,
    },
];

export const allocationTrackSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/candidates', component: AllocationTrackCandidatesPage },
    { path: '/properties', component: AllocationTrackPropertiesPage },
];
