import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { usersClient } from '../clients';
import { QueryKeys } from '../enums';
import { IUser, ListModelItems } from '../models';

const client = usersClient;
const listKey = QueryKeys.AuthManagers;

export function useManagerList() {
    const { t } = useTranslation();
    return useQuery<ListModelItems<IUser>, AxiosError>({
        queryKey: [listKey],
        queryFn: () => client.getManagers(t('getManagersError')),
    });
}
