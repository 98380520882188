import { IAllocationTrackForm, ISaveAllocationTrack, IUser } from '../../shared';

export const allocationTrackFromFormMapper = (
    allocationTrack: IAllocationTrackForm,
    user?: IUser,
): ISaveAllocationTrack => {
    return {
        ...allocationTrack,
        allocationManager: {
            userId: allocationTrack.userId,
            name: user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email || '',
        },
    };
};
