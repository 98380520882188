import { Box, Dialog, DialogContent, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, formatDateTimeString, IMailLog, LabelValue } from '../../../shared';

interface Props {
    show: boolean;
    mail: IMailLog | null;
    handleClose: () => void;
}
export const DetailMail: FC<Props> = ({ show, mail, handleClose }) => {
    const { t } = useTranslation();

    const mapParameters = (parameters: any, spacing = 0) => {
        return (
            parameters !== null &&
            parameters !== undefined && (
                <>
                    {Object.entries(parameters).map((entry) => (
                        <Box key={entry[0]} sx={{ ml: spacing }}>
                            - {entry[0]} :{' '}
                            {typeof entry[1] !== 'object' ? <>{entry[1]}</> : mapParameters(entry[1], spacing + 2)}
                        </Box>
                    ))}
                </>
            )
        );
    };

    return (
        <Dialog open={show} onClose={handleClose}>
            {mail && (
                <>
                    <DialogTitleWithClose onClose={handleClose}> {t('detailMail')}</DialogTitleWithClose>
                    <DialogContent>
                        <Stack spacing={2} sx={{ minWidth: 500 }}>
                            <LabelValue label={t('logTime')} value={formatDateTimeString(mail.createdDate)} />
                            <LabelValue label={t('to')} value={mail.to} />
                            <LabelValue label={t('template')} value={mail.templateName} />
                            <LabelValue label={t('createdBy')} value={mail.createdBy} />
                            <LabelValue
                                label={t('parameters')}
                                value={
                                    <Stack direction="column" component="span">
                                        {mail?.parameters && mapParameters(mail.parameters)}
                                    </Stack>
                                }
                            />
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};
