import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { formatAmount } from '../../../rental-requests/utils';
import { Page, SortOrder, Table, useQueryParams } from '../../../shared';
import { useIndexationList } from '../../hooks';

export const IndexationsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'year', sort: 'desc' }],
    });

    const { data: indexations, isLoading: indexationsIsLoading } = useIndexationList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'year',
            headerName: t('year'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'index',
            headerName: t('index'),
            minWidth: 200,
            flex: 1,
            sortable: true,
            valueFormatter: (value: number) => formatAmount(value),
        },
    ];

    return (
        <Page
            title={t('indexations')}
            actions={[
                <Button component={Link} to="/admin/indexations/new" variant="contained" data-testid="new-indexation">
                    {t('new-indexation')}
                </Button>,
            ]}
        >
            <Table
                rows={indexations?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={indexations?.pagination.size || 0}
                loading={indexationsIsLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/admin/indexations/${row.id}/edit`)}
            />
        </Page>
    );
};
