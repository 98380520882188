import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledInput, ControlledNumberInput, FormGrid, IParams, Page } from '../../../shared';
import { useIndexation, useSaveIndexation } from '../../hooks';
import { indexationFromFormMapper, indexationToFormMapper } from '../../mapper';
import { IIndexationForm } from '../../models';
import { useIndexationSchema } from '../../validators';

export const IndexationEditPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: indexation, isFetching: isFetchingItem } = useIndexation(id);

    const form = useForm<IIndexationForm>({
        mode: 'all',
        resolver: yupResolver(useIndexationSchema()),
    });
    const { mutateAsync: saveIndexation, isPending: isLoadingSave } = useSaveIndexation();

    useEffect(() => {
        if (indexation) {
            form.reset(indexationToFormMapper(indexation));
        }
    }, [indexation, form]);

    const onSubmit = useCallback(
        async (item: IIndexationForm) => {
            await saveIndexation({ id, item: indexationFromFormMapper(item) });
            navigate('/admin/indexations');
        },
        [id, navigate, saveIndexation],
    );

    const actions = useMemo(
        () => [
            <Button key={'submit'} variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetchingItem}>
                {t('save')}
            </Button>,
            <Button key={'cancel'} onClick={() => navigate('/admin/indexations')}>
                {t('cancel')}
            </Button>,
        ],
        [t, form, onSubmit, navigate, isFetchingItem],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate('/admin/indexations')}
            title={indexation?.year ? t('update-indexation') : t('new-indexation')}
            loading={isFetchingItem || isLoadingSave}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormGrid>
                                <ControlledInput name={`year`} label={t('year')} required />
                                <ControlledNumberInput name={`index`} label={t('index')} required />
                            </FormGrid>
                        </CardContent>
                        <CardActions sx={{ bgcolor: 'background.default' }}>{actions}</CardActions>
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
