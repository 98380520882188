import { MenuItem, Stack } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, EligibleStatus, ListQueryParam, RentalRequestStatus } from '../../../shared';

export interface IRentalRequestFilter extends ListQueryParam {
    status?: RentalRequestStatus | 'all';
    eligibleStatus?: EligibleStatus | 'all';
}

interface Props {
    filter?: IRentalRequestFilter;
    onChange: (filter: IRentalRequestFilter) => void;
    defaultValues?: IRentalRequestFilter;
}

export const RentalRequestsFilter: FC<Props> = ({ filter, onChange, defaultValues = {} }) => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;
    const form = useForm<IRentalRequestFilter>({
        mode: 'onChange',
        defaultValues: filter && (!Object.values(filter).length ? defaultValues : filter),
    });

    const formValues = form.watch();

    const hasFilterFormChanged = useMemo(() => {
        return JSON.stringify(formValues) !== JSON.stringify(filter);
    }, [filter, formValues]);

    useEffect(() => {
        hasFilterFormChanged && onChange(formValues);
    }, [formValues, onChange, hasFilterFormChanged]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <ControlledSelect
                    name="eligibleStatus"
                    label={t('eligibleStatus')}
                    inline
                    sx={{ mr: theme.name === 'antwerp' ? 4 : 1 }}
                >
                    <MenuItem value="all">{t('all')}</MenuItem>
                    <MenuItem value={EligibleStatus.ELIGIBLE}>{t('yes')}</MenuItem>
                    <MenuItem value={EligibleStatus.NOT_ELIGIBLE}>{t('no')}</MenuItem>
                    <MenuItem value={EligibleStatus.UNABLE_TO_CALCULATE}>
                        {t(EligibleStatus.UNABLE_TO_CALCULATE)}
                    </MenuItem>
                </ControlledSelect>
                <ControlledSelect name="status" label={t('status')} inline>
                    <MenuItem value="all">{t('all')}</MenuItem>
                    {Object.values(RentalRequestStatus).map((status) => (
                        <MenuItem value={status} key={status}>
                            {t(status)}
                        </MenuItem>
                    ))}
                </ControlledSelect>
            </Stack>
        </FormProvider>
    );
};
