import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, lazy, object, string } from 'yup';

export function useDisapproveRentalRequestSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                mailMessages: lazy(() =>
                    array()
                        .of(string().required())
                        .required()
                        .when('message', {
                            is: '',
                            then: (schema) => schema.min(1, t('reasonsRequired')).required(),
                        }),
                ),
                message: lazy(() =>
                    string().when('mailMessages', {
                        is: [],
                        then: (schema) => schema.required(),
                    }),
                ),
            }),
        [t],
    );
}
