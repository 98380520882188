import React, { FC } from 'react';
import { FormControl, FormHelperText, FormLabel, RadioGroup, RadioGroupProps, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props extends RadioGroupProps {
    name: string;
    label?: string;
    labelHelpText?: string;
    disabled?: boolean;
    required?: boolean;
}

export const ControlledRadioGroup: FC<Props> = ({
    children,
    name,
    label,
    labelHelpText,
    defaultValue,
    disabled,
    required,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <FormControl disabled={disabled}>
            {label && (
                <FormLabel required={required}>
                    <Typography component="span">{label}</Typography>
                    {labelHelpText && (
                        <Typography variant="caption" component="span">
                            {labelHelpText}
                        </Typography>
                    )}
                </FormLabel>
            )}
            <Controller
                name={name}
                defaultValue={defaultValue || null}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <>
                        <RadioGroup value={value} onBlur={onBlur} onChange={onChange} {...rest}>
                            {children}
                        </RadioGroup>
                        {error && (
                            <FormHelperText error>{error.message || t('shared.form.fallbackError')}</FormHelperText>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};
