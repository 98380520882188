import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import { ErrorFallback } from '../../../main';
import { IParams, SideBar, SideNav } from '../../../shared';
import { useRentalRequest } from '../../hooks';
import { rentalRequestSubRoutes } from '../../rental-requests.routes';

export const RentalRequestPage: FC = () => {
    const { t } = useTranslation();
    const { bondWithTheCity, theme } = window.carity.environment;

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest } = useRentalRequest(id);

    useEffect(() => {
        document.title = t('pages.file', { context: theme.name });
    }, [t, theme]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: 'info', text: t('generalInfo') },
                        { path: 'financial', text: t('financial', { context: theme.name }) },
                        ...(bondWithTheCity ? [{ path: 'bond-with-the-city', text: t('bondWithTheCity') }] : []),
                        { path: 'preferences', text: t('residence') },
                        {
                            path: 'additional-questions',
                            text: `${t('additionalQuestions')} (${rentalRequest?.additionalQuestions?.length || 0})`,
                        },
                        { path: 'communication', text: t('communication') },
                        { path: 'refusals', text: `${t('refusals')} (${rentalRequest?.refusalsCount || 0})` },
                        { path: 'warnings', text: t('warnings') },
                        { path: 'logs', text: t('logs') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {rentalRequestSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    {<subRoute.component />}
                                </ErrorBoundary>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
