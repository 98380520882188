import { Box, Grid, IconButton, Divider, Typography, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';
import { useBreakpoint } from '../../hooks';
import { Loading } from '../loading/loading.component';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    filter?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode | ReactNode[];
    onBack?: MouseEventHandler;
    loading?: boolean;
}

export const Page: FC<Props> = ({ actions, onBack, title, subTitle, filter, children, loading }) => {
    const mappedActions = useMemo(() => (Array.isArray(actions) ? actions : [actions]), [actions]);
    const smallScreen = useBreakpoint('sm');

    return (
        <>
            <Box id="page-component" sx={{ mb: smallScreen ? '0' : '75px', minWidth: '1000px' }}>
                {title && (
                    <>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="nowrap"
                            sx={{
                                pl: 3,
                                pr: 3,
                                backgroundColor: 'white',
                                minHeight: '80px',
                                '.MuiInputBase-input': { mt: 0 },
                            }}
                        >
                            <Grid item pr={0} pl={1} pt={0}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {onBack && (
                                        <IconButton
                                            color="inherit"
                                            aria-label="back button"
                                            onClick={onBack}
                                            size="large"
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    )}

                                    <Typography variant="subtitle1" color="text.secondary">
                                        {title}
                                    </Typography>

                                    {subTitle && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {subTitle}
                                        </Typography>
                                    )}
                                    {filter}
                                </Stack>
                            </Grid>
                            {mappedActions && (
                                <Grid
                                    container
                                    flexWrap="nowrap"
                                    spacing={1}
                                    pr={2}
                                    sx={{
                                        width: 'fit-content',
                                        '.MuiGrid-item': { pr: 0, mb: 0 },
                                    }}
                                    className="actionBar"
                                >
                                    {mappedActions.map((action, index) => (
                                        <Grid item key={index}>
                                            {action}
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                        <Divider />
                    </>
                )}
                <Box sx={{ p: 3, maxHeight: title ? 'calc(100vh - 140px)' : 'calc(100vh - 60px)', overflow: 'auto' }}>
                    <Box>{loading ? <Loading /> : children}</Box>
                </Box>
            </Box>
        </>
    );
};
