import { Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AllocationTrackStatus, IParams, Page, PropertyStatus, useAllocationTrack } from '../../../shared';
import {
    ActiveAllocations,
    AllocationTrackAddCandidatesModal,
    AllocationTrackInformationAlert,
    AllocationTrackTitle,
    CloseAllocationTrackModal,
    DeniedAllocations,
} from '../../components';
import { compareCandidates } from '../../util';

export const AllocationTrackCandidatesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const [showCloseAllocationTrack, setShowCloseAllocationTrack] = useState<boolean>(false);
    const [showAddCandidates, setShowAddCandidates] = useState(false);

    const { data: allocationTrack, isLoading } = useAllocationTrack(id);

    const activeAllocations = allocationTrack?.allocations
        ?.sort(compareCandidates)
        .filter((allocation) => !allocation.refusal);

    const hasAllAllocatedProperties = allocationTrack?.properties.every(
        (property) => property.status === PropertyStatus.ALLOCATED,
    );

    const trackNotClosedWithProperties =
        allocationTrack?.status !== AllocationTrackStatus.CLOSED && (allocationTrack?.properties?.length || 0) > 0;

    const canCloseAllocationTrack = trackNotClosedWithProperties && hasAllAllocatedProperties;

    return (
        <Page
            title={
                allocationTrack && <AllocationTrackTitle subtitle={t('candidates')} allocationTrack={allocationTrack} />
            }
            onBack={() => navigate(-1)}
            actions={[
                <Button
                    onClick={() => {
                        setShowCloseAllocationTrack(true);
                    }}
                    variant="contained"
                    sx={{
                        display: canCloseAllocationTrack ? 'inline-flex' : 'none',
                    }}
                >
                    {t('closeAllocationTrack')}
                </Button>,
                <Button
                    onClick={() => setShowAddCandidates(true)}
                    variant="contained"
                    sx={{
                        display: trackNotClosedWithProperties ? 'inline-flex' : 'none',
                    }}
                >
                    {t('addCandidateToAllocationTrack')}
                </Button>,
            ]}
            loading={isLoading}
        >
            {allocationTrack && (
                <>
                    <AllocationTrackAddCandidatesModal
                        open={showAddCandidates}
                        handleClose={() => setShowAddCandidates(false)}
                        allocationTrack={allocationTrack}
                    />

                    <CloseAllocationTrackModal
                        show={showCloseAllocationTrack}
                        allocationTrack={allocationTrack}
                        handleClose={() => setShowCloseAllocationTrack(false)}
                    />

                    <AllocationTrackInformationAlert
                        amountOfProperties={allocationTrack?.properties?.length}
                        amountOfCandidates={activeAllocations?.length}
                    />

                    <ActiveAllocations allocationTrack={allocationTrack} allocations={activeAllocations} />

                    <DeniedAllocations allocationTrackId={id} allocations={allocationTrack?.allocations} />
                </>
            )}
        </Page>
    );
};
