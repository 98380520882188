import { Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
    error: any;
}

export const ErrorFallback: FC<Props> = ({ error }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <p>{t('shared.somethingWentWrong')}</p>
            <pre>{error.message}</pre>
            <Button onClick={() => navigate(-1)} variant="contained">
                {t('shared.back')}
            </Button>
        </>
    );
};
