import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useApproveRentalRequest, useCancelVerification, useStartVerification } from '../../hooks';
import { RequestChangesModal } from '../request-changes/request-changes-modal.component';
import { DisapproveRequestModal } from '../disapprove-request/disapprove-request-modal.component';
import { RentalRequestStatus } from '../../../shared';

interface Props {
    id: string;
    status?: RentalRequestStatus;
}

export const RentalRequestVerificationActions: FC<Props> = ({ id, status }) => {
    const { t } = useTranslation();
    const { mutateAsync: startVerification } = useStartVerification();
    const { mutateAsync: cancelVerification } = useCancelVerification();
    const { mutateAsync: approveRentalRequest } = useApproveRentalRequest();

    if (status === RentalRequestStatus.SUBMITTED) {
        return (
            <Button onClick={() => startVerification({ id })} variant="contained">
                {t('startVerification')}
            </Button>
        );
    }

    if (status === RentalRequestStatus.IN_VERIFICATION || status === RentalRequestStatus.CHANGES_REQUESTED) {
        return (
            <>
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => cancelVerification({ id })} color="primary" variant="text">
                        {t('cancelVerification')}
                    </Button>

                    <DisapproveRequestModal id={id} />
                    <RequestChangesModal id={id} />

                    <Button onClick={() => approveRentalRequest({ id })} color="primary" variant="contained">
                        {t('approveRentalRequest')}
                    </Button>
                </Stack>
            </>
        );
    }

    return <></>;
};
