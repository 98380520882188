import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { QueryKeys } from '../../shared';
import { IDashboardChart } from '../models';

async function getChartsByDashboard(id: number): Promise<IDashboardChart[]> {
    const { data } = await axios.get(`/reporting/dashboard/${id}/charts`);
    return data;
}

export const useChartsByDashboard = (id: number) => {
    return useQuery<IDashboardChart[], AxiosError>({
        queryKey: [QueryKeys.ChartsByDashboard, id],
        queryFn: () => getChartsByDashboard(id),
    });
};
