import { useMemo } from 'react';
import { mixed, object, string } from 'yup';
import { ContactType } from '../../shared';

export function useInviteSchema() {
    return useMemo(
        () =>
            object().shape({
                propertyId: string().required(),
                contactType: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
            }),
        [],
    );
}
