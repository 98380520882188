import { GroupTwoTone } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IProperty, IResident, Widget } from '../../../shared';
import { Button, Stack } from '@mui/material';
import { CreateAllocationTrackModal } from '../../../allocation/components';

interface Props {
    property: IProperty;
}

export const PropertyAllocations: FC<Props> = ({ property }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showCreateAllocationTrack, setShowAllocationTrack] = useState(false);

    const columns: GridColDef[] = [
        {
            field: 'rentalRequest',
            headerName: t('name'),
            sortable: false,
            renderCell: (params) => {
                const resident = params.row.rentalRequest?.residents.find((res: IResident) => res.isPrimaryResident);
                return (
                    <Link
                        to={`/rental-requests/${params.row.rentalRequest.id}/info`}
                        style={{ textDecoration: 'none' }}
                    >
                        {resident?.firstName} {resident?.lastName}
                    </Link>
                );
            },
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('status'),
            valueFormatter: (value: string) => t(value),
            sortable: false,
            flex: 1,
        },
    ];
    return (
        <Stack
            spacing={3}
            height="100%"
            sx={{ '.MuiPaper-root:first-of-type': { height: 'fit-content', overflow: 'visible' } }}
        >
            <Widget title={t('currentAllocationTrack')} icon={<GroupTwoTone color="primary" />}>
                {property.allocationTrack ? (
                    <Button
                        onClick={() => navigate(`/allocation-tracks/${property.allocationTrack?.id}/candidates`)}
                        variant="contained"
                        fullWidth
                    >
                        {t('toCurrentAllocationTrack')}
                    </Button>
                ) : (
                    <Button onClick={() => setShowAllocationTrack(true)} variant="outlined" fullWidth>
                        {t('newAllocationTrack')}
                    </Button>
                )}
            </Widget>
            <Widget title={t('allocations')} icon={<GroupTwoTone color="primary" />} hasTable>
                <DataGrid
                    columns={columns}
                    rows={property.allocations}
                    autoHeight
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    getRowClassName={(params) => `row-${params.row.id}`}
                    localeText={{ noRowsLabel: t('noRows') }}
                />
            </Widget>

            <CreateAllocationTrackModal
                show={showCreateAllocationTrack}
                handleClose={() => setShowAllocationTrack(false)}
                property={property}
            />
        </Stack>
    );
};
