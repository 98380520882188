import { IAllocation, ISaveAppointment, ISaveRefusal, rentingApi } from '../../shared';

class AllocationClient {
    public async allocate(allocationId: string): Promise<IAllocation> {
        const { data } = await rentingApi.post<IAllocation>(`allocations/${allocationId}/allocate`);
        return data;
    }

    public async saveAppointment(allocationId: string, appointment: ISaveAppointment): Promise<IAllocation> {
        const { data } = await rentingApi.post<IAllocation>(`allocations/${allocationId}/appointments`, appointment);
        return data;
    }

    public async saveRefusal(allocationId: string, refusal: ISaveRefusal): Promise<IAllocation> {
        const { data } = await rentingApi.post<IAllocation>(`allocations/${allocationId}/refusals`, refusal);
        return data;
    }
}

export const allocationClient = new AllocationClient();
