import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, Tab, Tabs } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AllocationTrackType,
    IAllocationTrackForm,
    IProperty,
    useGetCurrentUser,
    useManagerList,
    useSaveAllocationTrack,
} from '../../../shared';
import { useAllocationTrackSchema } from '../../validators';
import { allocationTrackFromFormMapper } from '../../mappers';
import { CreateAllocationTrackForm } from './create-allocation-track-form.component';

interface Props {
    show: boolean;
    handleClose: () => void;
    property?: IProperty;
}

export const CreateAllocationTrackModal: FC<Props> = ({ show, handleClose, property }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const allocationTrackType = window.carity.environment.defaultAllocationTrackType;
    const [selectedTab, setSelectedTab] = useState<string>(allocationTrackType);

    const { data: managers } = useManagerList();
    const { data: currentUser } = useGetCurrentUser();
    const { mutateAsync: createAllocationTrack } = useSaveAllocationTrack();

    const form = useForm<IAllocationTrackForm>({
        resolver: yupResolver(useAllocationTrackSchema(selectedTab)),
        mode: 'all',
    });

    useEffect(() => {
        if (property) {
            setSelectedTab(AllocationTrackType.PROPERTY);
            form.reset({ propertyId: property.id, userId: currentUser?.data?.id });
        } else if (show) {
            form.reset({ userId: currentUser?.data?.id });
        }
    }, [property, form, currentUser, show]);

    const onSubmit = async (data: IAllocationTrackForm) => {
        const user = managers?.items?.find((user) => user.id === data.userId);
        const created = await createAllocationTrack({ item: allocationTrackFromFormMapper(data, user) });
        navigate(`/allocation-tracks/${created.id}/candidates`);
    };

    return (
        <Dialog open={show}>
            {!property && (
                <Tabs variant="fullWidth" value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                    <Tab label={t('property')} value={AllocationTrackType.PROPERTY} key={'property'} />
                    <Tab label={t('propertyType')} value={AllocationTrackType.PROPERTY_TYPE} key={'propertyType'} />
                </Tabs>
            )}

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    <CreateAllocationTrackForm selectedTab={selectedTab} property={property} />

                    <DialogActions>
                        <Button type="submit" variant="contained">
                            {t('startAllocationTrack')}
                        </Button>
                        <Button onClick={handleClose}>{t('cancel')}</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
