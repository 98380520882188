import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { QueryKeys } from '../../shared';
import { IAppliedDashboardFilterDto, IChart } from '../models';

async function getChart(id: number, filters: IAppliedDashboardFilterDto[]): Promise<IChart> {
    const { data } = await axios.post(`/reporting/chart/${id}`, { filters });
    return data;
}

export const useChart = (id: number, filters: IAppliedDashboardFilterDto[]) => {
    return useQuery<IChart, AxiosError>({
        queryKey: [QueryKeys.Charts, id, filters],
        queryFn: () => getChart(id, filters),
    });
};
