import { LiveHelpTwoTone } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, PermissionKeys, RentalRequestAssetType, useHasPermission, Widget } from '../../../shared';
import {
    AddAdditionalQuestion,
    DeleteAdditionalQuestion,
    DownloadAssets,
    RentalRequestTitle,
    RentalRequestVerificationActions,
} from '../../components';
import { useRentalRequest } from '../../hooks';

export const RentalRequestAdditionalQuestionsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasAdditionalQuestionsPermission = hasPermission(PermissionKeys.ADDITIONAL_QUESTIONS_WRITE);

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Widget
                icon={<LiveHelpTwoTone color="primary" />}
                title={t('additionalQuestions')}
                actions={hasAdditionalQuestionsPermission && <AddAdditionalQuestion id={id} />}
            >
                <Stack spacing={2}>
                    {rentalRequest?.additionalQuestions?.map((question) => (
                        <Box>
                            <Stack direction="row" alignItems="center">
                                <Typography sx={{ whiteSpace: 'pre-line' }}>{question.question}</Typography>
                                {hasAdditionalQuestionsPermission && (
                                    <DeleteAdditionalQuestion rentalRequestId={id} questionId={question.id} />
                                )}
                            </Stack>
                            {question?.rentalRequestAssets?.length ? (
                                <DownloadAssets
                                    rentalRequestAssets={question?.rentalRequestAssets}
                                    rentalRequestAssetType={RentalRequestAssetType.ADDITIONAL_DOCUMENT}
                                />
                            ) : (
                                <>-</>
                            )}
                        </Box>
                    ))}
                </Stack>
            </Widget>
        </Page>
    );
};
