import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object } from 'yup';

export function useIndexationSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                year: number()
                    .optional()
                    .transform((value) => {
                        if (value) {
                            return value;
                        } else {
                            return undefined;
                        }
                    })
                    .required()
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber')),

                index: number()
                    .optional()
                    .transform((value) => {
                        if (value) {
                            return value;
                        } else {
                            return undefined;
                        }
                    })
                    .required()
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber')),
            }),
        [t],
    );
}
