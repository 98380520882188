import React, { FC, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import { useRentalRequestContractSigned } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../../../shared';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
    id: string;
}

export const ContractSigned: FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { mutateAsync: contractSigned, isPending } = useRentalRequestContractSigned();

    const onSubmit = async () => {
        await contractSigned({ id });
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="outlined">
                {t('contractSigned')}
            </Button>
            <Dialog open={open}>
                <DialogTitleWithClose onClose={() => setOpen(false)}>
                    <Grid container direction="row" alignItems="center">
                        <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                        {t('contractSignedTitle')}
                    </Grid>
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>{t('contractSignedText')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={isPending}
                        startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                        onClick={onSubmit}
                    >
                        {t('confirm')}
                    </Button>
                    <Button onClick={() => setOpen(false)} disabled={isPending}>
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
