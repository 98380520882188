export default {
    getProvidersError: 'Er is een probleem opgetreden bij het ophalen van de providers.',
    getUserError: 'Er is een probleem opgetreden bij het ophalen van de gebruiker.',
    selectLoginMethod: 'Welkom terug bij betaalbaar huren. Selecteer hieronder je login methode.',
    selectLoginMethod_antwerp: 'Welkom terug bij budgethuren. Selecteer hieronder je login methode.',
    loginTitle: 'Welkom terug!',
    logIn: 'Inloggen',
    logOut: 'Uitloggen',
    logoutTitle: 'Afmelden succesvol',
    goToLogin: 'Log terug in',
    unauthenticatedTitle: 'Geen toegang',
    unauthenticatedText: 'Je gebruiker heeft momenteel geen toegang tot deze applicatie.',
    loginAgain: 'Opnieuw inloggen',
};
