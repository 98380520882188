import { Euro } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, FormGrid, IProperty, LabelValue, Widget } from '../../../shared';

interface Props {
    property: IProperty;
}

export const PropertyDetailRent: FC<Props> = ({ property }) => {
    const { t } = useTranslation();

    const oneBedroom = property.amountOfBedrooms === 1;

    return (
        <FormGrid xs={12} lg={6} containerProps={{ mt: 1, spacing: 3 }}>
            <Widget
                title={`${t('propertyRent')} ${property.currentRents.year} ${t('withAppliedDiscounts')}`}
                icon={<Euro color="primary" />}
            >
                {oneBedroom ? (
                    <FormGrid xs={12} md={4}>
                        <LabelValue
                            label={t('rentingPrice', { year: property.currentRents.year })}
                            value={formatPrice(property.currentRents.oneBedroom, 0)}
                        />
                        <LabelValue
                            label={t('propertyMonthlyExtraCost')}
                            value={formatPrice(property.currentRents.extraCost, 0)}
                        />
                    </FormGrid>
                ) : (
                    <FormGrid xs={12} md={4}>
                        <LabelValue
                            label={t('singleParent')}
                            value={formatPrice(property.currentRents.multipleBedroomSingleParent, 0)}
                        />
                        <LabelValue
                            label={t('notSingleParent')}
                            value={formatPrice(property.currentRents.multipleBedroomOther, 0)}
                        />
                        <LabelValue
                            label={t('propertyMonthlyExtraCost')}
                            value={formatPrice(property.currentRents.extraCost, 0)}
                        />
                    </FormGrid>
                )}
            </Widget>

            <Widget
                title={`${t('rentingPriceIndexed', { indexYear: property.indexedRents.year })} ${t(
                    'withAppliedDiscounts',
                )}`}
                icon={<Euro color="primary" />}
            >
                {oneBedroom ? (
                    <FormGrid xs={12} md={4}>
                        <LabelValue
                            label={t('rentingPrice', { year: property.indexedRents.year })}
                            value={formatPrice(property.indexedRents.oneBedroom, 0)}
                        />
                        <LabelValue
                            label={t('propertyMonthlyExtraCost')}
                            value={formatPrice(property.indexedRents.extraCost, 0)}
                        />
                    </FormGrid>
                ) : (
                    <FormGrid xs={12} md={4}>
                        <LabelValue
                            label={t('singleParent')}
                            value={formatPrice(property.indexedRents.multipleBedroomSingleParent, 0)}
                        />
                        <LabelValue
                            label={t('notSingleParent')}
                            value={formatPrice(property.indexedRents.multipleBedroomOther, 0)}
                        />
                        <LabelValue
                            label={t('propertyMonthlyExtraCost')}
                            value={formatPrice(property.indexedRents.extraCost, 0)}
                        />
                    </FormGrid>
                )}
            </Widget>
        </FormGrid>
    );
};
