import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledDatePicker } from '../../../shared';

export const DashboardDateFilter: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <ControlledDatePicker name="startDate" label={t('startDate')} />
            <ControlledDatePicker name="endDate" label={t('endDate')} />
        </>
    );
};
