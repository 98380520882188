import { IPropertyType, ISavePropertyType } from '../index';
import { BaseClient } from './base.client';
import { rentingApi } from './renting-api.client';

class PropertyTypesClient extends BaseClient<IPropertyType, ISavePropertyType> {
    constructor() {
        super(rentingApi, '/property-types');
    }
}

export const propertyTypesClient = new PropertyTypesClient();
