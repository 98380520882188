import { Box, Stack, Typography } from '@mui/material';
import React, { ComponentProps, FC, ReactElement } from 'react';

interface Props extends ComponentProps<typeof Stack> {
    label: ReactElement | string;
    value?: ReactElement | string;
}

export const LabelValue: FC<Props> = ({ label, value, ...props }) => {
    return (
        <Stack {...props}>
            <Typography variant="caption">{label}</Typography>
            <Box>{value || '-'}</Box>
        </Stack>
    );
};
