import { CloudDownloadOutlined } from '@mui/icons-material';
import { Link, Stack, Typography } from '@mui/material';
import * as html2pdf from 'html2pdf.js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType, IProperty } from '../../../shared';
import { useDownloadInvite } from '../../hooks';

interface Params {
    contactType: ContactType;
    propertyId: string;
    rentalRequestId: string;
    properties: IProperty[];
}

export const DownloadPdfInvite: FC<Params> = ({ contactType, propertyId, rentalRequestId, properties }) => {
    const { t } = useTranslation();
    const { data: htmlString, isSuccess } = useDownloadInvite(contactType, propertyId, rentalRequestId);

    const downloadPdf = () => {
        const propertyName = properties.find((property) => property.id === propertyId)?.name;
        html2pdf()
            .set({ html2canvas: { useCORS: true } })
            .from(htmlString, 'string')
            .save(`${t('invite')}-${propertyName}.pdf`);
    };

    return (
        <Link component="button" type="button" onClick={downloadPdf} disabled={!isSuccess}>
            <Stack component="span" direction="row" alignItems="center" spacing={1} sx={{ paddingRight: '14px' }}>
                <Typography component="span">{t(`download.${contactType}`)}</Typography>
                <CloudDownloadOutlined fontSize="small" />
            </Stack>
        </Link>
    );
};
