import { TextField } from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    ampm?: boolean;
};

export const ControlledDateTimePicker: FC<Props> = ({ name, ampm, ...textFieldProps }) => {
    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DateTimePicker
                    slotProps={{
                        textField: {
                            ...textFieldProps,
                            id: `${name}-field`,
                            helperText: (error as FieldError | undefined)?.message,
                            error: !!error,
                        },
                        inputAdornment: { position: 'start' },
                    }}
                    label={textFieldProps.label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format={'dd/MM/yyyy HH:mm'}
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                    ampm={ampm || false}
                />
            )}
        />
    );
};
