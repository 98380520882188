import { Article, Euro, Home, Timeline } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Account } from '../account/account.component';

export const Navigation: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'home', [location]);
    const theme = useTheme();
    const isTabletOrHigher = useMediaQuery(theme.breakpoints.up('lg'));

    const navItems = [
        { label: t('nav.allocationTracks'), value: 'allocation-tracks', to: '/allocation-tracks', icon: <Timeline /> },
        { label: t('nav.rentalRequests'), value: 'rental-requests', to: '/rental-requests', icon: <Article /> },
        { label: t('nav.properties'), value: 'properties', to: '/properties', icon: <Home /> },
        // { label: t('nav.reporting'), value: 'reports', to: '/reports', icon: <Dashboard /> },
        { label: t('nav.admin'), value: 'admin', to: '/admin', icon: <Euro /> },
    ];

    return isTabletOrHigher ? (
        <>
            <Tabs indicatorColor="primary" textColor="primary" sx={{ flexGrow: 1 }} value={selectedTab}>
                {navItems.map(({ label, value, to }) => (
                    <Tab key={value} label={label} value={value} component={Link} to={to} />
                ))}
            </Tabs>
            <Account />
        </>
    ) : (
        <>
            <Box sx={{ flexGrow: 1 }} />
            <Account />
            <BottomNavigation showLabels value={selectedTab}>
                {navItems.map(({ label, value, to, icon }) => (
                    <BottomNavigationAction
                        key={value}
                        label={label}
                        value={value}
                        component={Link}
                        to={to}
                        icon={icon}
                    />
                ))}
            </BottomNavigation>
        </>
    );
};
