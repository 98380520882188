import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveAllocationManager } from '../save-allocation-manager/save-allocation-manager.component';
import { IAllocationTrack } from '../../../shared';

interface Props {
    subtitle: string;
    allocationTrack: IAllocationTrack;
}

export const AllocationTrackTitle: FC<Props> = ({ subtitle, allocationTrack }) => {
    const { t } = useTranslation();
    const [showSaveAllocationManager, setShowSaveAllocationManager] = useState(false);

    const title =
        allocationTrack?.propertyType === null
            ? `${t('allocationTrackForProperties')} ${allocationTrack?.properties?.map(({ name }) => name).join(', ')}`
            : `${t('allocationTrackForPropertyType')} ${allocationTrack?.propertyType?.name}`;

    return (
        <>
            <Stack direction="row" sx={{ '.MuiLink-root': { fontSize: '12px', cursor: 'pointer' } }}>
                <Box>
                    <Typography variant="subtitle1" mt={1}>
                        {title} - {subtitle}
                    </Typography>
                    <Typography variant="caption" sx={{ position: 'relative', top: '-5px' }}>
                        {`${t('allocationManager')}: ${allocationTrack.allocationManager?.name} `}
                        <Link onClick={() => setShowSaveAllocationManager(true)}>{t('edit')}</Link>
                    </Typography>
                </Box>
                <Chip label={t(allocationTrack.status)} sx={{ ml: 5, my: 'auto' }} />
            </Stack>
            <SaveAllocationManager
                show={showSaveAllocationManager}
                handleClose={() => setShowSaveAllocationManager(false)}
                allocationTrack={allocationTrack}
            />
        </>
    );
};
