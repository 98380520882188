import { DeleteOutline, Edit, Search as SearchIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { formatAmount } from '../../../rental-requests/utils';
import {
    AllocationStatus,
    formatPropertyAddress,
    IAddress,
    IPropertyType,
    Page,
    RemoveModal,
    Search,
    SortOrder,
    Table,
    useQueryParamsContext,
} from '../../../shared';
import { PropertyFilter } from '../../components';
import { useDeleteProperty, usePropertyList } from '../../hooks';

export const PropertyPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        queryParams: { page, pageSize, sort, filter, search },
        debouncedQueryParams: { search: debouncedSearch },
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('properties', {
        sort: [{ field: 'name', sort: 'asc' as GridSortDirection }],
        filter: {},
    });

    const { data: properties, isLoading } = usePropertyList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        propertyTypeId: filter?.propertyTypeId || undefined,
        status: filter?.status || undefined,
        search: debouncedSearch,
    });
    const { mutateAsync: deleteProperty } = useDeleteProperty();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('property'),
            minWidth: 300,
        },
        {
            field: 'type',
            headerName: t('propertyType'),
            minWidth: 200,
            valueFormatter: (value: IPropertyType) => value?.name,
        },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: IAddress) => formatPropertyAddress(value),
        },
        {
            field: 'amountOfBedrooms',
            headerName: t('propertyAmountOfBedrooms'),
            minWidth: 180,
        },
        {
            field: 'area',
            headerName: t('propertyArea'),
            minWidth: 180,
            valueFormatter: (value: number) => formatAmount(value),
        },
        {
            field: 'hasElevator',
            headerName: t('propertyHasElevator'),
            minWidth: 180,
            valueFormatter: (value: boolean) => (value ? t('yes') : ''),
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 120,
            renderCell: (params) => {
                const status = params?.row?.status;
                return (
                    <Link
                        to={
                            params?.row?.allocationTrack?.id
                                ? `/allocation-tracks/${params?.row?.allocationTrack?.id}/candidates`
                                : ''
                        }
                        style={{ textDecoration: 'none' }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {status === AllocationStatus.INVITED && t('invited')}
                        {status === AllocationStatus.ALLOCATED && t('allocated')}
                    </Link>
                );
            },
        },
        {
            field: ' ',
            headerName: '',
            width: 170,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/properties/${params?.row?.id}`);
                        }}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/properties/${params?.row?.id}/edit`);
                        }}
                        size="large"
                    >
                        <Edit />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => deleteProperty(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('propertyDeleteWarningTitle')}
                        text={t('propertyDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    const actions: ReactNode[] = [
        <Search search={search} onSearch={setSearch} />,
        <Button component={Link} to="/properties/new" variant="contained">
            {t('newProperty')}
        </Button>,
    ];

    return (
        <Page
            title={t('properties')}
            filter={<PropertyFilter filter={filter} onChange={setFilter} />}
            actions={actions}
            loading={isLoading}
        >
            <Table
                rows={properties?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={properties?.pagination.size || 0}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/properties/${row.id}`)}
            />
        </Page>
    );
};
