import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';

interface Props {
    amountOfProperties?: number;
    amountOfCandidates?: number;
}

export const AllocationTrackInformationAlert: FC<Props> = ({ amountOfProperties = 0, amountOfCandidates = 0 }) => {
    const { t } = useTranslation();

    const informationToDisplay = () => {
        if (amountOfCandidates === 0) {
            return (
                <>
                    <AlertTitle>{t('noCandidates')}</AlertTitle>
                    {t('informationAlertTextNoCandidates', { count: amountOfProperties })}
                </>
            );
        }

        if (amountOfCandidates < amountOfProperties) {
            return (
                <>
                    <AlertTitle>{t('notEnoughCandidates')}</AlertTitle>
                    {t('informationAlertTextNotEnoughProperties', {
                        count: amountOfProperties,
                    })}
                    {t('informationAlertTextNotEnoughCandidates', { count: amountOfCandidates })}
                </>
            );
        }

        if (amountOfCandidates > amountOfProperties) {
            return (
                <>
                    <AlertTitle>{t('toManyCandidates')}</AlertTitle>
                    {t('informationAlertTextToManyCandidates', { count: amountOfCandidates })}
                    {t('informationAlertTextToManyProperties', { count: amountOfProperties })}
                </>
            );
        }
    };

    return (
        <>
            {(amountOfProperties === 0 || amountOfCandidates !== amountOfProperties) && (
                <Alert severity="info" sx={{ mb: 3 }}>
                    {informationToDisplay()}
                </Alert>
            )}
        </>
    );
};
