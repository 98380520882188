import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, Collapse, Grid, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAddress, formatSocialSecurityNumber, IResident, LabelValue, ResidentRelation } from '../../../shared';
import { ageBetween18And25 } from '../../utils';

interface Props {
    resident: IResident;
}

export const ResidentInfo: FC<Props> = ({ resident }) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <ListItem sx={{ p: 0 }}>
                <ListItemButton sx={{ p: 2 }} onClick={() => setShowMore((prev) => !prev)}>
                    <ListItemText
                        primary={
                            <Stack direction="row" alignItems="center">
                                {`${resident.firstName} ${resident.lastName}`}
                                {!resident.autofill && (
                                    <Chip
                                        label={t('isManuallyAdded')}
                                        color="warning"
                                        variant="outlined"
                                        size="small"
                                        sx={{ ml: 3 }}
                                    />
                                )}
                            </Stack>
                        }
                        secondary={resident.isPrimaryResident ? t('primary') : t(resident.relation || '')}
                    />
                    {showMore ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={showMore} timeout="auto" unmountOnExit>
                <ListItem sx={{ p: 2, pt: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('socialSecurityNumber')}
                                value={formatSocialSecurityNumber(resident.socialSecurityNumber)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue label={t('dateOfBirth')} value={resident.dateOfBirth} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('relation')}
                                value={resident.isPrimaryResident ? t('primary') : t(resident.relation || '')}
                            />
                        </Grid>
                        {!resident.isPrimaryResident && (
                            <Grid item xs={12} sm={6} md={3}>
                                <LabelValue
                                    label={t('address')}
                                    value={
                                        resident.currentAddress && !resident.isCurrentAddressSameAsPrimaryResident
                                            ? formatAddress(resident.currentAddress)
                                            : t('sameAsPrimaryResident')
                                    }
                                />
                            </Grid>
                        )}
                        {!resident.isPrimaryResident &&
                            resident.relation === ResidentRelation.CHILD &&
                            ageBetween18And25(resident.dateOfBirth) && (
                                <Grid item xs={12} sm={6} md={3}>
                                    <LabelValue
                                        label={t('hasChildSupport')}
                                        value={resident.hasChildSupport ? t('yes') : t('no')}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </ListItem>
            </Collapse>
        </>
    );
};
