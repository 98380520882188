import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Checkbox> & {
    control: Control<any>;
    name: string;
    label: string;
    initialValue?: boolean;
};

export const ControlledCheckbox: FC<Props> = ({ name, label, control, initialValue = false, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={initialValue}
            render={({ field: { name, onChange, value } }) => (
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...props}
                                checked={value}
                                onChange={onChange}
                                name={name}
                                data-testid={`${name}-checkbox`}
                            />
                        }
                        label={label}
                    />
                </FormControl>
            )}
        />
    );
};
