import { ErrorOutline, WarningAmber } from '@mui/icons-material';
import { Box, Card, CardContent, List, ListItem, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IError, IParams, Page, RentalRequestErrorType } from '../../../shared';
import { RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { useRentalRequest } from '../../hooks';
import { useGetResident, useSortedErrors } from '../../utils';

export const RentalRequestWarningsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    const getResident = useGetResident(rentalRequest);
    const sortedErrors = useSortedErrors(rentalRequest);

    const getErrorsToDisplay = (error?: any) => {
        return (
            <>
                <List dense>
                    {error?.errors?.map((err: IError, index: number) => (
                        <ListItem sx={{ ml: '33px' }} key={index}>
                            {t(err.message, {
                                property: t('errors.' + err.property, {
                                    context: window.carity.environment.theme.name,
                                }),
                            })}
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    const getErrorMessage = (error?: any) => {
        switch (error?.type) {
            case RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE:
                return !!error?.notEligibleReason ? (
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ color: 'error.main', display: 'flex' }}>
                            <ErrorOutline sx={{ width: '50px' }} />
                            {t('errors.eligible')}
                        </Typography>
                        <Typography sx={{ m: '10px 0 0 50px', display: 'flex' }}>
                            {t(`errors.${error?.notEligibleReason}`)}
                        </Typography>
                    </CardContent>
                ) : (
                    <></>
                );

            case RentalRequestErrorType.RENTAL_REQUEST:
                return (
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ color: 'warning.main', display: 'flex' }}>
                            <WarningAmber sx={{ width: '50px' }} />
                            {t('errors.rentalRequestError')}
                        </Typography>
                        {getErrorsToDisplay(error)}
                    </CardContent>
                );

            case RentalRequestErrorType.RESIDENT:
                const resident = getResident(error?.residentId);
                return (
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ color: 'warning.main', display: 'flex' }}>
                            <WarningAmber sx={{ width: '50px' }} />
                            {resident?.firstName === '' || resident?.lastName === ''
                                ? t('errors.unknown_name')
                                : `${resident?.firstName} ${resident?.lastName}`}
                        </Typography>
                        {getErrorsToDisplay(error)}
                    </CardContent>
                );
        }
    };

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            {rentalRequest?.errors?.length ? (
                <>
                    {sortedErrors?.map((error, index) => (
                        <Card key={index} sx={{ mb: '15px' }}>
                            {getErrorMessage(error)}
                        </Card>
                    ))}
                </>
            ) : (
                <Box style={{ minHeight: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>{t('noErrors')}</Typography>
                </Box>
            )}
        </Page>
    );
};
