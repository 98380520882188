import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export const useSettingsSchema = () => {
    const { t } = useTranslation();
    return object().shape({
        referenceIncomeLowerLimitCommon: number().typeError(t('invalidNumber')).required(),
        referenceIncomeUpperLimitCommon: number().typeError(t('invalidNumber')).required(),
        referenceIncomeLowerLimitSingle: number().typeError(t('invalidNumber')).required(),
        referenceIncomeUpperLimitSingle: number().typeError(t('invalidNumber')).required(),
        referenceIncomeLowerLimitSingleAndHandicap: number().typeError(t('invalidNumber')).required(),
        referenceIncomeUpperLimitSingleAndHandicap: number().typeError(t('invalidNumber')).required(),
        supplementChild: number().typeError(t('invalidNumber')).required(),
        year: string().required(),
    });
};
