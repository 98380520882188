import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, FormCard, Page, Section } from '../../../shared';
import { useIncomeSettings, useUpdateIncomeSettings } from '../../hooks';
import { ISaveIncomeSettings } from '../../models';
import { useSettingsSchema } from '../../validators';

export const IncomeSettingsPage: FC = () => {
    const { t } = useTranslation();

    const { data: settings } = useIncomeSettings();

    const form = useForm<ISaveIncomeSettings>({
        resolver: yupResolver(useSettingsSchema()),
        mode: 'all',
    });

    const { mutateAsync: updateSettings, isPending } = useUpdateIncomeSettings();

    useEffect(() => {
        if (settings) {
            form.reset(settings);
        }
    }, [settings, form]);

    const onSubmit = useCallback(
        async (settings: ISaveIncomeSettings) => {
            await updateSettings(settings);
        },
        [updateSettings],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isPending} key="save">
                {t('save')}
            </Button>,
        ],
        [t, form, onSubmit, isPending],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page title={t('incomeSettings')} actions={reversedActions} loading={isPending}>
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('settings')}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeLowerLimitCommon"
                                    label={t('referenceIncomeLowerLimitCommon')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeUpperLimitCommon"
                                    label={t('referenceIncomeUpperLimitCommon')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeLowerLimitSingle"
                                    label={t('referenceIncomeLowerLimitSingle')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeUpperLimitSingle"
                                    label={t('referenceIncomeUpperLimitSingle')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeLowerLimitSingleAndHandicap"
                                    label={t('referenceIncomeLowerLimitSingleAndHandicap')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="referenceIncomeUpperLimitSingleAndHandicap"
                                    label={t('referenceIncomeUpperLimitSingleAndHandicap')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledInput
                                    type="number"
                                    name="supplementChild"
                                    label={t('supplementChild')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControlledNumberInput
                                    name="year"
                                    label={t('year')}
                                    required
                                    decimalScale={0}
                                    thousandSeparator=""
                                />
                            </Grid>
                        </Grid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
