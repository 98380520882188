import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IRefusal, ListModel, ListQueryParam, QueryKeys } from '../../shared';
import { refusalsClient } from '../clients';

const client = refusalsClient;
const listKey = QueryKeys.Refusals;

export function useRefusalList(
    requestId: string,
    query: ListQueryParam,
    options: Omit<UseQueryOptions<ListModel<IRefusal>, AxiosError>, 'queryKey'> = {},
): UseQueryResult<ListModel<IRefusal>, AxiosError> {
    return useQuery<ListModel<IRefusal>, AxiosError>({
        queryKey: [listKey, requestId, query],
        queryFn: () => client.getList(requestId, query),
        ...options,
    });
}
