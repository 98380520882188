import { getYear } from 'date-fns';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    FormGrid,
    IParams,
    IPropertyForm,
    Page,
    RemoveModal,
    usePropertyTypeList,
    useRegionList,
} from '../../../shared';
import { useDeleteProperty, useProperty, useSaveProperty } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePropertySchema } from '../../validators';
import { Button, Card, CardActions, CardContent, MenuItem, Typography } from '@mui/material';
import { propertyFromFormMapper, propertyToFormMapper } from '../../mapper';

export const PropertyEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: property, isFetching } = useProperty(id);
    const { data: propertyTypes } = usePropertyTypeList({ page: 1, pageSize: 1000 });
    const { data: regions } = useRegionList({ page: 1, pageSize: 1000 });
    const { mutateAsync: saveProperty, isPending } = useSaveProperty();
    const { mutateAsync: deleteProperty } = useDeleteProperty();

    const form = useForm<IPropertyForm>({
        mode: 'all',
        resolver: yupResolver(usePropertySchema()),
    });

    useEffect(() => {
        if (property) {
            form.reset(propertyToFormMapper(property));
        } else {
            form.reset({ rentYear: getYear(new Date()).toString() });
        }
    }, [property, form]);

    const rentYearOptions = useMemo(() => {
        const currentYear = getYear(new Date());

        const previouslyFilledInYear = property?.rentYear.toString() || '';
        const uniqueValues = [
            ...new Set([
                previouslyFilledInYear,
                currentYear.toString(),
                (currentYear - 1).toString(),
                (currentYear - 2).toString(),
            ]),
        ].filter((year) => !!year);

        return uniqueValues.sort();
    }, [property]);

    const onSubmit = useCallback(
        async (item: IPropertyForm) => {
            const { id: savedId } = await saveProperty({ id, item: propertyFromFormMapper(item) });
            navigate(`/properties/${savedId}`);
        },
        [id, navigate, saveProperty],
    );

    const onDelete = useCallback(async () => {
        await deleteProperty(id);
        navigate('/properties');
    }, [deleteProperty, navigate, id]);

    const actions = useMemo(
        () => [
            <Button key={'submit'} variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetching}>
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={<Button variant="outlined">{t('delete')}</Button>}
                        title={t('propertyDeleteWarningTitle')}
                        text={t('propertyDeleteWarningText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate(-1)}>
                {t('cancel')}
            </Button>,
        ],
        [t, form, id, onSubmit, onDelete, navigate, isFetching],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={property?.id ? t('updateProperty') : t('newProperty')}
            loading={isFetching || isPending}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit, (err) => {
                        console.log('err', err);
                    })}
                >
                    <Card variant="outlined">
                        <CardContent sx={{ '.MuiGrid-container': { mb: 2, mt: 0 } }}>
                            <Typography variant="subtitle2">{t('property')}</Typography>
                            <FormGrid xs={12} md={'auto'}>
                                <ControlledSelect name="typeId" label={t('propertyPropertyType')} required>
                                    {propertyTypes?.data?.map((propertyType) => (
                                        <MenuItem key={propertyType.id} value={propertyType.id}>
                                            {propertyType.name}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                                <ControlledInput name="name" label={t('propertyName')} required />
                            </FormGrid>

                            <Typography variant="subtitle2">{t('address')}</Typography>
                            <FormGrid xs={12} md={'auto'}>
                                <ControlledInput name="address.street" label={t('street')} required />
                                <ControlledInput name="address.number" label={t('number')} required />
                                <ControlledInput name="address.box" label={t('box')} />
                                <ControlledInput name="address.zip" label={t('zip')} required />
                                <ControlledSelect name="address.region" label={t('region')}>
                                    {regions?.data?.map((region) => (
                                        <MenuItem key={region.id} value={region.name}>
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                                <ControlledInput name="address.city" label={t('city')} required />
                                <ControlledInput name="address.country" label={t('country')} required />
                            </FormGrid>

                            <Typography variant="subtitle2">{t('propertyOccupation')}</Typography>
                            <FormGrid xs={12} md={'auto'}>
                                <ControlledInput
                                    name="amountOfBedrooms"
                                    label={t('propertyAmountOfBedrooms')}
                                    required
                                />
                                <ControlledNumberInput name={`area`} label={t('propertyArea')} required />
                            </FormGrid>

                            <Typography variant="subtitle2">{t('propertyRent')}</Typography>
                            <FormGrid xs={12} md={'auto'}>
                                <ControlledInput name="monthlyRent" label={t('propertyMonthlyRent')} required />
                                <ControlledInput
                                    name="monthlyExtraCost"
                                    label={t('propertyMonthlyExtraCost')}
                                    required
                                />
                                <ControlledSelect name="rentYear" label={t('rentYear')} required>
                                    {rentYearOptions.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                            </FormGrid>

                            <Typography variant="subtitle2">{t('propertyExtras')}</Typography>
                            <FormGrid xs={12} md={'auto'} sx={{ '&.MuiGrid-item ': { pt: 0 } }}>
                                <ControlledCheckbox
                                    control={form.control}
                                    name="hasElevator"
                                    label={t('propertyHasElevator')}
                                />
                            </FormGrid>
                            <ControlledInput name="comment" label={t('comment')} multiline rows={2} />
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
