import { SpeakerNotesTwoTone } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, Widget } from '../../../shared';

interface Props {
    rentalRequest: IRentalRequest;
}
export const RentalRequestRemarks: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('remarks')} icon={<SpeakerNotesTwoTone color="primary" />} sx={{ whiteSpace: 'pre-line' }}>
            {rentalRequest?.preferences?.remarks}
        </Widget>
    );
};
