import { Button, CircularProgress, Divider, Grid, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    FormCard,
    ICommunicationSettings,
    Page,
    Section,
    useCommunicationSettings,
    useUpdateCommunicationSettings,
} from '../../../shared';
import { SelectTemplate, TemplatePreview } from '../../components';

type preview = { templateId: string | undefined; title: string };
export const CommunicationSettingsPage: FC = () => {
    const { t } = useTranslation();

    const { data: communicationSettings, isLoading } = useCommunicationSettings();
    const { mutateAsync: updateSettings, isPending: isUpdating } = useUpdateCommunicationSettings();

    const [preview, setPreview] = useState<preview | undefined>();
    const form = useForm<ICommunicationSettings>({});
    const watchTemplates = form.watch();

    useEffect(() => {
        if (communicationSettings) {
            form.reset(communicationSettings);
        }
    }, [form, communicationSettings]);

    const onSubmit = useCallback(
        async (settings: ICommunicationSettings) => {
            await updateSettings(settings);
        },
        [updateSettings],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isLoading} key="save">
                {t('save')}
            </Button>,
            <>{isUpdating ? <CircularProgress size={24} /> : <></>}</>,
        ],
        [t, form, onSubmit, isLoading, isUpdating],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page title={t('communicationSettings')} actions={reversedActions}>
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('settings')}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} sx={{ pr: 2 }}>
                                <Stack spacing={3}>
                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'submitRequestConfirmationTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'changesRequestedTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'approveRequestTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'disapproveRequestTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'mailInviteTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'letterInviteTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'appointmentTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'denyByRequesterTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'denyByOrganisationTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'allocatedTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'notAllocatedTemplate'}
                                        watchTemplates={watchTemplates}
                                    />

                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'lastRefusalTemplate'}
                                        watchTemplates={watchTemplates}
                                    />
                                    <SelectTemplate
                                        setPreview={setPreview}
                                        name={'additionalQuestionTemplate'}
                                        watchTemplates={watchTemplates}
                                    />
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} md={6}>
                                <TemplatePreview id={preview?.templateId} title={preview?.title} />
                            </Grid>
                        </Grid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
