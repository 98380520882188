export default {
    errors: {
        socialSecurityNumber: 'Rijksregisternummer',
        MONTHLY_INCOME_DOCUMENT: 'Loonfiche',
        TAX_BILL_DOCUMENT: 'Aanslagbiljet',
        PREGNANCY_DOCUMENT: 'Zwangerschapsattest',
        RESIDENTIAL_HISTORY_DOCUMENT: 'Attest woonhistoriek',
        rentalRequestError: 'Algemeen',
        DISABILITY_INCOME_DOCUMENT: 'Attest inkomensvervangende tegemoetkoming voor personen met een handicap',
        LIVING_WAGE_DOCUMENT: 'Attest leefloon',
        CHILD_SUPPORT_DOCUMENT: 'Document groeipakket',
        email: 'Email',
        hasTaxBillForFullYear: 'Antwoord op heeft aanslagbiljet voor volledig jaar',
        jointTaxableIncome: 'Gezamenlijk belastbaar inkomen',
        individualTaxableIncome: 'Afzonderlijk belastbaar inkomen',
        monthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        livingWage: 'Leefloon',
        foreignIncome: 'Buitenlandse inkomsten',
        disabilityIncome: 'Inkomensvervangende tegemoetkoming',
        hasMonthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        hasLivingWage: 'Leefloon',
        hasForeignIncome: 'Buitenlandse inkomsten',
        hasDisabilityIncome: 'Inkomensvervangende tegemoetkoming',
        street: 'Straat',
        number: 'Nummer',
        dateOfBirth: 'Geboortedatum',
        financialData: 'Financieel',
        financialData_antwerp: 'Inkomen',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        city: 'Stad',
        zip: 'Postcode',
        country: 'Land',
        taxYear: 'Aanslagjaar',
        unknown_name: 'Onbekend',
        cityTies: 'Binding met de stad',
        hasChildSupport: 'Kinderbijslag of groeipakket',
        hasResidentialHistory: 'Antwoord vraag woonhistoriek',
        aResidentOwnsProperty: 'Antwoord vraag heeft woning',
        phone: 'Telefoonnummer',
        contactType: 'Contact type',
        expectingChildren: 'Zwangerschap',
        eligible: 'In aanmerking',
        notCompletelyFilledIn: 'Niet voldoende informatie ingevuld.',
        PRIMARY_RESIDENT_TOO_YOUNG: 'Hoofdaanvrager is onder de 18 jaar.',
        RESIDENT_HAS_PROPERTY: 'Eén van de bewoners heeft een eigendom.',
        REFERENCE_INCOME_TOO_HIGH: 'Het referentie inkomen is te hoog.',
        REFERENCE_INCOME_TOO_LOW: 'Het referentie inkomen is te laag.',
        RESIDENT_NOT_IN_REGISTER: 'Eén van de medebewoners zit niet in het bevolkingsregister.',
        TAX_YEAR_OR_INCOME_EMPTY: 'Zowel het referentie inkomen als het aanslagjaar moeten ingevuld zijn.',

        BUDGET_RENTING_DOCUMENT: 'Vlaams attest voor budgethuren',
        hasBudgetRentingDocument: 'Vlaams attest voor budgethuren',
        expirationDateBudgetRentingDocument: 'Vervaldatum Vlaams attest budgethuren',
        INVALID_BUDGET_RENTING_DOCUMENT: 'Een van de bewoners heeft geen geldig Vlaams attest voor budgethuren.',

        hasRentSubsidy: 'Antwoord vraag huurpremie',
        rentSubsidy: 'Antwoord vraag huurpremie',
        useRentSubsidyForMaxRent: 'Antwoord vraag huurpremie mee opnemen in de berekening van de te besteden huurprijs',
        RENT_SUBSIDY_DOCUMENT: 'Attest huursubsidie of huurpremie',

        RESIDENTIAL_RELATION_DOCUMENT: 'Attest van woonst met adressenhistoriek',
        WORK_STUDY_RELATION_DOCUMENT: 'Bewijs van werk of school',
        residentialRelation: 'Band met stad als woonplaats',
        workStudyRelation: 'Band met stad als werkplaats of school',
        bondWithTheCity: 'Band met stad',

        preferences: 'Voorkeuren',
        regions: 'Districten',
        elevator: 'Antwoord vraag nood aan een lift',
        propertyTypes: 'Antwoord vraag voorkeur woningtype',
        requiredAmountOfBedrooms: 'Keuze aantal slaapkamers',
        agreeToChangeResidence: 'Akkoord aanpassing domiciliëring',
    },

    DUPLICATE_USE: 'Het {{property}} van deze persoon wordt al gebruikt in een ander dossier.',
    DUPLICATE_USE_IN_RENTAL_REQUEST: 'Het {{property}} van deze persoon wordt al gebruikt in dit dossier.',
    EMPTY: '{{property}} ontbreekt.',
    DOCUMENT_NOT_EXPECTED: 'Geen document verwacht.',
    TOO_LOW: '{{property}} is te laag.',
    TOO_HIGH: '{{property}} is te hoog.',
    INVALID_FORMAT: '{{property}} heeft geen geldig formaat.',
    INVALID_LENGTH: '{{property}} is ongeldig.',
    INVALID_BOOLEAN: '{{property}} is ongeldig.',
    NOT_AN_ENUM_VALUE: '{{property}} is ongeldig.',
    INVALID_DATE_FORMAT: '{{property}} is geen geldige datum.',
    INVALID_STRING: '{{property}} is ongeldig.',
};
