import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    ControlledInput,
    FormGrid,
    IParams,
    IPropertyTypeForm,
    isAxiosError,
    Page,
    RemoveModal,
    StatusCode,
    useDeletePropertyType,
    usePropertyType,
    useSavePropertyType,
} from '../../../shared';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { AxiosError } from 'axios';
import { PropertyTypeInUse } from '../../components';

export const PropertyTypeEditPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: propertyType, isFetching } = usePropertyType(id);
    const { mutateAsync: savePropertyType, isPending } = useSavePropertyType();
    const { mutateAsync: deletePropertyType } = useDeletePropertyType();

    const [showPropertyTypeInUse, setShowPropertyTypeInUse] = useState(false);

    const schema = object().shape({
        code: string().required(t('requiredField')),
        name: string().required(t('requiredField')),
    });

    const form = useForm<IPropertyTypeForm>({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (propertyType) {
            form.reset(propertyType);
        }
    }, [propertyType, form]);

    const onSubmit = useCallback(
        async (item: IPropertyTypeForm) => {
            try {
                await savePropertyType({ id, item });
                navigate('/admin/property-types');
            } catch (e) {
                if (isAxiosError(e)) {
                    if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                        form.setError('code', {
                            type: 'custom',
                            message: t('propertyTypeCodeUniqueError'),
                        });
                    }
                } else {
                    throw e;
                }
            }
        },
        [t, id, navigate, form, savePropertyType],
    );

    const onDelete = useCallback(async () => {
        try {
            await deletePropertyType(id);
            navigate('/admin/property-types');
        } catch (e: any) {
            if (e.response.status === StatusCode.CONFLICT) {
                return setShowPropertyTypeInUse(true);
            }
        }
    }, [deletePropertyType, navigate, id]);

    const actions = useMemo(
        () => [
            <Button key={'submit'} variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetching}>
                {t('save')}
            </Button>,
            <Fragment key={'delete'}>
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={<Button variant="outlined">{t('delete')}</Button>}
                        title={t('propertyTypeDeleteWarningTitle')}
                        text={t('propertyTypeDeleteWarningText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/admin/property-types')}>
                {t('cancel')}
            </Button>,
        ],
        [t, id, form, onSubmit, onDelete, navigate, isFetching],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate('/admin/property-types')}
            title={propertyType?.id ? t('updatePropertyType') : t('newPropertyType')}
            loading={isFetching || isPending}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormGrid>
                                <ControlledInput name={`code`} label={t('propertyTypeCode')} required />
                                <ControlledInput name={`name`} label={t('propertyTypeName')} required />
                            </FormGrid>
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }}>{actions}</CardActions>
                    </Card>
                </form>
            </FormProvider>

            <PropertyTypeInUse open={showPropertyTypeInUse} onClose={() => setShowPropertyTypeInUse(false)} />
        </Page>
    );
};
