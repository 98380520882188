export default {
    downloads: {
        RESIDENTIAL_HISTORY_DOCUMENT: 'Attesten woonhistoriek',
        TAX_BILL_DOCUMENT: 'Documenten-aanslagbiljet',
        MONTHLY_INCOME_DOCUMENT: 'Documenten-loonfiches',
        LIVING_WAGE_DOCUMENT: 'Attesten-leefloon',
        DISABILITY_INCOME_DOCUMENT: 'Attesten-invaliditeit-inkomsten',
        PREGNANCY_DOCUMENT: 'Zwangerschaps-attesten',
        CHILD_SUPPORT_DOCUMENT: 'Documenten-groeipakket',
        RESIDENTIAL_RELATION_DOCUMENT: 'Attesten woonst met adressenhistoriek',
        ADDITIONAL_DOCUMENT: 'Bijkomende documenten',
        BUDGET_RENTING_DOCUMENT: 'Attesten voor Vlaams budgethuren',
        RENT_SUBSIDY_DOCUMENT: 'Attesten van huurpremie',
    },
};
