export default {
    property: 'Pand',
    properties: 'Panden',
    newProperty: 'Nieuw pand',
    updateProperty: 'Wijzig pand',
    propertyDeleteWarningTitle: 'Verwijder pand',
    propertyDeleteWarningText: 'Bent u zeker dat u dit pand wilt verwijderen?',
    propertyName: 'Naam',
    propertyPropertyType: 'Type',
    propertyOccupation: 'Bezetting',
    propertyRent: 'Huurprijs',
    propertyExtras: 'Extra',
    propertyAmountOfBedrooms: 'Aantal slaapkamers',
    propertyMonthlyCost: 'Maandelijkse huurprijs excl. kosten: {{rent}}\nMaandelijkse vaste kosten: {{extra}}',
    propertyMonthlyRent: 'Maandelijkse huurprijs excl. kosten',
    propertyMonthlyExtraCost: 'Maandelijkse vaste kosten',
    propertyHasElevator: 'Lift aanwezig',
    rentYear: 'Jaar van kosten',
    allocated: 'Toegewezen',
    invited: 'Uitgenodigd',
    rentingPriceIndexed: 'Huurprijs van referentiejaar {{indexYear}}',
    withAppliedDiscounts: 'met toegepaste kortingen',
    singleParent: 'Eenoudergezin',
    notSingleParent: 'Niet eenoudergezin',
    allocations: 'Toewijzingen',
};
