import {
    IProperty,
    IPropertyQuery,
    ListModel,
    ListQueryParam,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { propertyClient } from '../clients';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const client = propertyClient;
const listKey = QueryKeys.Properties;
const itemKey = QueryKeys.Property;

export function usePropertyList(...args: UseListParams<IProperty, IPropertyQuery>) {
    return useList(client, listKey, ...args);
}

export function useProperty(...args: UseItemParams<IProperty>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveProperty() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteProperty() {
    return useDelete(client, listKey);
}

export function useGetUnallocatedProperties(
    query: ListQueryParam,
    options: Omit<UseQueryOptions<ListModel<IProperty>, AxiosError>, 'queryKey'> = {},
): UseQueryResult<ListModel<IProperty>, AxiosError> {
    return useQuery<ListModel<IProperty>, AxiosError>({
        queryKey: [listKey, 'unallocated', query],
        queryFn: () => client.getUnallocatedProperties(query),
        ...options,
    });
}
