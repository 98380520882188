import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object } from 'yup';
import { ControlledDateTimePicker, DialogTitleWithClose, IAllocation, ISaveAppointment } from '../../../shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSaveAppointment } from '../../hooks';

interface Props {
    show: boolean;
    handleClose: () => void;
    allocation?: IAllocation | null;
}

export const AppointmentModal: FC<Props> = ({ show, handleClose, allocation }) => {
    const { t } = useTranslation();

    const { mutateAsync: saveAppointment, isPending } = useSaveAppointment();

    const schema = object().shape({
        date: date().typeError(t('requiredField')).required(t('requiredField')),
    });
    const form = useForm<ISaveAppointment>({
        resolver: yupResolver(schema),
        mode: 'all',
    });

    useEffect(() => {
        if (allocation?.appointment) {
            form.reset({ date: new Date(allocation.appointment?.date) });
        } else {
            form.reset({});
        }
    }, [form, allocation]);

    const onSubmit = async (item: ISaveAppointment) => {
        if (allocation) {
            await saveAppointment({ allocationId: allocation.id, item });
            handleClose();
        }
    };

    return (
        <Dialog open={show} onClose={handleClose}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    <DialogTitleWithClose onClose={handleClose}>
                        {allocation?.appointment ? t('alterAppointment') : t('makeAppointment')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Box sx={{ minWidth: 300 }}>
                            <ControlledDateTimePicker name="date" />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isPending}
                            startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                        >
                            {t('confirm')}
                        </Button>
                        <Button onClick={handleClose} disabled={isPending}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
