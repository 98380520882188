import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    RentalRequestAdditionalQuestionsPage,
    RentalRequestBondWithTheCityPage,
    RentalRequestCommunicationPage,
    RentalRequestFinancialPage,
    RentalRequestInfoPage,
    RentalRequestLogsPage,
    RentalRequestPage,
    RentalRequestPreferencesPage,
    RentalRequestRefusalsPage,
    RentalRequestsPage,
    RentalRequestWarningsPage,
} from './pages';

export const rentalRequestsRoutes: IRouteConfig[] = [
    {
        path: '/rental-requests',
        component: RentalRequestsPage,
        requiredPermissions: PermissionKeys.RENTAL_REQUESTS_READ,
    },
    {
        path: '/rental-requests/:id/*',
        component: RentalRequestPage,
        requiredPermissions: PermissionKeys.RENTAL_REQUESTS_READ,
    },
];

export const rentalRequestSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/info', component: RentalRequestInfoPage },
    { path: '/financial', component: RentalRequestFinancialPage },
    { path: '/preferences', component: RentalRequestPreferencesPage },
    { path: '/bond-with-the-city', component: RentalRequestBondWithTheCityPage },
    { path: '/additional-questions', component: RentalRequestAdditionalQuestionsPage },
    { path: '/refusals', component: RentalRequestRefusalsPage },
    { path: '/warnings', component: RentalRequestWarningsPage },
    { path: '/logs', component: RentalRequestLogsPage },
    { path: '/communication', component: RentalRequestCommunicationPage },
];
