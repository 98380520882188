import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, IDisapproveForm, useMailMessageList } from '../../../shared';
import { useDisapproveRentalRequest } from '../../hooks';
import { useDisapproveRentalRequestSchema } from '../../validators';

interface Props {
    id: string;
}

export const DisapproveRequestModal: FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const { data: mailMessages } = useMailMessageList({ pageSize: 100 });
    const { mutateAsync: disapproveRentalRequest, isPending } = useDisapproveRentalRequest();

    const form = useForm<IDisapproveForm>({ resolver: yupResolver(useDisapproveRentalRequestSchema()), mode: 'all' });

    const onSubmit = async (form: IDisapproveForm) => {
        await disapproveRentalRequest({
            id,
            statusChange: { message: `${form.mailMessages.join('\n')} \n${form.message}` },
        });
        onClose();
    };

    const onClose = () => {
        setShowModal(false);
        form.reset({ message: '' });
    };

    return (
        <>
            <Button onClick={() => setShowModal(true)} variant="outlined">
                {t('disapproveRentalRequest')}
            </Button>
            <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <DialogTitle>{t('disapproveRentalRequestModalTitle')}</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2}>
                                <ControlledSelect
                                    name="mailMessages"
                                    label={t('selectReasons')}
                                    multiple
                                    renderValue={(selected: any) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 0.5,
                                            }}
                                        >
                                            {selected.map((value: string) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {mailMessages?.data.map((message) => (
                                        <MenuItem value={message.text} key={message.id}>
                                            {message.text}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                                <ControlledInput name="message" label={t('writeReasons')} multiline />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" disabled={isPending}>
                                {t('disapproveRentalRequest')}
                            </Button>
                            <Button onClick={onClose} disabled={isPending}>
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};
