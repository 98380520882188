import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    AllocationStatus,
    formatDateTimeString,
    formatSocialSecurityNumber,
    IAllocationTrack,
    IParams,
    IRentalRequestList,
    Table,
    useGetPossibleCandidatesForAllocationTrack,
    useQueryParams,
} from '../../../shared';
import { useTheme } from '../../../main/hooks';

interface Props {
    onlyVerifiedCandidates: boolean;
    setSelected: (selected: string[]) => void;
    allocationTrack: IAllocationTrack;
}

export const AllocationTrackAddCandidatesData: FC<Props> = ({
    onlyVerifiedCandidates,
    setSelected,
    allocationTrack,
}) => {
    const { t } = useTranslation();
    const { id } = (useParams<keyof IParams>() as IParams) || '';
    const { palette } = useTheme();
    const { showRentWarning, sortOrderPossibleCandidates, theme } = window.carity.environment;

    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const { data: candidates, isLoading } = useGetPossibleCandidatesForAllocationTrack(id, {
        page,
        pageSize,
        onlyVerifiedCandidates,
    });

    const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
        setSelected(ids as string[]);
    };

    const columns: GridColDef[] = useMemo(
        () => [
            ...(showRentWarning
                ? [
                      {
                          field: 'showRentWarning',
                          headerName: ' ',
                          width: 8,
                          sortable: false,
                          renderCell: ({ row }: GridCellParams) =>
                              row.showRentWarning && (
                                  <Tooltip title={t('showRentWarning', { context: theme.name })}>
                                      <WarningIcon color="warning" sx={{ mt: 1.5 }} />
                                  </Tooltip>
                              ),
                      },
                  ]
                : []),
            {
                field: 'socialSecurityNumber',
                headerName: t('socialSecurityNumber'),
                minWidth: 150,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => formatSocialSecurityNumber(row?.primaryResident?.socialSecurityNumber),
            },

            {
                field: 'firstName,lastName',
                headerName: t('name'),
                minWidth: 200,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => (
                    <>
                        {row?.primaryResident?.firstName} {row?.primaryResident?.lastName}
                    </>
                ),
            },
            {
                field: 'refusals',
                headerName: t('refusalReason'),
                minWidth: 200,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => {
                    const refusals = getRefusals(row, allocationTrack);
                    if (refusals) {
                        return (
                            <Tooltip title={refusals.reasons} arrow>
                                <Typography
                                    variant="inherit"
                                    sx={{
                                        color: refusals.refusedForSameTypeOrProperty ? palette.error.main : 'inherit',
                                    }}
                                >
                                    {refusals.reasons}
                                </Typography>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                field: 'score',
                headerName: t('score'),
                minWidth: 50,
                flex: 0.5,
                sortable: false,
            },
            {
                field: 'referenceIncome',
                headerName: t('referenceIncome'),
                minWidth: 100,
                flex: 0.5,
                sortable: false,
            },
            {
                field: 'submittedDate',
                headerName: t('submittedDate'),
                minWidth: 50,
                flex: 0.5,
                sortable: false,
                valueFormatter: (value: string) => formatDateTimeString(value),
            },
            {
                field: 'status',
                headerName: t('status'),
                minWidth: 100,
                flex: 0.5,
                sortable: false,
                valueFormatter: (value: string) => t(value),
            },
        ],
        [allocationTrack, palette.error.main, showRentWarning, t, theme.name],
    );

    const filteredColumns = useMemo(() => {
        switch (sortOrderPossibleCandidates) {
            case 'leuven':
                return columns.filter((col) => col.field !== 'referenceIncome');
            default:
                return columns;
        }
    }, [sortOrderPossibleCandidates, columns]);

    return (
        <Table
            loading={isLoading}
            checkboxSelection
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            rows={candidates?.data || []}
            columns={filteredColumns}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            rowCount={candidates?.pagination?.size || 0}
            sx={{
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none',
                },
            }}
        />
    );
};

function getRefusals(
    rentalRequest: IRentalRequestList,
    allocationTrack: IAllocationTrack,
): { reasons: string | undefined; refusedForSameTypeOrProperty: boolean } | undefined {
    const typeIds = allocationTrack.propertyType
        ? [allocationTrack.propertyType?.id]
        : allocationTrack.properties.map(({ type }) => type?.id);

    const refusals = rentalRequest.allocations?.filter(({ status }) => status === AllocationStatus.REFUSED) || [];
    const refusalsForTypes = refusals?.filter(({ property }) => property && typeIds.includes(property.type.id)) || [];

    if (refusals.length > 0) {
        return {
            reasons: refusals.map(({ refusal }) => refusal?.text).join(', '),
            refusedForSameTypeOrProperty: refusalsForTypes.length > 0,
        };
    }
}
