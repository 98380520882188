import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShowDrawer } from '../../../shared';
import MenuIcon from '@mui/icons-material/Menu';

export const AppTitle: FC = () => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;
    const navigate = useNavigate();
    const { showDrawer, setShowDrawer, hasDrawer } = useShowDrawer();

    return (
        <Stack direction="row" id="app-title">
            {hasDrawer && (
                <IconButton
                    className="drawer-button"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setShowDrawer(!showDrawer)}
                    sx={{ mr: 2, display: { xl: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Typography onClick={() => navigate('')} variant="h1" sx={{ cursor: 'pointer' }}>
                <Box className="app-logo" sx={{ display: hasDrawer ? { xs: 'none', xl: 'flex' } : 'flex' }}>
                    <img src={theme.logo} style={{ height: '100%' }} alt="Carity" />
                </Box>
                <Box>{t('nav.title', { context: theme.name })}</Box>
            </Typography>
        </Stack>
    );
};
