import { allocationClient } from '../clients';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { IAllocation, ISaveAppointment, ISaveRefusal, QueryKeys } from '../../shared';
import { AxiosError } from 'axios';

const client = allocationClient;
const itemKey = QueryKeys.AllocationTrack;

export function useAllocate(): UseMutationResult<IAllocation, AxiosError, { allocationId: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ allocationId }) => client.allocate(allocationId),
        onSuccess: async ({ allocationTrackId }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, allocationTrackId] });
        },
    });
}

export function useSaveAppointment(): UseMutationResult<
    IAllocation,
    AxiosError,
    { allocationId: string; item: ISaveAppointment }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ allocationId, item }) => client.saveAppointment(allocationId, item),
        onSuccess: async ({ allocationTrackId }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, allocationTrackId] });
        },
    });
}

export function useSaveRefusal(): UseMutationResult<
    IAllocation,
    AxiosError,
    { allocationId: string; item: ISaveRefusal }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ allocationId, item }) => client.saveRefusal(allocationId, item),
        onSuccess: async ({ allocationTrackId }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, allocationTrackId] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AllocationTrackPossibleCandidates] });
        },
    });
}
