import { HomeTwoTone } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPrice, IParams, LabelValue, Page, Widget } from '../../../shared';
import { RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { useRentalRequest } from '../../hooks';
import { Stack } from '@mui/material';

export const RentalRequestPreferencesPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Widget title={t('residence')} icon={<HomeTwoTone color="primary" />}>
                <Stack spacing={2}>
                    <LabelValue
                        label={t('regions')}
                        value={rentalRequest?.preferences?.regions?.map(({ name }) => name)?.join(', ')}
                    />
                    <LabelValue
                        label={t('elevator')}
                        value={
                            rentalRequest?.preferences?.elevator && rentalRequest?.preferences?.elevator
                                ? t('yes')
                                : t('no')
                        }
                    />
                    <LabelValue
                        label={t('requiredAmountOfBedrooms')}
                        value={rentalRequest?.preferences?.requiredAmountOfBedrooms?.join(', ')}
                    />
                    {window.carity.environment.showPreferencesRentPrice && (
                        <LabelValue
                            label={t('maxRentPrice')}
                            value={
                                rentalRequest?.preferences?.maxRentPrice
                                    ? formatPrice(rentalRequest?.preferences?.maxRentPrice)
                                    : undefined
                            }
                        />
                    )}
                    {window.carity.environment.showPreferencesPropertyType && (
                        <LabelValue
                            label={t('propertyTypePreference')}
                            value={rentalRequest?.preferences?.propertyTypes?.join(', ')}
                        />
                    )}
                </Stack>
            </Widget>
        </Page>
    );
};
