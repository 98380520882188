import {
    ICaseManager,
    IMailLog,
    IRentalRequest,
    IRentalRequestList,
    IRentalRequestLog,
    IStatusChange,
    ListModel,
    ListQueryParam,
    ReadOnlyClient,
    rentingApi,
} from '../../shared';

class RentalRequestsClient extends ReadOnlyClient<IRentalRequest, ListQueryParam, IRentalRequestList> {
    constructor() {
        super(rentingApi, '/rental-requests');
    }

    public async startVerification(id: string): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(`${this.basePath}/${id}/start-verification`);
        return data;
    }

    public async cancelVerification(id: string): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(`${this.basePath}/${id}/cancel-verification`);
        return data;
    }

    public async approveRentalRequest(id: string): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(`${this.basePath}/${id}/verify-rental-request`);
        return data;
    }

    public async disapproveRentalRequest(id: string, statusChange: IStatusChange): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(
            `${this.basePath}/${id}/disapprove-rental-request`,
            statusChange,
        );
        return data;
    }

    public async reverifyRentalRequest(id: string): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(`${this.basePath}/${id}/reverify-rental-request`);
        return data;
    }

    public async requestChangesRentalRequest(id: string, statusChange: IStatusChange): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(
            `${this.basePath}/${id}/request-changes-rental-request`,
            statusChange,
        );
        return data;
    }

    public async getLogsList(
        rentalRequestId: string,
        { pageSize = 10, ...query }: ListQueryParam,
    ): Promise<ListModel<IRentalRequestLog>> {
        const { data } = await this.api.get<ListModel<IRentalRequestLog>>(`${this.basePath}/${rentalRequestId}/logs`, {
            params: { pageSize, ...query },
        });
        return data;
    }

    public async getMailList(
        rentalRequestId: string,
        { pageSize = 10, ...query }: ListQueryParam,
    ): Promise<ListModel<IMailLog>> {
        const { data } = await this.api.get<ListModel<IMailLog>>(`${this.basePath}/${rentalRequestId}/mail-logs`, {
            params: { pageSize, ...query },
        });
        return data;
    }

    public async upsertCaseManager(rentalRequestId: string, caseManager: ICaseManager): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(
            `${this.basePath}/${rentalRequestId}/case-manager`,
            caseManager,
        );
        return data;
    }

    public async contractSigned(id: string): Promise<IRentalRequest> {
        const { data } = await this.api.post<IRentalRequest>(`${this.basePath}/${id}/contract-signed`);
        return data;
    }
}

export const rentalRequestsClient = new RentalRequestsClient();
