import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledSelect,
    DialogTitleWithClose,
    IAllocationManagerForm,
    IAllocationTrack,
    useManagerList,
    useUpdateAllocationManager,
} from '../../../shared';
import { useManagerSchema } from '../../../rental-requests/validators';

interface Props {
    show: boolean;
    handleClose: () => void;
    allocationTrack: IAllocationTrack;
}

export const SaveAllocationManager: FC<Props> = ({ show, handleClose, allocationTrack }) => {
    const { t } = useTranslation();

    const { mutateAsync: saveAllocationManager } = useUpdateAllocationManager();
    const { data: managers } = useManagerList();

    const form = useForm<IAllocationManagerForm>({
        resolver: yupResolver(useManagerSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (allocationTrack) {
            form.reset({ userId: allocationTrack.allocationManager?.userId });
        }
    }, [allocationTrack, form]);

    const onSubmit = async (data: IAllocationManagerForm) => {
        const user = managers?.items?.find((user) => user.id === data.userId);
        await saveAllocationManager({
            allocationTrackId: allocationTrack.id,
            allocationManager: {
                ...data,
                name: user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email || '',
            },
        });
        handleClose();
    };

    return (
        <Dialog open={show}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <DialogTitleWithClose onClose={handleClose}>{t('updateAllocationManager')}</DialogTitleWithClose>
                    <DialogContent>
                        <Box sx={{ minWidth: 500 }}>
                            <ControlledSelect name="userId">
                                {managers?.items?.map((user) => (
                                    <MenuItem value={user.id} key={user.id}>
                                        {user?.firstName || user?.lastName
                                            ? `${user?.firstName} ${user?.lastName}`
                                            : user?.email}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained">
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose}>{t('cancel')}</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
