import axios from 'axios';
import { AuthListQueryParam, IUser, ListModelItems } from '../models';
import { authApi } from './auth-api.client';

class UsersClient {
    private basePath = '/v1/users';
    private api = authApi;

    public async getList(params: AuthListQueryParam, onGetErrorMessage?: string): Promise<ListModelItems<IUser>> {
        const { data } = await this.api.get(this.basePath, { params, errorMessage: onGetErrorMessage });

        return data;
    }

    public async getManagers(onGetErrorMessage?: string): Promise<ListModelItems<IUser>> {
        const { data } = await axios.get('/auth/managers', { errorMessage: onGetErrorMessage });
        return data;
    }
}

export const usersClient = new UsersClient();
