import { Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, RentalRequestStatus } from '../../../shared';
import {
    AdditionalQuestionWarnings,
    ContactInfo,
    ContractSigned,
    CurrentResidence,
    FamilySituation,
    RentalRequestHistory,
    RentalRequestRemarks,
    RentalRequestTitle,
    RentalRequestVerificationActions,
    RentalRequestWarnings,
} from '../../components';
import { useRentalRequest } from '../../hooks';

export const RentalRequestInfoPage: FC = () => {
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    const unansweredQuestions = useMemo(
        () => rentalRequest?.additionalQuestions?.filter((question) => !question.rentalRequestAssets?.length),
        [rentalRequest],
    );

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[
                <RentalRequestVerificationActions id={id} status={rentalRequest?.status} />,
                rentalRequest?.status === RentalRequestStatus.ALLOCATED && <ContractSigned id={rentalRequest.id} />,
            ]}
            loading={isLoading}
        >
            {rentalRequest && (
                <Grid container direction="row" alignItems="flex-start" spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12}>
                                <FamilySituation rentalRequest={rentalRequest} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CurrentResidence rentalRequest={rentalRequest} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ContactInfo rentalRequest={rentalRequest} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container direction="row" spacing={3}>
                            {!!rentalRequest?.errors?.length && (
                                <Grid item xs={12}>
                                    <RentalRequestWarnings />
                                </Grid>
                            )}

                            {!!unansweredQuestions?.length && (
                                <Grid item xs={12}>
                                    <AdditionalQuestionWarnings unansweredQuestions={unansweredQuestions} />
                                </Grid>
                            )}

                            {window.carity.environment.showRemarks && (
                                <Grid item xs={12}>
                                    <RentalRequestRemarks rentalRequest={rentalRequest} />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <RentalRequestHistory rentalRequestId={rentalRequest.id} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};
