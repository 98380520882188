import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    search: string;
    onSearch: (search: string) => void;
    inputAdornmentPosition?: 'start' | 'end';
}

export const Search: FC<Props> = ({ search, onSearch, inputAdornmentPosition = 'start' }) => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;

    return (
        <TextField
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            label={theme.name !== 'antwerp' ? t('shared.search') : undefined}
            placeholder={theme.name === 'antwerp' ? t('shared.search') : undefined}
            size="small"
            data-testid="search-input"
            InputProps={
                inputAdornmentPosition === 'end'
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <SearchIcon />
                              </InputAdornment>
                          ),
                      }
                    : {
                          startAdornment: (
                              <InputAdornment position="start">
                                  <SearchIcon />
                              </InputAdornment>
                          ),
                      }
            }
        />
    );
};
