import { formatDateString, IRentalRequest, IRentalRequestAsset, RentalRequestAssetType } from '../../shared';

type additionalAssets = {
    type: RentalRequestAssetType;
    assets: IRentalRequestAsset[];
};

type Residents = {
    id: string;
    name: string;
    expirationDateBudgetRentingDocument?: string;
    assets: additionalAssets[];
};

type Result = {
    id: string;
    name: string;
    validUntil?: string;
    type: RentalRequestAssetType;
    assets: IRentalRequestAsset[];
};

const documentTypes = [RentalRequestAssetType.BUDGET_RENTING_DOCUMENT, RentalRequestAssetType.RENT_SUBSIDY_DOCUMENT];

export function mapAdditionalFinancialAssets(rentalRequest?: IRentalRequest): Result[] {
    if (!rentalRequest) return [];

    const residents: Residents[] =
        rentalRequest?.residents?.map((resident) => {
            const mappedAssets =
                resident.financialData?.rentalRequestAssets
                    ?.filter(({ type }) => documentTypes.includes(type))
                    ?.reduce((group, asset) => {
                        const types = group.find(({ type }) => type === asset.type);
                        types ? types.assets.push(asset) : group.push({ type: asset.type, assets: [asset] });
                        return group;
                    }, [] as additionalAssets[]) || [];

            return {
                id: resident?.id,
                name: `${resident?.firstName} ${resident?.lastName}`,
                expirationDateBudgetRentingDocument: resident.financialData?.expirationDateBudgetRentingDocument,
                assets: mappedAssets,
            };
        }) || [];

    const mappedResult: Result[] = [];
    residents.forEach((resident) =>
        resident.assets?.forEach(({ type, assets }) =>
            mappedResult.push({
                ...resident,
                id: `${resident.id}_${type}`,
                type,
                assets: assets,
                validUntil:
                    type === RentalRequestAssetType.BUDGET_RENTING_DOCUMENT
                        ? formatDateString(resident?.expirationDateBudgetRentingDocument)
                        : '-',
            }),
        ),
    );

    return mappedResult;
}
