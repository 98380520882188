import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Options, OptionsToggle } from '../../../shared';
import {
    DashboardFilterType,
    IAppliedDashboardFilterDto,
    IAppliedDateFilterDto,
    IAppliedSelectFilterDto,
    IDashboardFilter,
} from '../../models';
import { DashboardDateFilter } from '../dashboard-date-filter/dashboard-date-filter.component';
import { DashboardSelectFilter } from '../dashboard-select-filter/dashboard-select-filter.component';

interface Props {
    availableFilters: IDashboardFilter[];
    onChange: (filters: IAppliedDashboardFilterDto[]) => void;
    filters: IAppliedDashboardFilterDto[];
}

type DashboardFilterForm = Record<string, string[]> & { startDate?: string; endDate?: string };

export const DashboardFilter: FC<Props> = ({ availableFilters, filters, onChange }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        ...mapAvailableFilterToFilterOptions(availableFilters),
    });

    const form = useForm();

    const hasFilterFormChanged = useHasFilterFormChanged(form, filters);

    const onFilterChange = useCallback(
        (formValues: DashboardFilterForm) => {
            onChange(mapFormValuesToAppliedFilters(formValues));
        },
        [onChange],
    );

    const activeAvailableFilters = useMemo(
        () => availableFilters.filter((filter) => options[filter.name]?.active),
        [availableFilters, options],
    );

    useEffect(() => {
        filters.forEach((filter) => {
            if (filter.type === DashboardFilterType.DATE) {
                form.setValue('startDate', filter.startDate);
                form.setValue('endDate', filter.endDate);
            }
            if (filter.type === DashboardFilterType.SELECT) {
                form.setValue(filter.target, filter.values);
            }
        });
    }, [filters, form]);

    useEffect(() => {
        setOptions({
            filter: { type: 'title', label: t('filter') },
            ...mapAvailableFilterToFilterOptions(availableFilters),
        });
    }, [availableFilters, t]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" spacing={1} alignItems="center">
                <OptionsToggle options={options} onChange={setOptions} />
                <Stack spacing={2} direction="row">
                    {activeAvailableFilters.map((filter) => (
                        <Fragment key={filter.name}>
                            {filter.type === DashboardFilterType.DATE && <DashboardDateFilter />}
                            {filter.type === DashboardFilterType.SELECT && <DashboardSelectFilter filter={filter} />}
                        </Fragment>
                    ))}
                </Stack>
                {hasFilterFormChanged && (
                    <IconButton onClick={form.handleSubmit(onFilterChange)}>
                        <RefreshOutlined color="primary" />
                    </IconButton>
                )}
            </Stack>
        </FormProvider>
    );
};

function useHasFilterFormChanged(
    form: UseFormReturn<DashboardFilterForm>,
    appliedFilters: IAppliedDashboardFilterDto[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const newlyAppliedFilters = mapFormValuesToAppliedFilters(formValues);
        return JSON.stringify(newlyAppliedFilters) !== JSON.stringify(appliedFilters);
    }, [appliedFilters, formValues]);
}

function mapFormValuesToAppliedFilters(filterForm: DashboardFilterForm): IAppliedDashboardFilterDto[] {
    const { startDate, endDate, ...rest } = filterForm;

    const appliedDateFilters: IAppliedDateFilterDto[] = [];
    if (startDate && endDate) {
        appliedDateFilters.push({ type: DashboardFilterType.DATE, startDate, endDate });
    }

    const appliedSelectFilters: IAppliedSelectFilterDto[] = Object.entries(rest)
        .map(
            ([target, values]): IAppliedSelectFilterDto => ({
                type: DashboardFilterType.SELECT,
                target,
                values,
            }),
        )
        .filter((appliedFilter) => appliedFilter.values.length);

    return [...appliedDateFilters, ...appliedSelectFilters];
}

function mapAvailableFilterToFilterOptions(availableFilters: IDashboardFilter[]) {
    return availableFilters.reduce((filters, { name }) => ({ ...filters, [name]: { active: false, label: name } }), {});
}
