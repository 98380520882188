import { IIndexation } from '../../admin/models';
import { IFinancialData, IResident, MULTIPLIER_MONTHLY_TO_YEARLY_INCOME } from '../../shared';

function calculateIncomeByYear(
    individualTaxableIncome: number,
    jointTaxableIncome: number,
    disabilityIncome: number,
    foreignIncome: number,
    livingWage: number,
) {
    return jointTaxableIncome + individualTaxableIncome + disabilityIncome + foreignIncome + livingWage;
}
function calculateIncomeByMonth(
    monthlyTaxableIncome: number,
    disabilityIncome: number,
    foreignIncome: number,
    livingWage: number,
) {
    return monthlyTaxableIncome * MULTIPLIER_MONTHLY_TO_YEARLY_INCOME + disabilityIncome + foreignIncome + livingWage;
}

export function calculateTotalIncome(
    residents: IResident[],
    currentIndexations?: IIndexation[],
    referenceIndex?: IIndexation,
) {
    return residents
        ?.map((resident) => resident?.financialData || ({} as IFinancialData))
        .reduce(
            (
                previousValue,
                {
                    disabilityIncome = 0,
                    foreignIncome = 0,
                    individualTaxableIncome = 0,
                    jointTaxableIncome = 0,
                    monthlyTaxableIncome = 0,
                    livingWage = 0,
                    taxYear = '',
                    hasTaxBillForFullYear = true,
                },
            ) => {
                let income = hasTaxBillForFullYear
                    ? calculateIncomeByYear(
                          individualTaxableIncome,
                          jointTaxableIncome,
                          disabilityIncome,
                          foreignIncome,
                          livingWage,
                      )
                    : calculateIncomeByMonth(monthlyTaxableIncome, disabilityIncome, foreignIncome, livingWage);

                if (currentIndexations && referenceIndex) {
                    income =
                        (income / (currentIndexations?.find((i) => i?.year?.toString() === taxYear)?.index || 0)) *
                        (referenceIndex?.index || 0);
                }
                return previousValue + income;
            },
            0,
        );
}
