import { createTheme } from '@mui/material';
import { enUS, nlNL } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import { createAntwerpTheme, createBaseTheme, createLeuvenTheme } from '../themes';

export const useTheme = () => {
    const { i18n } = useTranslation();
    const theme = window.carity.environment.theme;

    const locale = i18n.language === 'nl' ? nlNL : enUS;

    switch (theme.name) {
        case 'antwerp':
            return createTheme(createAntwerpTheme(theme), locale);
        case 'leuven':
            return createTheme(createLeuvenTheme(theme), locale);
        default:
            return createTheme(createBaseTheme(theme), locale);
    }
};
