import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Page, RemoveModal, Table, useDeleteMailMessage, useMailMessageList, useQueryParams } from '../../../shared';
import { DeleteOutline, Edit } from '@mui/icons-material';

export const MailMessagesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize } = useQueryParams({});

    const { data: mailMessages, isLoading: mailMessagesIsLoading } = useMailMessageList({ page, pageSize });

    const { mutateAsync: deleteMailMessage } = useDeleteMailMessage();
    const onDelete = async (id: string) => deleteMailMessage(id);

    const columns: GridColDef[] = [
        { field: 'text', headerName: t('answer'), flex: 1, sortable: false },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/admin/mail-messages/${params.row.id}/edit`} size="large">
                        <Edit />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => onDelete(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('mailMessageDeleteWarningTitle')}
                        text={t('mailMessageDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('mailMessages')}
            actions={[
                <Button component={Link} to="/admin/mail-messages/new" variant="contained">
                    {t('newMailMessage')}
                </Button>,
            ]}
        >
            <Table
                rows={mailMessages?.data || []}
                columns={columns}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                rowCount={mailMessages?.pagination.size || 0}
                loading={mailMessagesIsLoading}
                onRowClick={(row) => navigate(`/admin/mail-messages/${row.id}/edit`)}
            />
        </Page>
    );
};
