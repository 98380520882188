import { IRouteConfig, PermissionKeys } from '../shared';
import { PropertyDetailPage, PropertyEditPage, PropertyPage } from './pages';

export const propertiesRoutes: IRouteConfig[] = [
    { path: '/properties', component: PropertyPage, requiredPermissions: PermissionKeys.PROPERTIES_READ },
    {
        path: '/properties/:id',
        component: PropertyDetailPage,
        requiredPermissions: PermissionKeys.PROPERTIES_READ,
    },
    {
        path: '/properties/:id/edit',
        component: PropertyEditPage,
        requiredPermissions: PermissionKeys.PROPERTIES_WRITE,
    },
    {
        path: '/properties/new',
        component: PropertyEditPage,
        requiredPermissions: PermissionKeys.PROPERTIES_WRITE,
    },
];
