import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import React, { MouseEventHandler, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../dialog-title-with-close/dialog-title-with-close.component';

export function RemoveModal(props: {
    handleDelete: MouseEventHandler<HTMLButtonElement>;
    button: ReactElement;
    title: string;
    text: string | ReactNode;
}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const button = React.cloneElement(props.button, {
        onClick: (event: MouseEvent) => {
            event.stopPropagation();
            setOpen(true);
        },
    });

    return (
        <>
            {button}

            <Dialog open={open}>
                <DialogTitleWithClose onClose={() => setOpen(false)}>
                    <Grid container direction="row" alignItems="center">
                        <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                        {props.title}
                    </Grid>
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>{props.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(event) => {
                            setOpen(false);
                            props.handleDelete(event);
                        }}
                        autoFocus
                        variant="contained"
                        aria-label="confirm-delete"
                    >
                        {t('confirm')}
                    </Button>
                    <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
