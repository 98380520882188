import { DataGrid, GridFeatureMode } from '@mui/x-data-grid';
import React, { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTableFooter } from '../table-footer/table-footer.component';

interface Props extends Omit<ComponentProps<typeof DataGrid>, 'pagination'> {
    page?: number;
    setPage?: (page: number) => void;
    pageSize?: number;
    setPageSize?: (pageSize: number) => void;
    pagination?: boolean;
    mode?: GridFeatureMode;
}

export const Table: FC<Props> = ({
    page,
    setPage,
    pageSize = 5,
    setPageSize,
    mode = 'server',
    pagination,
    ...props
}) => {
    const { t } = useTranslation();

    const paginationOptions: Partial<ComponentProps<typeof DataGrid>> =
        pagination === false
            ? {
                  hideFooter: true,
              }
            : {
                  pagination: true as const,
                  paginationMode: mode,
                  pageSizeOptions: props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20],
                  paginationModel: { page: page ? page - 1 : 0, pageSize: pageSize },
                  onPaginationModelChange: (value) => {
                      setPage?.(value?.page + 1);
                      setPageSize?.(value?.pageSize || 5);
                  },
              };

    return (
        <DataGrid
            autoHeight
            pagination
            paginationMode={mode}
            sortingMode={mode}
            sortingOrder={['asc', 'desc']}
            disableColumnFilter
            disableColumnMenu
            slots={{ footer: CustomTableFooter }}
            localeText={{ noRowsLabel: t('noRows') }}
            {...paginationOptions}
            {...props}
        />
    );
};
