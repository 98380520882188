import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, IAllocationTrack, LabelValue, Widget } from '../../../shared';

interface Props {
    allocationTrack: IAllocationTrack;
}
export const AllocationTrackPropertyPrice: FC<Props> = ({ allocationTrack }) => {
    const { t } = useTranslation();
    return (
        <Widget>
            {allocationTrack.amountOfBedrooms === 1 ? (
                <LabelValue
                    label={t('indexedPropertyPrice')}
                    value={formatPrice(allocationTrack.propertyPrices?.highestPriceOneBedroom)}
                />
            ) : (
                <Stack spacing={1}>
                    <LabelValue
                        label={t('indexedPropertyPrice')}
                        value={formatPrice(allocationTrack.propertyPrices?.highestPriceOther)}
                    />
                    <LabelValue
                        label={t('indexedPropertyPriceSingleParent')}
                        value={formatPrice(allocationTrack.propertyPrices?.highestPriceSingleParents)}
                    />
                </Stack>
            )}
        </Widget>
    );
};
