import admin from './admin';
import main from './main';
import rentalRequests from './rental-requests';
import shared from './shared';
import validationTranslations from './validation';
import auth from './auth.nl';
import rentalRequestCommunication from './rental-request-communication';
import assets from './assets';
import errors from './errors';
import properties from './properties';
import allocation from './allocation';
import reporting from './reporting';

export default {
    translation: {
        ...main,
        ...shared,
        ...rentalRequests,
        ...admin,
        ...validationTranslations,
        ...auth,
        ...rentalRequestCommunication,
        ...assets,
        ...errors,
        ...properties,
        ...allocation,
        ...reporting,
    },
};
