import { IIndexation, IIndexationForm, ISaveIndexation } from '../models';

export const indexationToFormMapper = (indexation: IIndexation): IIndexationForm => {
    return {
        year: indexation.year,
        index: indexation.index,
    };
};
export const indexationFromFormMapper = (indexation: IIndexationForm): ISaveIndexation => {
    return {
        year: indexation.year,
        index: indexation.index,
    };
};
