export enum PermissionKeys {
    RENTAL_REQUESTS_READ = 'rental-requests-read',
    INDEXATIONS_READ = 'indexations-read',
    INDEXATIONS_WRITE = 'indexations-write',
    INCOME_SETTINGS_READ = 'income-settings-read',
    INCOME_SETTINGS_WRITE = 'income-settings-write',
    COMMUNICATION_SETTINGS_READ = 'communication-settings-read',
    COMMUNICATION_SETTINGS_WRITE = 'communication-settings-write',
    PROPERTY_TYPES_READ = 'property-types-read',
    PROPERTY_TYPES_WRITE = 'property-types-write',
    PROPERTIES_READ = 'properties-read',
    PROPERTIES_WRITE = 'properties-write',
    ALLOCATION_TRACKS_READ = 'allocation-tracks-read',
    ALLOCATION_TRACKS_WRITE = 'allocation-tracks-write',
    MAIL_MESSAGES_READ = 'mail-messages-read',
    MAIL_MESSAGES_WRITE = 'mail-messages-write',
    ADDITIONAL_QUESTIONS_WRITE = 'additional-questions-write',
}
