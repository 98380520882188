import React, { FC } from 'react';
import {
    formatSocialSecurityNumber,
    IAllocation,
    RemoveModal,
    Table,
    useDeleteAllocation,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, NotInterested, SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
    allocationTrackId: string;
    allocations?: IAllocation[];
}

export const DeniedAllocations: FC<Props> = ({ allocationTrackId, allocations }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const { mutateAsync: removeCandidate } = useDeleteAllocation();

    const deniedAllocations = allocations?.filter((allocation) => allocation.refusal);

    const onDelete = async (allocation: IAllocation) => {
        await removeCandidate({
            id: allocationTrackId,
            allocationId: allocation.id,
            rentalRequestId: allocation.rentalRequestId,
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'rentalRequest.socialSecurityNumber',
            headerName: t('socialSecurityNumber'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                formatSocialSecurityNumber(params?.row?.rentalRequest?.primaryResident?.socialSecurityNumber),
        },
        {
            field: 'firstName,lastName',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params?.row?.rentalRequest?.primaryResident?.lastName !== undefined ? (
                    <>
                        {params?.row?.rentalRequest?.primaryResident?.firstName}{' '}
                        {params?.row?.rentalRequest?.primaryResident?.lastName}
                    </>
                ) : (
                    <>{t('removedRentalRequest')}</>
                ),
        },
        {
            field: 'property.name',
            headerName: t('property'),
            minWidth: 200,
            flex: 0.75,
            sortable: false,
            renderCell: (params: GridCellParams) => params?.row?.property?.name,
        },
        {
            field: 'refusal.text',
            headerName: t('refusalText'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <Tooltip
                    title={<Box sx={{ maxHeight: 150, overflow: 'scroll' }}>{params.row.refusal.text}</Box>}
                    arrow
                    placement="bottom-end"
                >
                    <span>{params.row.refusal.text}</span>
                </Tooltip>
            ),
        },
        {
            field: 'refusal.createdByRequester',
            headerName: t('createdByRequester'),
            minWidth: 200,
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridCellParams) => (params?.row?.refusal?.createdByRequester ? t('yes') : t('no')),
        },

        {
            field: ' ',
            headerName: '',
            width: 250,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const deletedRentalRequest = params?.row?.rentalRequest?.primaryResident?.lastName === undefined;

                return (
                    <>
                        <IconButton
                            component="button"
                            onClick={() => navigate(`/rental-requests/${params?.row?.rentalRequest?.id}/info`)}
                            disabled={deletedRentalRequest}
                        >
                            <Tooltip title={t('showCandidateDetails')} arrow>
                                <SearchOutlined />
                            </Tooltip>
                        </IconButton>

                        <RemoveModal
                            handleDelete={() => onDelete(params.row)}
                            button={
                                <IconButton>
                                    <Tooltip title={t('deleteRefusal')} arrow>
                                        <DeleteOutline sx={{ color: 'gray' }} />
                                    </Tooltip>
                                </IconButton>
                            }
                            title={t('deleteRefusalWarningTitle')}
                            text={<span>{t('deleteRefusalWarningText')}</span>}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            {deniedAllocations?.length !== 0 && (
                <Widget hasTable icon={<NotInterested />} title={t('deniedAllocations')} sx={{ mt: 3 }}>
                    <Table
                        rows={deniedAllocations || []}
                        columns={columns}
                        page={page}
                        pageSize={pageSize}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        mode="client"
                    />
                </Widget>
            )}
        </>
    );
};
