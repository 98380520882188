import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page } from '../../../shared';
import {
    RentalRequestAdditionalFinancialAssets,
    RentalRequestFinancialReferenceIncome,
    RentalRequestRentingPrice,
    RentalRequestTitle,
    RentalRequestVerificationActions,
} from '../../components';
import { useRentalRequest } from '../../hooks';
import { Stack } from '@mui/material';

export const RentalRequestFinancialPage: FC = () => {
    const navigate = useNavigate();

    const { budgetRentingDocument } = window.carity.environment;
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Stack spacing={2}>
                <RentalRequestRentingPrice />
                <RentalRequestFinancialReferenceIncome />
                {budgetRentingDocument && <RentalRequestAdditionalFinancialAssets />}
            </Stack>
        </Page>
    );
};
