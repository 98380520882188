import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledSelect,
    DialogTitleWithClose,
    ICaseManagerForm,
    IRentalRequest,
    useGetCurrentUser,
    useManagerList,
} from '../../../shared';
import { useRentalRequestUpsertCaseManager } from '../../hooks';
import { useManagerSchema } from '../../validators';

interface Props {
    show: boolean;
    rentalRequest: IRentalRequest;
    handleClose: () => void;
}

export const SaveCaseManager: FC<Props> = ({ show, rentalRequest, handleClose }) => {
    const { t } = useTranslation();

    const { mutateAsync: saveCaseManager } = useRentalRequestUpsertCaseManager();
    const { data: managers } = useManagerList();
    const { data: currentUser } = useGetCurrentUser();

    const form = useForm<ICaseManagerForm>({
        resolver: yupResolver(useManagerSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (rentalRequest?.caseManager) {
            form.reset({ userId: rentalRequest.caseManager.userId });
        } else {
            form.reset({ userId: currentUser?.data?.id });
        }
    }, [rentalRequest, form, currentUser]);

    const onSubmit = async (data: ICaseManagerForm) => {
        const user = managers?.items?.find((user) => user.id === data.userId);
        await saveCaseManager({
            rentalRequestId: rentalRequest.id,
            caseManager: {
                ...data,
                name: user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email || '',
            },
        });
        handleClose();
    };

    return (
        <Dialog open={show}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <DialogTitleWithClose onClose={handleClose}>{t('updateCaseManager')}</DialogTitleWithClose>
                    <DialogContent>
                        <Box sx={{ minWidth: 500 }}>
                            <ControlledSelect name="userId">
                                {managers?.items?.map((user) => (
                                    <MenuItem value={user.id} key={user.id}>
                                        {user?.firstName || user?.lastName
                                            ? `${user?.firstName} ${user?.lastName}`
                                            : user?.email}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained">
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose}>{t('cancel')}</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
