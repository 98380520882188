import { QueryKeys } from '../enums';
import {
    IPropertyType,
    propertyTypesClient,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../index';

const client = propertyTypesClient;
const listKey = QueryKeys.PropertyTypes;
const itemKey = QueryKeys.PropertyType;

export function usePropertyTypeList(...args: UseListParams<IPropertyType>) {
    return useList(client, listKey, ...args);
}

export function usePropertyType(...args: UseItemParams<IPropertyType>) {
    return useItem(client, itemKey, ...args);
}

export function useSavePropertyType() {
    return useSave(client, listKey, itemKey);
}

export function useDeletePropertyType() {
    return useDelete(client, listKey);
}
