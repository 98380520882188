import React, { FC } from 'react';
import {
    formatPrice,
    formatSocialSecurityNumber,
    IAllocation,
    IAllocationTrack,
    IRentalRequest,
    Table,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Box, Tooltip, Typography } from '@mui/material';
import { Group } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { AllocationTrackDeadlines } from '../allocation-track-deadlines/allocation-track-deadlines.component';
import { AllocationTrackActions } from '../allocation-track-actions/allocation-track-actions.component';

interface Props {
    allocationTrack: IAllocationTrack;
    allocations?: IAllocation[];
}

export const ActiveAllocations: FC<Props> = ({ allocationTrack, allocations }) => {
    const { t } = useTranslation();
    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const { showRentWarning, theme } = window.carity.environment;

    const columns: GridColDef[] = [
        ...(showRentWarning
            ? [
                  {
                      field: 'rentalRequest.showRentWarning',
                      headerName: ' ',
                      width: 8,
                      sortable: false,
                      renderCell: ({ row }: GridCellParams) =>
                          row.rentalRequest?.showRentWarning && (
                              <Tooltip title={t('showRentWarning', { context: theme.name })}>
                                  <WarningIcon color="warning" sx={{ mt: 1.5 }} />
                              </Tooltip>
                          ),
                  },
              ]
            : []),
        {
            field: 'rentalRequest.caseManager.name',
            headerName: t('caseManager'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridCellParams) => params?.row?.rentalRequest?.caseManager?.name,
        },
        {
            field: 'rentalRequest.socialSecurityNumber',
            headerName: t('socialSecurityNumber'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                formatSocialSecurityNumber(params?.row?.rentalRequest?.primaryResident?.socialSecurityNumber),
        },
        {
            field: 'firstName,lastName',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params?.row?.rentalRequest?.primaryResident?.lastName !== undefined ? (
                    <>
                        {params?.row?.rentalRequest?.primaryResident?.firstName}{' '}
                        {params?.row?.rentalRequest?.primaryResident?.lastName}
                    </>
                ) : (
                    <>{t('removedRentalRequest')}</>
                ),
        },
        {
            field: 'rentalRequest',
            headerName: t('status'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            valueFormatter: (value: IRentalRequest) => t(value?.status),
        },
        {
            field: 'property.name',
            headerName: t('property'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
                    <Tooltip
                        arrow
                        title={t('propertyMonthlyCost', {
                            rent: formatPrice(params?.row?.property?.currentMonthlyRentForRentalRequest),
                            extra: formatPrice(params?.row?.property?.currentMonthlyExtraCostForRentalRequest),
                        })}
                    >
                        <Typography>{params?.row?.property?.name}</Typography>
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'deadline',
            headerName: t('nextAction'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) => <AllocationTrackDeadlines allocation={params.row} />,
        },
        {
            field: ' ',
            headerName: '',
            width: 260,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <AllocationTrackActions allocation={params.row} allocationTrack={allocationTrack} />
            ),
        },
    ];

    return (
        <>
            {allocations?.length !== 0 && (
                <Widget hasTable icon={<Group />} title={t('activeAllocations')}>
                    <Table
                        rows={allocations || []}
                        columns={columns}
                        page={page}
                        pageSize={pageSize}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        mode="client"
                    />
                </Widget>
            )}
        </>
    );
};
