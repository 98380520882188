import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Grid, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, IExpectingChildren, LabelValue, RentalRequestAssetType } from '../../../shared';
import { DownloadAssets } from '../download-assets/download-assets.component';

interface Props {
    expectingChildren?: IExpectingChildren;
}

export const ExpectingChildrenInfo: FC<Props> = ({ expectingChildren }) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <ListItem sx={{ p: 0 }}>
                <ListItemButton sx={{ p: 2 }} onClick={() => setShowMore((prev) => !prev)}>
                    <ListItemText primary={t('expectingChildren')} />
                    {showMore ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={showMore} timeout="auto" unmountOnExit>
                <ListItem sx={{ p: 2, pt: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('expectedAmount')}
                                value={expectingChildren?.expectedAmount?.toString()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('expectedDate')}
                                value={formatDateString(expectingChildren?.expectedDate)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('pregnancyDocument')}
                                value={
                                    <DownloadAssets
                                        rentalRequestAssets={expectingChildren?.rentalRequestAssets}
                                        rentalRequestAssetType={RentalRequestAssetType.PREGNANCY_DOCUMENT}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </ListItem>
            </Collapse>
        </>
    );
};
