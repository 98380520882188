export enum RentalRequestStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    IN_VERIFICATION = 'IN_VERIFICATION',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
    VERIFIED = 'VERIFIED',
    INVITED = 'INVITED',
    APPOINTMENT_PLANNED = 'APPOINTMENT_PLANNED',
    AWAITING_VISIT_FEEDBACK = 'AWAITING_VISIT_FEEDBACK',
    ALLOCATED = 'ALLOCATED',
    CONTRACT_SIGNED = 'CONTRACT_SIGNED',
    DISAPPROVED = 'DISAPPROVED',
}
