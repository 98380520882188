import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ContactType, IInvite, ISaveInvite, QueryKeys } from '../../shared';
import { invitesClient } from '../clients';
import * as html2pdf from 'html2pdf.js';

const client = invitesClient;
const listKey = QueryKeys.AllocationTracks;
const itemKey = QueryKeys.AllocationTrack;

export function useCreateInvite(): UseMutationResult<IInvite, AxiosError, { item: ISaveInvite }> {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: ({ item }) => client.createOne(item),
        onSuccess: async ({ allocation, contactType, letterInviteHtml }) => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({
                queryKey: [itemKey, allocation?.allocationTrackId],
            });

            if (contactType == ContactType.LETTER) {
                html2pdf()
                    .set({ html2canvas: { useCORS: true } })
                    .from(letterInviteHtml, 'string')
                    .save(`${t('invite')}-${allocation?.property?.name}.pdf`);
            }
        },
    });
}

export function useDownloadInvite(
    contactType: ContactType,
    propertyId: string,
    rentalRequestId: string,
): UseQueryResult<string, AxiosError> {
    return useQuery<string, AxiosError>({
        queryKey: [QueryKeys.InvitePreview, { contactType, propertyId, rentalRequestId }],
        queryFn: () => client.downloadInvite(contactType, propertyId, rentalRequestId),
    });
}
