import { Alert } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../shared';
import { formatEpoch } from '../../formatters';
import { useChart } from '../../hooks';
import { ChartColumnType, IAppliedDashboardFilterDto, IChart } from '../../models';
import './chart.component.scss';

interface Props {
    id: number;
    filters: IAppliedDashboardFilterDto[];
}

const TOTAL_ROW_ID = -1;

export const Chart: FC<Props> = ({ id, filters }) => {
    const { data: chart, isLoading } = useChart(id, filters);
    const { t } = useTranslation();

    const rows = useChartRows(chart);
    const columns = useChartColumns(chart);

    return rows.length > 0 ? (
        <Table
            getRowId={getRowId}
            getRowClassName={getRowClassName}
            rows={rows}
            columns={columns}
            loading={isLoading}
            pagination={false}
        />
    ) : (
        <Alert severity="info">{t('noRows')}</Alert>
    );
};

function useChartRows(chart: IChart | undefined) {
    return useMemo(() => {
        const { data = [], totals } = chart || {};
        return totals && data.length > 0 ? [...data, { ...totals, rowId: TOTAL_ROW_ID }] : [...data];
    }, [chart]);
}

function useChartColumns(chart: IChart | undefined): GridColDef[] {
    return useMemo(
        () =>
            chart?.columns.map((column) => ({
                field: column.name,
                flex: 1,
                valueFormatter: (value: unknown) => formatTableValue(value, column.type),
            })) || [],
        [chart?.columns],
    );
}

function getRowId(entry: GridValidRowModel): number {
    return entry.rowId;
}

function getRowClassName(entry: GridValidRowModel): string {
    return entry.id === TOTAL_ROW_ID ? 'report-total-row' : '';
}

function formatTableValue(value: unknown, type: ChartColumnType): unknown {
    switch (type) {
        case ChartColumnType.DATE:
            return formatEpoch(value as number);
        case ChartColumnType.STRING:
            return value;
    }
}
