import {
    IAllocationManager,
    IAllocationTrack,
    IAllocationTrackQuery,
    IPossibleCandidatesQuery,
    IProperty,
    IRentalRequestList,
    ISaveAllocationTrack,
    ListModel,
    ListQueryParam,
} from '../index';
import { BaseClient } from './base.client';
import { rentingApi } from './renting-api.client';

class AllocationTracksClient extends BaseClient<IAllocationTrack, ISaveAllocationTrack, IAllocationTrackQuery> {
    constructor() {
        super(rentingApi, '/allocation-tracks');
    }

    public async updateAllocationManager(
        allocationTrackId: string,
        allocationManager: IAllocationManager,
    ): Promise<IAllocationTrack> {
        const { data } = await this.api.post<IAllocationTrack>(
            `${this.basePath}/${allocationTrackId}/allocation-manager`,
            allocationManager,
        );
        return data;
    }

    public async getPossibleCandidates(
        id: string,
        query: IPossibleCandidatesQuery,
    ): Promise<ListModel<IRentalRequestList>> {
        const { data } = await this.api.get<ListModel<IRentalRequestList>>(
            `${this.basePath}/${id}/possible-candidates`,
            {
                params: { ...query },
            },
        );
        return data;
    }

    public async addCandidates(id: string, rentalRequestIds: string[]): Promise<IAllocationTrack> {
        const { data } = await this.api.post<IAllocationTrack>(`${this.basePath}/${id}/allocations`, {
            rentalRequestIds,
        });
        return data;
    }

    public async removeAllocation(id: string, allocationId: string): Promise<IAllocationTrack> {
        const { data } = await this.api.delete<IAllocationTrack>(`${this.basePath}/${id}/allocations/${allocationId}`);
        return data;
    }

    public async getUnallocatedPropertiesForAllocationTrack(
        id: string,
        query: ListQueryParam,
    ): Promise<ListModel<IProperty>> {
        const { data } = await this.api.get<ListModel<IProperty>>(`${this.basePath}/${id}/possible-properties`, {
            params: { ...query },
        });
        return data;
    }

    public async addProperties(id: string, propertyIds: string[]): Promise<IAllocationTrack> {
        const { data } = await this.api.post<IAllocationTrack>(`${this.basePath}/${id}/properties`, { propertyIds });
        return data;
    }

    public async removeProperty(id: string, propertyId: string): Promise<IAllocationTrack> {
        const { data } = await this.api.delete<IAllocationTrack>(`${this.basePath}/${id}/properties/${propertyId}`);
        return data;
    }

    public async closeAllocationTrack(id: string): Promise<IAllocationTrack> {
        const { data } = await this.api.post<IAllocationTrack>(`${this.basePath}/${id}/close`);
        return data;
    }
}

export const allocationTracksClient = new AllocationTracksClient();
