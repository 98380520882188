import { GroupTwoTone } from '@mui/icons-material';
import { Chip, Divider, List } from '@mui/material';
import React, { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, Widget } from '../../../shared';
import { compareResidents } from '../../utils';
import { ExpectingChildrenInfo } from '../expecting-children-info/expecting-children-info.component';
import { ResidentInfo } from '../resident-info/resident-info.component';

interface Props {
    rentalRequest: IRentalRequest;
}
export const FamilySituation: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    const primaryResident = useMemo(() => {
        return rentalRequest.residents?.find((resident) => resident.isPrimaryResident);
    }, [rentalRequest]);

    const otherResidents = useMemo(() => {
        return rentalRequest.residents?.filter((resident) => !resident.isPrimaryResident).sort(compareResidents);
    }, [rentalRequest]);

    return (
        <Widget
            title={t('familySituation')}
            icon={<GroupTwoTone color="primary" />}
            hasTable={true}
            actions={
                !!rentalRequest.deletedMagdaResidents && (
                    <Chip
                        label={t('deletedMagdaResidents', { count: rentalRequest.deletedMagdaResidents })}
                        color="warning"
                        variant="outlined"
                        size="small"
                    />
                )
            }
        >
            <>
                {primaryResident && otherResidents && (
                    <List sx={{ p: 0 }}>
                        <ResidentInfo resident={primaryResident} />

                        {otherResidents?.map((resident) => (
                            <Fragment key={resident.id}>
                                <Divider component="li" />
                                <ResidentInfo resident={resident} />
                            </Fragment>
                        ))}

                        {rentalRequest?.expectingChildren?.isExpecting && (
                            <>
                                <Divider component="li" />
                                <ExpectingChildrenInfo expectingChildren={rentalRequest?.expectingChildren} />
                            </>
                        )}
                    </List>
                )}
            </>
        </Widget>
    );
};
