import { IRefusal, ListModel, ListQueryParam, rentingApi } from '../../shared';

class RefusalsClient {
    private basePath = '/rental-requests';
    private api = rentingApi;

    public async getList(requestId: string, { pageSize = 10, ...query }: ListQueryParam): Promise<ListModel<IRefusal>> {
        const { data } = await this.api.get<ListModel<IRefusal>>(`${this.basePath}/${requestId}/refusals`, {
            params: { pageSize, ...query },
        });
        return data;
    }
}

export const refusalsClient = new RefusalsClient();
