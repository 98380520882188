import { DialogTitleWithClose, IAllocation } from '../../../shared';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllocate } from '../../hooks';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';

interface Props {
    show: boolean;
    handleClose: () => void;
    allocation?: IAllocation | null;
}

export const CompleteAllocationModal: FC<Props> = ({ show, handleClose, allocation }) => {
    const { t } = useTranslation();

    const { mutateAsync: allocate, isPending } = useAllocate();

    const onSubmit = async () => {
        if (allocation) {
            await allocate({ allocationId: allocation.id });
            handleClose();
        }
    };

    return (
        <Dialog open={show} onClose={handleClose}>
            <DialogTitleWithClose onClose={handleClose}>{t('allocate')}</DialogTitleWithClose>
            <DialogContent sx={{ minWidth: '400px' }}>
                {t('allocatePropertyToCandidate', {
                    propertyName: allocation?.property?.name,
                    candidateName: `${allocation?.rentalRequest.primaryResident?.firstName} ${allocation?.rentalRequest.primaryResident?.lastName}`,
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    disabled={isPending}
                    startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                    onClick={() => onSubmit()}
                >
                    {t('confirm')}
                </Button>
                <Button onClick={handleClose} disabled={isPending}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
