import {
    AllocationStatus,
    formatDateString,
    formatDateTimeString,
    IAllocation,
    IAllocationTrack,
    RemoveModal,
    RentalRequestStatus,
    useDeleteAllocation,
} from '../../../shared';
import { IconButton, Tooltip } from '@mui/material';
import {
    DeleteOutline,
    Event,
    EventAvailable,
    MailOutline,
    MarkEmailReadOutlined,
    NotInterested,
    SearchOutlined,
    TaskAlt,
} from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SentInviteModal } from '../sent-invite-modal/sent-invite-modal.component';
import { AppointmentModal } from '../appointment-modal/appointment-modal.component';
import { CompleteAllocationModal } from '../complete-allocation-modal/complete-allocation-modal.component';
import { DenyAllocationModal } from '../deny-allocation-modal/deny-allocation-modal.component';

interface Props {
    allocation: IAllocation;
    allocationTrack?: IAllocationTrack;
}

class setState {
    show?: boolean = false;
    allocation?: IAllocation = undefined;
}

export const AllocationTrackActions: FC<Props> = ({ allocation, allocationTrack }) => {
    const { t } = useTranslation();
    const [inviteModal, setInviteModal] = useState<setState>();
    const [appointmentModal, setAppointmentModal] = useState<setState>();
    const [denyModal, setDenyModal] = useState<setState>();
    const [completeModal, setCompleteModal] = useState<setState>();

    const { mutateAsync: removeCandidate } = useDeleteAllocation();
    const onDelete = async (allocation: IAllocation) => {
        await removeCandidate({
            id: allocation.allocationTrackId,
            allocationId: allocation.id,
            rentalRequestId: allocation.rentalRequestId,
        });
    };

    const deletedRentalRequest = allocation.rentalRequest?.primaryResident?.lastName === undefined;
    const inviteDisabled = !allocation.invite && allocation.rentalRequest?.status !== RentalRequestStatus.VERIFIED;
    const appointmentDisabled =
        allocation.status !== AllocationStatus.INVITED && allocation.status !== AllocationStatus.APPOINTMENT_PLANNED;

    return (
        <>
            <IconButton
                sx={{ color: inviteDisabled ? 'gray' : undefined }}
                onClick={() =>
                    setInviteModal({
                        show: true,
                        allocation: allocation,
                    })
                }
                disabled={inviteDisabled || deletedRentalRequest}
            >
                <Tooltip
                    arrow
                    title={
                        allocation.invite
                            ? t('inviteDetails', {
                                  date: formatDateString(allocation.invite.createdDate.toString()),
                                  type: t(allocation.invite.contactType),
                                  interpolation: { escapeValue: false },
                              })
                            : t('sentInvite')
                    }
                >
                    {allocation.invite ? <MarkEmailReadOutlined /> : <MailOutline />}
                </Tooltip>
            </IconButton>

            <IconButton
                sx={{ color: appointmentDisabled ? 'gray' : undefined }}
                onClick={() => setAppointmentModal({ show: true, allocation: allocation })}
                disabled={appointmentDisabled || deletedRentalRequest}
            >
                <Tooltip
                    arrow
                    title={
                        allocation.appointment
                            ? t('appointmentDetails', {
                                  date: formatDateTimeString(allocation.appointment.date.toString()),
                                  interpolation: { escapeValue: false },
                              })
                            : t('makeAppointment')
                    }
                >
                    {allocation.appointment ? <EventAvailable /> : <Event />}
                </Tooltip>
            </IconButton>

            <IconButton
                onClick={() => setCompleteModal({ show: true, allocation: allocation })}
                disabled={deletedRentalRequest || appointmentDisabled}
            >
                <Tooltip
                    arrow
                    title={
                        allocation.refusal
                            ? t('allocateDetails', {
                                  date: formatDateTimeString(allocation.refusal.createdDate),
                                  interpolation: { escapeValue: false },
                              })
                            : t('allocate')
                    }
                >
                    <TaskAlt />
                </Tooltip>
            </IconButton>

            <IconButton
                onClick={() => setDenyModal({ show: true, allocation: allocation })}
                disabled={deletedRentalRequest || appointmentDisabled}
            >
                <Tooltip
                    arrow
                    title={
                        allocation.refusal
                            ? t('denyDetails', {
                                  date: formatDateTimeString(allocation.refusal.createdDate),
                                  interpolation: { escapeValue: false },
                              })
                            : t('denyAllocation')
                    }
                >
                    <NotInterested />
                </Tooltip>
            </IconButton>

            <IconButton
                component="button"
                onClick={() => window.open(`/rental-requests/${allocation.rentalRequest?.id}/info`, '_blank')}
                disabled={deletedRentalRequest}
            >
                <Tooltip title={t('showCandidateDetails')} arrow>
                    <SearchOutlined />
                </Tooltip>
            </IconButton>

            <RemoveModal
                handleDelete={() => onDelete(allocation)}
                button={
                    <IconButton>
                        <Tooltip title={t('removeAllocation')} arrow>
                            <DeleteOutline sx={{ color: 'gray' }} />
                        </Tooltip>
                    </IconButton>
                }
                title={t('deleteCandidateFromAllocationTrackWarningTitle')}
                text={t('deleteCandidateFromAllocationTrackWarningText')}
            />

            <SentInviteModal
                show={inviteModal?.show || false}
                handleClose={() => setInviteModal({ show: false, allocation: undefined })}
                properties={allocationTrack?.properties || []}
                allocation={inviteModal?.allocation}
                allocations={allocationTrack?.allocations}
            />

            <AppointmentModal
                show={appointmentModal?.show || false}
                handleClose={() => setAppointmentModal({ show: false, allocation: undefined })}
                allocation={appointmentModal?.allocation}
            />

            <CompleteAllocationModal
                show={completeModal?.show || false}
                handleClose={() => setCompleteModal({ show: false, allocation: undefined })}
                allocation={completeModal?.allocation}
            />

            <DenyAllocationModal
                show={denyModal?.show || false}
                handleClose={() => setDenyModal({ show: false, allocation: undefined })}
                allocation={denyModal?.allocation}
            />
        </>
    );
};
