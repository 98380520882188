import { DeleteOutline, ThumbDownTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    formatDateString,
    IParams,
    Page,
    RemoveModal,
    SortOrder,
    Table,
    useDeleteAllocation,
    useQueryParams,
    Widget,
} from '../../../shared';
import { RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { useRefusalList, useRentalRequest } from '../../hooks';

export const RentalRequestRefusalsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);
    const { mutateAsync: deleteAllocation } = useDeleteAllocation();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });
    const { data: refusals, isLoading: refusalsIsLoading } = useRefusalList(id, {
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            valueFormatter: (value: string) => formatDateString(value),
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'allocation.property.name',
            headerName: t('property'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) => params?.row?.allocation?.property?.name,
        },
        {
            field: 'text',
            headerName: t('comment'),
            flex: 1.5,
            sortable: false,
        },
        {
            field: 'createdByRequester',
            headerName: t('createdByRequester'),
            flex: 1,
            sortable: false,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: 'edit',
            headerName: ' ',
            sortable: false,
            width: 110,
            align: 'right',
            renderCell: (params) => (
                <>
                    <RemoveModal
                        handleDelete={() =>
                            deleteAllocation({
                                id: params.row.allocation.allocationTrackId,
                                allocationId: params.row.allocation.id,
                                rentalRequestId: params.row.allocation.rentalRequestId,
                            })
                        }
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('deleteRefusalWarningTitle')}
                        text={
                            <>
                                {t('deleteRefusalWarningText')}
                                <br />
                                {formatDateString(params.row.createdDate)} {params.row.text && `- ${params.row.text}`}
                            </>
                        }
                    />
                </>
            ),
        },
    ];
    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Widget icon={<ThumbDownTwoTone color="primary" />} hasTable title={t('refusals')}>
                <Table
                    rows={refusals?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={refusals?.pagination.size || 0}
                    loading={refusalsIsLoading}
                    sortModel={sort}
                    onSortModelChange={setSort}
                />
            </Widget>
        </Page>
    );
};
