import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { ControlledInput, IParams, ISaveMailMessage, Page, useMailMessage, useSaveMailMessage } from '../../../shared';

export const MailMessageEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: mailMessage, isFetching: isFetchingItem } = useMailMessage(id);
    const { mutateAsync: saveMailMessage, isPending: isSaving } = useSaveMailMessage();

    const schema = object().shape({ text: string().required() });
    const form = useForm<ISaveMailMessage>({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (mailMessage) {
            form.reset(mailMessage);
        }
    }, [mailMessage, form]);

    const onSubmit = useCallback(
        async (item: ISaveMailMessage) => {
            await saveMailMessage({ id, item: item });
            navigate('/admin/mail-messages');
        },
        [id, navigate, saveMailMessage],
    );

    const actions = useMemo(
        () => [
            <Button key="submit" variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetchingItem}>
                {t('save')}
            </Button>,
            <Button key={'cancel'} onClick={() => navigate('/admin/mail-messages')}>
                {t('cancel')}
            </Button>,
        ],
        [t, form, onSubmit, navigate, isFetchingItem],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate('/admin/mail-messages')}
            title={mailMessage ? t('updateMailMessage') : t('newMailMessage')}
            loading={isFetchingItem || isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card>
                        <CardContent>
                            <ControlledInput name="text" label={t('answer')} rows={4} multiline />
                        </CardContent>
                        <CardActions sx={{ bgcolor: 'background.default' }}>{actions}</CardActions>
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
