import React, { FC, forwardRef } from 'react';
import { InputAttributes, NumericFormat } from 'react-number-format';
import { ControlledInput } from '../controlled-input/controlled-input.component';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    decimalScale?: number;
    thousandSeparator?: string;
};

interface NumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    decimalScale?: number;
    thousandSeparator?: string;
}

const NumberInput = forwardRef<typeof NumericFormat<InputAttributes>, NumberFormatProps>(
    ({ onChange, name, decimalScale = 2, thousandSeparator = '', ...other }, ref) => {
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={thousandSeparator}
                decimalSeparator=","
                valueIsNumericString
                allowNegative={false}
                decimalScale={decimalScale}
            />
        );
    },
);

export const ControlledNumberInput: FC<Props> = ({ name, label, required, decimalScale, thousandSeparator }) => {
    return (
        <ControlledInput
            InputProps={{
                sx: { minWidth: '100%', maxWidth: '200px' },
                inputComponent: NumberInput as any,
            }}
            inputProps={{ decimalScale, thousandSeparator }}
            name={name}
            label={label}
            required={required}
        />
    );
};
