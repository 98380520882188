import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { incomeSettingsClient } from '../clients';
import { QueryKeys } from '../../shared';
import { ISaveIncomeSettings } from '../models';

const client = incomeSettingsClient;
const itemKey = QueryKeys.IncomeSettings;

export function useIncomeSettings() {
    return useQuery({ queryKey: [itemKey], queryFn: () => client.getSettings() });
}

export function useUpdateIncomeSettings() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (settings: ISaveIncomeSettings) => client.updateSettings(settings),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [itemKey] }),
    });
}
