import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, QueryKeys, useQueryParamsContext } from '../../../shared';
import {
    AllocationTracksFilter,
    AllocationTracksWithProperties,
    AllocationTracksWithPropertyTypes,
    CreateAllocationTrackModal,
} from '../../components';
import { useIsFetching } from '@tanstack/react-query';

export const AllocationTracksPage: FC = () => {
    const { t } = useTranslation();
    const { theme } = window.carity.environment;
    const [showCreateAllocationTrack, setShowAllocationTrack] = useState(false);
    const [allocationTracksWithProperties, setAllocationTracksWithProperties] = useState(0);
    const [allocationTracksWithPropertyTypes, setAllocationTracksWithPropertyTypes] = useState(0);

    useEffect(() => {
        document.title = t('pages.allocation', { context: theme.name });
    }, [t, theme]);

    const {
        queryParams: { filter },
        setFilter,
    } = useQueryParamsContext('allocation-tracks', {});

    const noData = useMemo(
        () => allocationTracksWithProperties + allocationTracksWithPropertyTypes <= 0,
        [allocationTracksWithProperties, allocationTracksWithPropertyTypes],
    );

    const isFetching = useIsFetching({ queryKey: [QueryKeys.AllocationTracks] });

    return (
        <>
            <Page
                title={t('allocationTracks')}
                actions={[
                    <AllocationTracksFilter
                        filter={filter}
                        onChange={(filter) => setFilter({ status: filter.status })}
                    />,
                    <Button variant="contained" onClick={() => setShowAllocationTrack(true)}>
                        {t('newAllocationTrack')}
                    </Button>,
                ]}
            >
                {noData && !isFetching && (
                    <Typography variant="body2" textAlign="center" py={2}>
                        {t('noAllocationsFound')}
                    </Typography>
                )}

                <Stack spacing={2}>
                    <AllocationTracksWithProperties setCount={setAllocationTracksWithProperties} filter={filter} />
                    <AllocationTracksWithPropertyTypes
                        setCount={setAllocationTracksWithPropertyTypes}
                        filter={filter}
                    />
                </Stack>
            </Page>

            <CreateAllocationTrackModal
                show={showCreateAllocationTrack}
                handleClose={() => setShowAllocationTrack(false)}
            />
        </>
    );
};
