import {
    allocationTracksClient,
    IAllocationManager,
    IAllocationTrack,
    IAllocationTrackQuery,
    IPossibleCandidatesQuery,
    IProperty,
    IRentalRequestList,
    ListModel,
    ListQueryParam,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../index';
import { QueryKeys } from '../enums';
import { AxiosError } from 'axios';
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';

const client = allocationTracksClient;
const listKey = QueryKeys.AllocationTracks;
const itemKey = QueryKeys.AllocationTrack;
const possibleCandidateListKey = QueryKeys.AllocationTrackPossibleCandidates;
const possiblePropertyListKey = QueryKeys.AllocationTrackPossibleProperties;

export function useAllocationTrackList(...args: UseListParams<IAllocationTrack, IAllocationTrackQuery>) {
    return useList(client, listKey, ...args);
}

export function useAllocationTrack(...args: UseItemParams<IAllocationTrack>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveAllocationTrack() {
    return useSave(client, listKey, itemKey);
}

export function useGetPossibleCandidatesForAllocationTrack(
    id: string,
    query: IPossibleCandidatesQuery,
): UseQueryResult<ListModel<IRentalRequestList>, AxiosError> {
    return useQuery<ListModel<IRentalRequestList>, AxiosError>({
        queryKey: [possibleCandidateListKey, id, query],
        queryFn: () => client.getPossibleCandidates(id, query),
    });
}

export function useAddCandidatesToAllocationTrack(): UseMutationResult<
    IAllocationTrack,
    AxiosError,
    { id: string; candidateIds: string[] }
> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, candidateIds }) => await allocationTracksClient.addCandidates(id, candidateIds),
        onSuccess: async (r, { id }) => {
            await queryClient.setQueryData([itemKey, id], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [possibleCandidateListKey] });
        },
    });
}

export function useGetUnallocatedPropertiesForAllocationTrack(
    id: string,
    query: ListQueryParam,
    options: Omit<UseQueryOptions<ListModel<IProperty>, AxiosError>, 'queryKey'> = {},
): UseQueryResult<ListModel<IProperty>, AxiosError> {
    return useQuery<ListModel<IProperty>, AxiosError>({
        queryKey: [possiblePropertyListKey, id, query],
        queryFn: () => allocationTracksClient.getUnallocatedPropertiesForAllocationTrack(id, query),
        ...options,
    });
}

export function useAddPropertiesToAllocationTrack(): UseMutationResult<
    IAllocationTrack,
    AxiosError,
    { id: string; propertyIds: string[] }
> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, propertyIds }) => await allocationTracksClient.addProperties(id, propertyIds),
        onSuccess: async (r, { id }) => {
            await queryClient.setQueryData([itemKey, id], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [possiblePropertyListKey] });
        },
    });
}

export function useRemovePropertyFromAllocationTrack(): UseMutationResult<
    IAllocationTrack,
    AxiosError,
    { id: string; propertyId: string }
> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, propertyId }) => await allocationTracksClient.removeProperty(id, propertyId),
        onSuccess: async (r, { id }) => {
            await queryClient.setQueryData([itemKey, id], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [possiblePropertyListKey] });
        },
    });
}

export function useUpdateAllocationManager(): UseMutationResult<
    IAllocationTrack,
    AxiosError,
    { allocationTrackId: string; allocationManager: IAllocationManager }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ allocationTrackId, allocationManager }) =>
            client.updateAllocationManager(allocationTrackId, allocationManager),

        onSuccess: async (r, { allocationTrackId }) => {
            await queryClient.setQueryData([itemKey, allocationTrackId], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
        },
    });
}

export function useCloseAllocationTrack(): UseMutationResult<IAllocationTrack, AxiosError, { id: string }> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id }) => await allocationTracksClient.closeAllocationTrack(id),
        onSuccess: async (r, { id }) => {
            await queryClient.setQueryData([itemKey, id], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
        },
    });
}

export function useDeleteAllocation(): UseMutationResult<
    IAllocationTrack,
    AxiosError,
    { id: string; allocationId: string; rentalRequestId: string }
> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, allocationId }) => await allocationTracksClient.removeAllocation(id, allocationId),

        onSuccess: async (r, { id, rentalRequestId }) => {
            await queryClient.setQueryData([itemKey, id], r);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [possibleCandidateListKey] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.RentalRequest, rentalRequestId] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Refusals, rentalRequestId] });
        },
    });
}
