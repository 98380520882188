import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Link,
    MenuItem,
    Radio,
} from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ContactType,
    ControlledRadioGroup,
    ControlledSelect,
    DialogTitleWithClose,
    FormGrid,
    IAllocation,
    IInviteForm,
    IProperty,
    LabelValue,
    PropertyStatus,
} from '../../../shared';
import { useCreateInvite } from '../../hooks';
import { DownloadPdfInvite } from '../download-pdf-invite/download-pdf-invite.component';
import { PropertyInvitationStatus } from '../../../properties/components';
import { useInviteSchema } from '../../validators';

interface Props {
    show: boolean;
    handleClose: () => void;
    properties: IProperty[];
    allocation?: IAllocation;
    allocations?: IAllocation[];
}

export const SentInviteModal: FC<Props> = ({ show, handleClose, properties, allocation, allocations }) => {
    const { t } = useTranslation();

    const { mutateAsync: createInvite, isPending: isCreatingInvite } = useCreateInvite();

    const form = useForm<IInviteForm>({
        resolver: yupResolver(useInviteSchema()),
        mode: 'all',
    });

    const watchProperty = form.watch('propertyId');
    const watchContactType = form.watch('contactType');

    const filteredProperties = useMemo(
        () => (allocation?.property ? properties : properties.filter((p) => p.status !== PropertyStatus.ALLOCATED)),
        [allocation, properties],
    );

    useEffect(() => {
        if (allocation?.propertyId) {
            form.reset({ propertyId: allocation.propertyId, contactType: allocation.invite?.contactType });
        } else {
            form.reset({
                contactType: allocation?.rentalRequest?.contactType,
            });
        }
    }, [form, allocation]);

    const onSubmit = async (item: IInviteForm) => {
        if (allocation) {
            await createInvite({
                item: {
                    ...item,
                    allocationId: allocation.id,
                    propertyId: item.propertyId || '',
                },
            });

            handleClose();
        }
    };

    if (!allocation) {
        return <></>;
    }
    return (
        <Dialog open={show} onClose={handleClose}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    <DialogTitleWithClose onClose={handleClose}>
                        {!allocation.invite ? t('sentInvite') : t('invite')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Box sx={{ minWidth: 500 }}>
                            <FormGrid containerProps={{ direction: 'column' }}>
                                <LabelValue
                                    label={t('candidate')}
                                    value={`${allocation?.rentalRequest?.primaryResident?.firstName} ${allocation?.rentalRequest?.primaryResident?.lastName} - ${allocation?.rentalRequest?.email}`}
                                />
                                <div>
                                    <ControlledSelect
                                        name="propertyId"
                                        label={t('property')}
                                        required
                                        disabled={!!allocation?.invite}
                                    >
                                        {filteredProperties?.map((property) => (
                                            <MenuItem value={property.id} key={property.id}>
                                                {property.name}
                                                {property?.area && ` (${property.area} m²)`}
                                                <PropertyInvitationStatus
                                                    allocations={allocations}
                                                    property={property}
                                                />
                                            </MenuItem>
                                        ))}
                                    </ControlledSelect>
                                    {watchProperty && (
                                        <Link
                                            href={`/properties/${watchProperty}`}
                                            target={'_blank'}
                                            variant="caption"
                                            fontWeight="bold"
                                        >
                                            {t('showPropertyDetails')}
                                        </Link>
                                    )}
                                </div>

                                <ControlledRadioGroup
                                    name={'contactType'}
                                    label={t('communicationMethod')}
                                    required
                                    disabled={!!allocation?.invite}
                                >
                                    {Object.values(ContactType).map((contactType) => (
                                        <FormControlLabel
                                            control={<Radio />}
                                            label={t(contactType)}
                                            value={contactType}
                                            key={contactType}
                                        />
                                    ))}
                                </ControlledRadioGroup>
                                {!!allocation?.propertyId && (
                                    <>
                                        <Box sx={{ mb: 1 }}>
                                            <DownloadPdfInvite
                                                contactType={ContactType.LETTER}
                                                propertyId={allocation.propertyId}
                                                rentalRequestId={allocation.rentalRequestId}
                                                properties={properties}
                                            />
                                        </Box>
                                        <DownloadPdfInvite
                                            contactType={ContactType.EMAIL}
                                            propertyId={allocation.propertyId}
                                            rentalRequestId={allocation.rentalRequestId}
                                            properties={properties}
                                        />
                                    </>
                                )}
                            </FormGrid>
                        </Box>
                    </DialogContent>
                    {!allocation?.invite && (
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isCreatingInvite}
                                startIcon={isCreatingInvite && <CircularProgress color="inherit" size={25} />}
                            >
                                {watchContactType === ContactType?.LETTER ? t('downloadLetter') : t('sentEmail')}
                            </Button>
                            <Button onClick={handleClose} disabled={isCreatingInvite}>
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    )}
                </form>
            </FormProvider>
        </Dialog>
    );
};
