import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ICreateAdditionalQuestion, QueryKeys } from '../../shared';
import { additionalQuestionsClient } from '../clients';

const client = additionalQuestionsClient;

export function useCreateAdditionalQuestion(): UseMutationResult<void, AxiosError, ICreateAdditionalQuestion> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: client.create,
        onSuccess: async (r, { rentalRequestId }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.RentalRequest, rentalRequestId] });
        },
    });
}

export function useDeleteAdditionalQuestion(): UseMutationResult<
    void,
    AxiosError,
    { rentalRequestId: string; questionId: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ questionId }) => client.deleteOne(questionId),
        onSuccess: async (r, { rentalRequestId }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.RentalRequest, rentalRequestId] });
        },
    });
}
