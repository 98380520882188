import { Button, Paper, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Forbidden: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Paper
            sx={{ maxWidth: 420, width: '100%', mt: 8, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
        >
            <Stack spacing={2} p={4} alignItems="center">
                <img src={window.carity.environment.theme.logo} alt="Carity" width="80px" />
                <Typography variant="h2">{t('forbiddenTitle')}</Typography>
                <Typography variant="subtitle1">{t('forbiddenText')}</Typography>
                <Button onClick={() => navigate(-1)} variant="contained">
                    {t('backButton')}
                </Button>
            </Stack>
        </Paper>
    );
};
