import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const PropertyTypeInUse: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            <DialogTitle>
                <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                <span>{t('propertyTypeInUseTitle')}</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t('propertyTypeInUseDescription')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus variant="contained" aria-label="confirm-delete">
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
