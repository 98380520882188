import React, { FC } from 'react';
import { formatDateTimeString, IParams, Page, SortOrder, Table, useQueryParams, Widget } from '../../../shared';
import { RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { History } from '@mui/icons-material';
import { useRentalRequest, useRentalRequestLogList } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';

export const RentalRequestLogsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { data: logs, isLoading: logsIsLoading } = useRentalRequestLogList(id, {
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('logTime'),
            valueFormatter: (value: string) => formatDateTimeString(value),
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'text',
            headerName: t('logInfo'),
            valueFormatter: (value: string) => t(value),
            flex: 1,
            sortable: false,
        },
        {
            field: 'createdBy',
            headerName: t('logCreatedBy'),
            flex: 1,
            sortable: false,
        },
    ];

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Widget icon={<History color="primary" />} hasTable title={t('logs')}>
                <Table
                    rows={logs?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={logs?.pagination.size || 0}
                    loading={logsIsLoading}
                    sortModel={sort}
                    onSortModelChange={setSort}
                />
            </Widget>
        </Page>
    );
};
