import { ICreateAdditionalQuestion, rentingApi } from '../../shared';

class AdditionalQuestionsClient {
    public async create(question: ICreateAdditionalQuestion): Promise<void> {
        await rentingApi.post<void>('/rental-request-additional-questions', question);
    }

    public async deleteOne(id: string): Promise<void> {
        await rentingApi.delete<void>(`/rental-request-additional-questions/${id}`);
    }
}

export const additionalQuestionsClient = new AdditionalQuestionsClient();
