import { Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAllocation, IProperty, PropertyStatus } from '../../../shared';

interface Props {
    allocations: IAllocation[] | undefined;
    property: IProperty;
}

export const PropertyInvitationStatus: FC<Props> = ({ allocations, property }) => {
    const { t } = useTranslation();

    const deniedAllocations = useMemo(
        () => allocations?.filter((allocation) => allocation.refusal)?.map((allocation) => allocation.propertyId),
        [allocations],
    );

    if (deniedAllocations?.includes(property?.id)) {
        return (
            <Typography>
                <Typography variant="caption" color="error" pl={2}>
                    {t('propertyAlreadyRefused')}
                </Typography>
            </Typography>
        );
    }

    if (property?.status === PropertyStatus.INVITED) {
        return (
            <Typography>
                <Typography variant="caption" color="error" pl={2}>
                    {t('propertyHasInviteWarning')}
                </Typography>
            </Typography>
        );
    }

    return false;
};
