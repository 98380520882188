import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, ICommunicationSettings, useTemplates } from '../../../shared';
import { Link, MenuItem, Stack } from '@mui/material';

interface Props {
    setPreview: (item: { templateId: string | undefined; title: string }) => void;
    name: keyof ICommunicationSettings;
    watchTemplates: ICommunicationSettings;
}

export const SelectTemplate: FC<Props> = ({ setPreview, name, watchTemplates }) => {
    const { t } = useTranslation();
    const { data: templates } = useTemplates({ pageSize: 100 });

    return (
        <Stack>
            <ControlledSelect name={name} label={t(name)}>
                {(templates || []).map((template) => (
                    <MenuItem value={template.id} key={template.id}>
                        {template.name}
                    </MenuItem>
                ))}
            </ControlledSelect>
            <Link
                onClick={() =>
                    setPreview({
                        templateId: watchTemplates[name],
                        title: t(name),
                    })
                }
            >
                {t('showTemplatePreview')}
            </Link>
        </Stack>
    );
};
