import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ComponentProps, FC } from 'react';
import { Controller, FieldError } from 'react-hook-form';

interface Props extends Omit<ComponentProps<typeof DatePicker>, 'renderInput' | 'onChange' | 'value'> {
    name: string;
    label: string;
    required?: boolean;
    size?: ComponentProps<typeof TextField>['size'];
}

export const ControlledDatePicker: FC<Props> = ({ name, label, required, size, ...datePickerProps }) => {
    return (
        <Controller
            {...datePickerProps}
            name={name}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                    slotProps={{
                        textField: {
                            id: `${name}-field`,
                            size: size,
                            error: !!error,
                            helperText: (error as FieldError | undefined)?.message,
                            required: required,
                        },
                        inputAdornment: { position: 'end', sx: { marginLeft: '-40px' } },
                    }}
                    label={label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format="dd/MM/yyyy"
                />
            )}
        />
    );
};
