import { IMailMessage, ISaveMailMessage } from '../models';
import { BaseClient } from './base.client';
import { rentingApi } from './renting-api.client';

class MailMessagesClient extends BaseClient<IMailMessage, ISaveMailMessage> {
    constructor() {
        super(rentingApi, '/mail-messages');
    }
}

export const mailMessagesClient = new MailMessagesClient();
