import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback } from '../../../main';
import { SideBar, SideNav } from '../../../shared';
import { allocationTrackSubRoutes } from '../../allocation.routes';

export const AllocationTrackPage: FC = () => {
    const { t } = useTranslation();
    const { theme } = window.carity.environment;

    useEffect(() => {
        document.title = t('pages.allocation', { context: theme.name });
    }, [t, theme]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: 'candidates', text: t('candidates') },
                        { path: 'properties', text: t('properties') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {allocationTrackSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    {<subRoute.component />}
                                </ErrorBoundary>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
