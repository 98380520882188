import { Button, Paper, Stack, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const Unauthenticated: FC = () => {
    const { t } = useTranslation();

    const onLoginAgain = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: 8,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
            }}
        >
            <Stack spacing={2} p={4} alignItems="center">
                <img src={window.carity.environment.theme.logo} alt="Carity" width="80px" />
                <Typography variant="h2">{t('unauthenticatedTitle')}</Typography>
                <Typography variant="subtitle1">{t('unauthenticatedText')}</Typography>
                <Button variant="contained" fullWidth onClick={onLoginAgain}>
                    {t('loginAgain')}
                </Button>
            </Stack>
        </Paper>
    );
};
