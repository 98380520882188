export default {
    shared: {
        somethingWentWrong: 'Er liep iets mis:',
        back: 'Terug',
        search: 'Zoek',
    },
    pages: {
        files: 'Dossiers | Betaalbaar huren',
        file: 'Dossier | Betaalbaar huren',
        admin: 'Beheer | Betaalbaar huren',
        login: 'Aanmelden | Betaalbaar huren',
        allocation: 'Toewijzing | Betaalbaar huren',

        files_antwerp: 'Dossiers | Budgethuren',
        file_antwerp: 'Dossier | Budgethuren',
        admin_antwerp: 'Beheer | Budgethuren',
        login_antwerp: 'Aanmelden | Budgethuren',
        allocation_antwerp: 'Toewijzing | Budgethuren',
    },
    all: 'Alle',
    save: 'Bewaar',
    cancel: 'Annuleer',
    delete: 'Verwijder',
    filter: 'Filter',
    confirm: 'Bevestig',
    yes: 'Ja',
    no: 'Nee',
    ok: 'Ok',
    forbiddenTitle: 'Verboden toegang',
    forbiddenText: 'Je hebt onvoldoende rechten voor het uitvoeren van de laatste actie.',
    notFoundTitle: 'Pagina niet gevonden',
    notFoundText: 'Oeps... Deze pagina bestaat niet.',
    backButton: 'Ga terug naar vorige pagina',
    getUsersError: 'Er is een probleem opgetreden bij het ophalen van de gebruikers.',
    getManagersError: 'Er is een probleem opgetreden bij het ophalen van de beheerders.',
    getUserRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen van de gebruiker.',
    somethingWentWrong: 'Er ging iets mis',
    createdBy: 'Aangemaakt door',
    edit: 'Wijzigen',
    requiredField: 'Dit veld is verplicht',

    address: 'Adres',
    street: 'Straat',
    number: 'Nummer',
    box: 'Bus',
    zip: 'Postcode',
    region: 'Regio',
    city: 'Gemeente',
    country: 'Land',
    total: 'Totaal',
    noRows: 'Geen resultaten',
};
