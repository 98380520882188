import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogTitleWithClose, IAllocationTrack, IParams, useAddCandidatesToAllocationTrack } from '../../../shared';
import { AllocationTrackAddCandidatesData } from './allocation-track-add-candidates-data.component';
import { AllocationTrackAddCandidatesAlert } from './allocation-track-add-candidates-warning.component';

interface Props {
    open: boolean;
    handleClose: () => void;
    allocationTrack: IAllocationTrack;
}

export const AllocationTrackAddCandidatesModal: FC<Props> = ({ open, handleClose, allocationTrack }) => {
    const { t } = useTranslation();
    const { id } = (useParams<keyof IParams>() as IParams) || '';

    const [onlyVerifiedCandidates, setOnlyVerifiedCandidates] = useState(true);
    const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>();

    const { mutateAsync: addCandidates } = useAddCandidatesToAllocationTrack();

    const onSubmit = async () => {
        await addCandidates({ id, candidateIds: selectedCandidateIds || [] });
        handleClose();
    };

    return (
        <Dialog open={open} fullWidth maxWidth={false}>
            <DialogTitleWithClose onClose={handleClose}>{t('addCandidatesAllocationTrack')}</DialogTitleWithClose>
            <DialogContent>
                <Box sx={{ width: '100%' }}>
                    <AllocationTrackAddCandidatesAlert selected={selectedCandidateIds?.length || 0} />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={onlyVerifiedCandidates}
                                onChange={() => setOnlyVerifiedCandidates(!onlyVerifiedCandidates)}
                            />
                        }
                        label={t('verifiedCandidates')}
                    />

                    <AllocationTrackAddCandidatesData
                        onlyVerifiedCandidates={onlyVerifiedCandidates}
                        setSelected={setSelectedCandidateIds}
                        allocationTrack={allocationTrack}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button type="submit" onClick={() => onSubmit()} variant="contained">
                    {t('save')}
                </Button>
                <Button onClick={handleClose}>{t('cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};
