import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { adminSubRoutes } from '../../admin.routes';

export const AdminPage: FC = () => {
    const { t } = useTranslation();
    const { theme } = window.carity.environment;

    useEffect(() => {
        document.title = t('pages.admin', { context: theme.name });
    }, [t, theme]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: 'indexations', text: t('indexations') },
                        { path: 'mail-messages', text: t('mailMessages') },
                        { path: 'communication-settings', text: t('communicationSettings') },
                        { path: 'income-settings', text: t('incomeSettings') },
                        { path: 'property-types', text: t('propertyTypes') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {adminSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    {<subRoute.component />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
