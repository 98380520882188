import { MenuItem } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AllocationTrackStatus, ControlledSelect, IAllocationTrackQuery } from '../../../shared';

interface Props {
    filter?: IAllocationTrackQuery;
    onChange: (filter: IAllocationTrackQuery) => void;
}
export const AllocationTracksFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IAllocationTrackQuery>({ mode: 'onChange' });
    const formValues = form.watch();

    const hasFilterFormChanged = useMemo(() => {
        return JSON.stringify(formValues) !== JSON.stringify(filter);
    }, [filter, formValues]);

    useEffect(() => {
        hasFilterFormChanged && onChange(formValues);
    }, [formValues, hasFilterFormChanged, onChange]);

    return (
        <FormProvider {...form}>
            <ControlledSelect name="status" label={t('status')} inline>
                <MenuItem value="">{t('all')}</MenuItem>
                {Object.values(AllocationTrackStatus).map((status) => (
                    <MenuItem value={status} key={status}>
                        {t(status)}
                    </MenuItem>
                ))}
            </ControlledSelect>
        </FormProvider>
    );
};
