import { AccountCircle, Login, Logout, Person } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUser } from '../../../shared';

export const Account: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data } = useGetCurrentUser();
    const user = data?.data;

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const onLogOut = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    const onLogIn = useCallback(() => {
        navigate('?login=true');
    }, [navigate]);

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ borderRadius: 0 }}
            >
                <AccountCircle />
                <Typography sx={{ ml: 1.5 }}>
                    {user?.firstName} {user?.lastName}
                </Typography>
            </IconButton>
            <Menu
                sx={{ mt: 5 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
            >
                {user ? (
                    [
                        <MenuItem key="person">
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            {user?.firstName} {user?.lastName}
                        </MenuItem>,
                        <MenuItem onClick={onLogOut} key="logout">
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            {t('logOut')}
                        </MenuItem>,
                    ]
                ) : (
                    <MenuItem onClick={onLogIn}>
                        <ListItemIcon>
                            <Login />
                        </ListItemIcon>
                        {t('logIn')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
