import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    formatPropertyAddress,
    IParams,
    Page,
    RemoveModal,
    Table,
    useAllocationTrack,
    useQueryParams,
    useRemovePropertyFromAllocationTrack,
} from '../../../shared';
import {
    AllocationTrackAddPropertiesModal,
    AllocationTrackPropertyPrice,
    AllocationTrackTitle,
} from '../../components';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Button, IconButton, Link, Stack } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

export const AllocationTrackPropertiesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: allocationTrack } = useAllocationTrack(id);
    const { mutateAsync: removeProperty } = useRemovePropertyFromAllocationTrack();
    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const [showAddProperties, setShowAddProperties] = useState(false);

    const onDelete = async (propertyId: string) => {
        await removeProperty({ id, propertyId: propertyId });
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('property'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => <>{formatPropertyAddress(params?.row?.address)}</>,
        },
        {
            field: ' ',
            headerName: '',
            width: 200,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <Link
                        component="button"
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/properties/${params?.row?.id}`);
                        }}
                    >
                        {t('viewPropertyDetails')}
                    </Link>
                    <RemoveModal
                        handleDelete={() => onDelete(params?.row?.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('deletePropertyFromAllocationTrackWarningTitle')}
                        text={t('deletePropertyFromAllocationTrackWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={
                allocationTrack && <AllocationTrackTitle subtitle={t('properties')} allocationTrack={allocationTrack} />
            }
            onBack={() => navigate('/allocation-tracks')}
            actions={[
                <Button onClick={() => setShowAddProperties(true)} color="primary" variant="contained">
                    {t('addPropertyToAllocationTrack')}
                </Button>,
            ]}
        >
            <AllocationTrackAddPropertiesModal
                open={showAddProperties}
                handleClose={() => setShowAddProperties(false)}
            />
            <Stack spacing={3}>
                {allocationTrack && <AllocationTrackPropertyPrice allocationTrack={allocationTrack} />}

                <Table
                    rows={allocationTrack?.properties || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    mode="client"
                />
            </Stack>
        </Page>
    );
};
