import { ChevronRight } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../shared';
import { useGetProviders } from '../../hooks';
import { IProvider } from '../../models';

export const Login: React.FC = () => {
    const { data: providers } = useGetProviders();
    const { theme } = window.carity.environment;
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.login', { context: theme.name });
    }, [t, theme]);

    const onClickProvider = useCallback((provider: IProvider) => {
        window.location.href = provider.url;
    }, []);

    useEffect(() => {
        if (providers?.length === 1) {
            onClickProvider(providers[0]);
        }
    }, [providers, onClickProvider]);

    const isLoading = (providers?.length || 0) <= 1;

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: 8,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                pb: isLoading ? 4 : 0,
            }}
        >
            <Stack spacing={2} p={4} alignItems="center">
                <img src={theme.logo} alt="Carity" width="80px" />
                <Typography variant="h2">{t('loginTitle')}</Typography>
                <Typography>{t('selectLoginMethod', { context: theme.name })}</Typography>
            </Stack>
            {isLoading ? (
                <Loading />
            ) : (
                <List>
                    {providers?.map((provider) => (
                        <Fragment key={provider.name}>
                            <Divider component="li" />
                            <ListItem>
                                <ListItemButton onClick={() => onClickProvider(provider)}>
                                    <ListItemText primary={provider.name} />
                                    <ChevronRight />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            )}
        </Paper>
    );
};
