export default {
    nav: {
        title: 'Betaalbaar huren',
        title_antwerp: 'Budgethuren',
        properties: 'Panden',
        rentalRequests: 'Dossiers',
        admin: 'Admin',
        allocationTracks: 'Toewijzingstrajecten',
        reporting: 'Rapportage',
    },
};
