import { Box, Typography } from '@mui/material';
import { differenceInBusinessDays, differenceInCalendarDays, isFuture } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AllocationStatus, formatDateTimeString, IAllocation } from '../../../shared';

interface Props {
    allocation: IAllocation;
}

export const AllocationTrackDeadlines: FC<Props> = ({ allocation }) => {
    const { t } = useTranslation();
    const { deadlineResponseInvite, deadlineResponseVisit } = window.carity.environment;

    const invited = allocation.status === AllocationStatus.INVITED;
    const planned =
        allocation.status === AllocationStatus.APPOINTMENT_PLANNED &&
        allocation.appointment?.date &&
        isFuture(new Date(allocation.appointment.date));
    const visited = allocation.status === AllocationStatus.APPOINTMENT_PLANNED && !planned;

    if (invited || visited) {
        const difference = invited
            ? differenceInDays(allocation.invite?.createdDate)
            : differenceInDays(allocation.appointment?.date);

        const days = difference && (invited ? deadlineResponseInvite - difference : deadlineResponseVisit - difference);

        return (
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
                <Typography variant="body2">
                    {invited ? t('actionMakeAppointment') : t('actionGiveFeedback')}
                </Typography>

                {days !== undefined && (
                    <Typography variant="caption" sx={{ color: days + 1 > 0 ? 'green' : 'orange', mb: 0 }}>
                        {days + 1 > 0
                            ? t('actionDaysRemaining', {
                                  count:
                                      difference == 0
                                          ? invited
                                              ? deadlineResponseInvite
                                              : deadlineResponseVisit
                                          : days,
                              })
                            : t('actionDaysLate', { count: parseInt(days.toString().replace('-', '')) })}
                    </Typography>
                )}
            </Box>
        );
    } else if (planned) {
        return (
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
                <Typography variant="body2">{t('actionAppointmentPlanned')}</Typography>
                <Typography variant="caption">
                    {formatDateTimeString(allocation.appointment?.date.toString())}
                </Typography>
            </Box>
        );
    }
};

function differenceInDays(date: string | undefined) {
    const { differenceInBusinessDays: businessDays } = window.carity.environment;
    return !!date
        ? businessDays
            ? differenceInBusinessDays(new Date(), new Date(date))
            : differenceInCalendarDays(new Date(), new Date(date))
        : undefined;
}
