import { FilterList } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton, Popover, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useState } from 'react';

export interface Option {
    label: string;
    active?: boolean;
    type?: 'option' | 'title';
}

export type Options = Record<string, Option>;

interface Props {
    icon?: ReactNode;
    options: Options;
    onChange: (options: Options) => void;
}

export const OptionsToggle: FC<Props> = ({ icon = <FilterList />, options, onChange }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>();

    return (
        <>
            <IconButton aria-haspopup="true" onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                {icon}
            </IconButton>
            <Popover open={Boolean(anchor)} anchorEl={anchor} onClose={() => setAnchor(null)}>
                <Typography sx={{ p: 2 }} component="div">
                    <Stack>
                        {Object.keys(options).map((key) => {
                            const { type, active, label } = options[key];

                            return type === 'title' ? (
                                <Typography variant="subtitle2" sx={{ fontVariant: 'all-small-caps' }} key={key}>
                                    {label}
                                </Typography>
                            ) : (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            checked={active}
                                            onChange={() =>
                                                onChange({
                                                    ...options,
                                                    [key]: { type, label, active: !active },
                                                })
                                            }
                                        />
                                    }
                                    label={label}
                                />
                            );
                        })}
                    </Stack>
                </Typography>
            </Popover>
        </>
    );
};
