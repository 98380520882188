import { useMemo } from 'react';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { AllocationTrackType } from '../../shared';

export function useAllocationTrackSchema(selectedTab: string) {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                userId: string().required(),
                propertyTypeId: string()
                    .optional()
                    .test('required', t('required'), (value) =>
                        selectedTab === AllocationTrackType.PROPERTY_TYPE ? !!value : true,
                    ),
                propertyId: string()
                    .optional()
                    .test('required', t('required'), (value) =>
                        selectedTab === AllocationTrackType.PROPERTY ? !!value : true,
                    ),
            }),
        [t, selectedTab],
    );
}
