import { Navigate, Route, Routes as RRRoutes } from 'react-router-dom';
import { adminRoutes } from '../../../admin';
import { allocationRoutes } from '../../../allocation';
import { rentalRequestsRoutes } from '../../../rental-requests';
import { propertiesRoutes } from '../../../properties';
import { reportingRoutes } from '../../../reporting';

import { IRouteConfig, IRouteConfigWithRedirect, ProtectedRoute } from '../../../shared';
import { PageNotFound } from '../../pages';

export const ROUTES: IRouteConfig[] = [
    { path: '/', redirect: 'allocation-tracks' },
    ...rentalRequestsRoutes,
    ...adminRoutes,
    ...propertiesRoutes,
    ...allocationRoutes,
    ...reportingRoutes,
];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export function Routes() {
    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ProtectedRoute requiredPermission={config.requiredPermissions}>
                                <config.component />
                            </ProtectedRoute>
                        }
                    />
                ),
            )}
            <Route path="*" element={<PageNotFound />} />
        </RRRoutes>
    );
}
