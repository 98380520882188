import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useRequestChangesRentalRequest } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { ControlledInput, IStatusChange } from '../../../shared';

interface Props {
    id: string;
}

export const RequestChangesModal: FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const { mutateAsync: requestChangesRentalRequest, isPending } = useRequestChangesRentalRequest();

    const schema = object().shape({ message: string().required() });
    const form = useForm<IStatusChange>({ resolver: yupResolver(schema), mode: 'all' });

    const onSubmit = async (statusChange: IStatusChange) => {
        await requestChangesRentalRequest({ id, statusChange });
        onClose();
    };

    const onClose = () => {
        setShowModal(false);
        form.reset({ message: '' });
    };

    return (
        <>
            <Button onClick={() => setShowModal(true)} variant="outlined">
                {t('requestChangesRentalRequest')}
            </Button>
            <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <DialogTitle>{t('requestChangesRentalRequest')}</DialogTitle>
                        <DialogContent>
                            <ControlledInput name="message" label={t('reason')} multiline />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" disabled={isPending}>
                                {t('sendRequestChanges')}
                            </Button>
                            <Button onClick={onClose} color="secondary" disabled={isPending}>
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};
