import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AllocationStatus,
    EligibleStatus,
    formatDateTimeString,
    formatSocialSecurityNumber,
    IAllocation,
    Page,
    RentalRequestStatus,
    Search,
    SortOrder,
    Table,
    useQueryParamsContext,
} from '../../../shared';
import { RentalRequestsFilter } from '../../components';
import { useRentalRequestList } from '../../hooks';

export const RentalRequestsPage: FC = () => {
    const { t } = useTranslation();
    const { theme } = window.carity.environment;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = t('pages.files', { context: theme.name });
    }, [t, theme]);

    const {
        queryParams: { page, pageSize, filter, search, sort },
        debouncedQueryParams: { search: debouncedSearch },
        setFilter,
        setPage,
        setPageSize,
        setSearch,
        setSort,
    } = useQueryParamsContext('rental-requests', {
        page: 1,
        pageSize: 10,
        search: '',
        sort: [{ field: 'lastName,firstName', sort: 'asc' as GridSortDirection }],
        filter: {},
    });

    const { data: rentalRequests, isLoading: rentalRequestsIsLoading } = useRentalRequestList({
        page,
        pageSize,
        status: filter?.['status'] && filter.status !== 'all' ? (filter['status'] as RentalRequestStatus) : undefined,
        eligibleStatus:
            filter.eligibleStatus && filter.eligibleStatus === 'all'
                ? undefined
                : [filter?.['eligibleStatus'] as EligibleStatus],
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        {
            field: 'socialSecurityNumber',
            headerName: t('socialSecurityNumber'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                formatSocialSecurityNumber(params.row.primaryResident?.socialSecurityNumber),
        },

        {
            field: 'lastName,firstName',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <>
                    {params.row.primaryResident?.lastName} {params.row.primaryResident?.firstName}
                </>
            ),
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 100,
            flex: 0.5,
            sortable: false,
            valueFormatter: (value: string) => t(value),
        },
        {
            field: 'score',
            headerName: t('score'),
            minWidth: 50,
            flex: 0.5,
        },
        {
            field: 'allocations',
            headerName: t('property'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: IAllocation[]) =>
                value.filter(
                    (allocation: IAllocation) =>
                        allocation.status === AllocationStatus.INVITED ||
                        allocation.status === AllocationStatus.APPOINTMENT_PLANNED ||
                        allocation.status === AllocationStatus.ALLOCATED,
                )[0]?.property?.name || '',
        },
        {
            field: 'eligibleStatus',
            headerName: t('eligibleStatus'),
            minWidth: 150,
            sortable: false,
            flex: 1,
            valueFormatter: (value: string) => {
                switch (value) {
                    case EligibleStatus.ELIGIBLE:
                        return t('yes');
                    case EligibleStatus.NOT_ELIGIBLE:
                        return t('no');
                    default:
                        return t(EligibleStatus.UNABLE_TO_CALCULATE);
                }
            },
        },
        {
            field: 'submittedDate',
            headerName: t('submittedDate'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
    ];

    return (
        <Page
            title={t('rentalRequests')}
            actions={[
                <RentalRequestsFilter
                    filter={filter}
                    onChange={(filter) =>
                        setFilter({
                            status: filter.status,
                            eligibleStatus: filter.eligibleStatus,
                        })
                    }
                    defaultValues={{ status: RentalRequestStatus.SUBMITTED, eligibleStatus: 'all' }}
                />,
                <Search search={search} onSearch={setSearch} inputAdornmentPosition="end" />,
            ]}
            loading={rentalRequestsIsLoading}
        >
            <Table
                rows={rentalRequests?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={rentalRequests?.pagination.size || 0}
                loading={rentalRequestsIsLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/rental-requests/${row.id}/info`)}
            />
        </Page>
    );
};
