import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { ControlledInput, DialogTitleWithClose, IAdditionalQuestionForm } from '../../../shared';
import { useCreateAdditionalQuestion } from '../../hooks';

interface Props {
    id: string;
}
export const AddAdditionalQuestion: FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const { mutateAsync: createQuestion, isPending } = useCreateAdditionalQuestion();

    const form = useForm<IAdditionalQuestionForm>({
        resolver: yupResolver(object().shape({ question: string().required() })),
        mode: 'onSubmit',
    });

    const onSubmit = async (form: IAdditionalQuestionForm) => {
        await createQuestion({ ...form, rentalRequestId: id });
        onClose();
    };

    const onClose = () => {
        setShowModal(false);
        form.reset({ question: '' });
    };

    return (
        <>
            <Button variant="contained" onClick={() => setShowModal(true)}>
                {t('addAdditionalQuestion')}
            </Button>
            <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <DialogTitleWithClose onClose={() => setShowModal(false)}>
                            {t('addAdditionalQuestion')}
                        </DialogTitleWithClose>
                        <DialogContent>
                            <Stack spacing={2}>
                                <TextField label={'Type'} value={'Document'} disabled />
                                <ControlledInput name="question" label={t('question')} multiline rows={2} />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" disabled={isPending}>
                                {t('addQuestion')}
                            </Button>
                            <Button onClick={onClose} disabled={isPending}>
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};
