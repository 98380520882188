export default {
    indexations: 'Indexaties',
    'new-indexation': 'Nieuwe indexatie',
    'update-indexation': 'Update indexatie',
    year: 'Jaar',
    index: 'Index',

    settings: 'Instellingen',

    incomeSettings: 'Inkomensgrenzen',
    referenceIncomeLowerLimitCommon: 'Ondergens referentie inkomen - basis',
    referenceIncomeUpperLimitCommon: 'Bovengrens referentie inkomen - basis',
    referenceIncomeLowerLimitSingle: 'Ondergrens referentie inkomen - alleenstaande',
    referenceIncomeUpperLimitSingle: 'Bovengrens referentie inkomen - alleenstaande',
    referenceIncomeLowerLimitSingleAndHandicap: 'Ondergens referentie inkomen - alleenstaande met een handicap',
    referenceIncomeUpperLimitSingleAndHandicap: 'Bovengrens referentie inkomen - alleenstaande met een handicap',
    supplementChild: 'Toeslag kind',

    communicationSettings: 'Communicatie',
    submitRequestConfirmationTemplate: 'Mail - Bevestiging inschrijving',
    changesRequestedTemplate: 'Mail - Wijzigingen gevraagd',
    approveRequestTemplate: 'Mail - Goedkeuring dossier',
    disapproveRequestTemplate: 'Mail - Afwijzing',
    mailInviteTemplate: 'Mail - Uitnodiging',
    letterInviteTemplate: 'Brief - Uitnodiging',
    appointmentTemplate: 'Mail - Bevestiging afspraak',
    denyByRequesterTemplate: 'Mail - Weigering door aanvrager',
    denyByOrganisationTemplate: 'Mail - Weigering door organisatie',
    allocatedTemplate: 'Mail - Bevestig toewijzing',
    notAllocatedTemplate: 'Mail - Toekenning pand aan andere kandidaat',
    lastRefusalTemplate: 'Mail - Laatste weigering',
    additionalQuestionTemplate: 'Mail - Bijkomende vraag voor aanvrager',

    preview: 'Voorbeeld',
    subject: 'Onderwerp',
    content: 'Inhoud',
    updateCommunicationSettingsSuccess: 'Communicatie instellingen successvol aangepast.',
    updateCommunicationSettingsError:
        'Er is een probleem opgetreden bij het aanpassen van de communicatie instellingen.',
    showTemplatePreview: 'Toon voorbeeld',

    propertyTypes: 'Pand types',
    propertyType: 'Pand type',
    propertyTypeCode: 'Code',
    propertyTypeName: 'Naam',
    newPropertyType: 'Nieuw pand type',
    updatePropertyType: 'Wijzig pand type',
    propertyTypeDeleteWarningTitle: 'Verwijder pand type',
    propertyTypeDeleteWarningText: 'Bent u zeker dat u dit pand type wilt verwijderen?',
    propertyTypeCodeUniqueError: 'Code moet uniek zijn',
    propertyTypeInUseTitle: 'Pand type in gebruik.',
    propertyTypeInUseDescription:
        'Dit pand type wordt al gebruikt bij één of meerdere panden en kan niet verwijdert worden.',

    mailMessages: 'Mail antwoorden',
    mailMessage: 'Mail antwoord',
    newMailMessage: 'Nieuw mail antwoord',
    updateMailMessage: 'Wijzig mail antwoord',
    answer: 'Antwoord',
    mailMessageDeleteWarningTitle: 'Verwijder mail antwoord',
    mailMessageDeleteWarningText: 'Bent u zeker dat u deze mail antwoord wilt verwijderen?',
};
