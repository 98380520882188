import { ICommunicationSettings } from '../index';
import { rentingApi } from './renting-api.client';

class CommunicationSettingsClient {
    public async getCommunicationSettings(): Promise<ICommunicationSettings> {
        const { data } = await rentingApi.get('/communication-settings');
        return data;
    }

    public async updateCommunication(
        settings: ICommunicationSettings,
        successMessage?: string,
        errorMessage?: string,
    ): Promise<ICommunicationSettings> {
        const { data } = await rentingApi.post('/communication-settings', settings, {
            successMessage,
            errorMessage,
        });
        return data;
    }
}

export const communicationSettingsClient = new CommunicationSettingsClient();
