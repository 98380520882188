import { IRegion, ISaveRegion } from '../index';
import { BaseClient } from './base.client';
import { rentingApi } from './renting-api.client';

class RegionsClient extends BaseClient<IRegion, ISaveRegion> {
    constructor() {
        super(rentingApi, '/regions');
    }
}

export const regionsClient = new RegionsClient();
