import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAddress, FormGrid, IProperty, LabelValue, Widget } from '../../../shared';
import { Chip, Typography } from '@mui/material';
import { ListAltTwoTone } from '@mui/icons-material';

interface Props {
    property: IProperty;
}

export const PropertyDetailGeneral: FC<Props> = ({ property }) => {
    const { t } = useTranslation();

    return (
        <Widget
            title={
                <>
                    {t('property')}
                    <Chip sx={{ ml: 2 }} label={t(property.status || '')} />
                </>
            }
            icon={<ListAltTwoTone color="primary" />}
        >
            <Typography my={1} variant={'subtitle2'}>
                {t('property')}
            </Typography>
            <FormGrid xs={12} md={4} containerProps={{ mb: 4 }}>
                <LabelValue label={t('name')} value={property.name} />
                <LabelValue label={t('propertyType')} value={property.type.name} />
                <LabelValue label={t('address')} value={formatAddress(property.address)} />
            </FormGrid>

            <Typography my={1} variant={'subtitle2'}>
                {t('propertyOccupation')}
            </Typography>
            <FormGrid xs={12} md={4} containerProps={{ mb: 4 }}>
                <LabelValue label={t('propertyAmountOfBedrooms')} value={property.amountOfBedrooms.toString()} />
                <LabelValue label={t('propertyArea')} value={`${property.area.toString()}m²`} />
            </FormGrid>

            <Typography my={1} variant={'subtitle2'}>
                {t('propertyRent')}
            </Typography>
            <FormGrid xs={12} md={4} containerProps={{ mb: 4 }}>
                <LabelValue label={t('propertyMonthlyRent')} value={property.monthlyRent.toString()} />
                <LabelValue label={t('propertyMonthlyExtraCost')} value={property.monthlyExtraCost.toString()} />
                <LabelValue label={t('rentYear')} value={property.rentYear.toString()} />
            </FormGrid>

            <Typography my={1} variant={'subtitle2'}>
                {t('propertyExtras')}
            </Typography>
            <FormGrid xs={12} md={4} containerProps={{ mb: 4 }}>
                <LabelValue label={t('propertyHasElevator')} value={property.hasElevator ? t('yes') : t('no')} />
                <LabelValue label={t('comment')} value={property.comment} sx={{ whiteSpace: 'pre-line' }} />
            </FormGrid>
        </Widget>
    );
};
