import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { PanelContent } from '../pannel-content/pannel-content.component';
import { PanelFooter } from '../pannel-footer/pannel-footer.component';
import { Panel } from '../pannel/pannel.component';

export function FormCard(props: { children: ReactNode; actions: ReactNode; handleSubmit: SubmitHandler<any> }) {
    const actions = Array.isArray(props.actions) ? props.actions : [props.actions];

    return (
        <form onSubmit={props.handleSubmit} noValidate={true}>
            <Panel>
                <PanelContent>{props.children}</PanelContent>

                {props.actions && (
                    <PanelFooter>
                        <Grid container alignItems="center" spacing={1}>
                            {actions.map((action, index) => (
                                <Grid item key={index}>
                                    {action}
                                </Grid>
                            ))}
                        </Grid>
                    </PanelFooter>
                )}
            </Panel>
        </form>
    );
}
