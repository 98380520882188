import { ITemplateListItem, ITemplateVersionDetail, rentingApi, TemplateListQueryParam } from '../index';

class TemplatesClient {
    public async getTemplates({ pageSize = 10, ...query }: TemplateListQueryParam): Promise<ITemplateListItem[]> {
        const { data } = await rentingApi.get('/templates', {
            params: { pageSize, ...query },
        });
        return data;
    }

    public async getTemplate(id?: string): Promise<ITemplateVersionDetail | undefined> {
        const { data } = await rentingApi.get(`/templates/${id}`);
        return data;
    }
}

export const templatesClient = new TemplatesClient();
