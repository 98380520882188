export default {
    rentalRequests: 'Dossiers',
    socialSecurityNumber: 'Rijksregisternummer',
    dateOfBirth: 'Geboortedatum',
    relation: 'Relatie',
    status: 'Status',
    name: 'Naam',
    hasChildSupport: 'Groeipakket',
    expectingChildren: 'Zwangerschap',
    expectedAmount: 'Verwachte aantal kinderen',
    expectedDate: 'Uitgerekende datum',
    pregnancyDocument: 'Zwangerschapsattest',
    eligibleStatus: 'In aanmerking',

    ELIGIBLE: 'In aanmerking',
    NOT_ELIGIBLE: 'Niet in aanmerking',
    UNABLE_TO_CALCULATE: 'Gegevens onvolledig',
    DRAFT: 'Incompleet',
    SUBMITTED: 'Compleet / te verifiëren',
    CHANGES_REQUESTED: 'Wijzigingen gevraagd',
    VERIFIED: 'Geverifieerd',
    INVITED: 'Uitgenodigd',
    APPOINTMENT_PLANNED: 'Bezoek ingepland',
    AWAITING_VISIT_FEEDBACK: 'Afwachting feedback bezoek',
    ALLOCATED: 'Toegewezen',
    DENIED: 'Afgekeurd',
    CONTRACT_SIGNED: 'Contract ondertekend',
    DISAPPROVED: 'Afgekeurd',
    IN_VERIFICATION: 'In verificatie',
    START_VERIFICATION: 'Verificatie gestart',
    CANCEL_VERIFICATION: 'Verificatie geannuleerd',
    REQUEST_CHANGES_RENTAL_REQUEST: 'Wijzigingen gevraagd',
    APPROVE_RENTAL_REQUEST: 'Goedgekeurd',
    DISAPPROVE_RENTAL_REQUEST: 'Afgekeurd',
    REVERIFY_RENTAL_REQUEST: 'Verificatie opnieuw gestart',
    reason: 'Geef een reden',
    selectReasons: 'Selecteer één of meerdere redenen',
    writeReasons: 'Of geef een specifieke reden mee',
    reasonsRequired: 'Selecteer minstens één vooraf ingestelde reden en/of geef een eigen reden mee.',
    sendRequestChanges: 'Vraag wijzigingen aan',
    showAllLogs: 'Toon volledige historiek ({{amount}})',
    logs: 'Historiek',
    logTime: 'Tijdstip',
    logInfo: 'Informatie',
    logCreatedBy: 'Gewijzigd door',
    warnings: 'Waarschuwingen',
    showAllWarings: 'Toon alle waarschuwingen ({{amount}})',
    noErrors: 'Geen waarschuwingen gevonden.',

    score: 'Score',
    familySituation: 'Gezinsituatie',
    familySituationDocument: 'Attest geszinssamenstelling',
    generalInfo: 'Algemene info',
    financial: 'Financieel',
    financial_antwerp: 'Inkomen',
    person: 'Persoon',
    income: 'Inkomsten',
    individualIncome: 'Afzonderlijk belastbaar inkomen',
    documents: 'Attest(en)',
    additionalFinancialAssets: 'Bijkomende attest(en)',
    amount: 'Bedrag',
    monthlyAmount: 'Maandelijks bedrag',
    yearlyAmount: 'Jaarlijks bedrag',
    indexedAmount: 'Geïndexeerd bedrag naar {{year}}',
    certificates: 'Attesten',
    primary: 'Hoofdaanvrager',
    minor: 'Persoon jonger dan 18 jaar',
    currentResidence: 'Huidige woonst',
    contactInformation: 'Contact informatie',
    history: 'Historiek',
    address: 'Adres',
    startVerification: 'Start verificatie',
    cancelVerification: 'Annuleer verificatie',
    approveRentalRequest: 'Keur aanvraag goed',
    disapproveRentalRequestModalTitle: 'Aanvraag afkeuren',
    disapproveRentalRequest: 'Afkeuren',
    requestChangesRentalRequest: 'Vraag wijzigingen',
    reverifyRentalRequest: 'Opnieuw verifiëren',
    submittedDate: 'Ingediend op',
    property: 'Pand',
    contractSigned: 'Contract ondertekend',
    contractSignedTitle: 'Is het contract ondertekend?',
    contractSignedText: 'Ben je zeker dat je deze actie wil uitvoeren? Deze actie kan niet worden teruggedraaid.',
    deletedMagdaResidents_one: '{{count}} persoon verwijderd van officiële gezinssamenstelling',
    deletedMagdaResidents_other: '{{count}} personen verwijderd van officiële gezinssamenstelling',

    PARTNER: 'Partner',
    CHILD: 'Kind',
    OTHER: 'Andere',

    FULL_TIME: 'Voltijds',
    MORE_THAN_5_NIGHTS_PER_2_WEEKS: 'Meer dan 5 nachten per 2 weken',
    LESS_THAN_5_NIGHTS_PER_2_WEEKS: 'Minder dan 5 nachten per 2 weken',
    NEVER: 'Nooit',
    currentAddress: 'Huidig adres hoofdaanvrager',

    MALE: 'Man',
    FEMALE: 'Vrouw',
    UNKNOWN: 'Onbekend',

    hasTaxBillForFullYear: 'Aanslagbiljet voor een volledig werkjaar.',
    taxYear: 'Aanslagjaar',
    incomeYear: 'Inkomensjaar',
    isJointTaxReturn: 'Gemeenschappelijke aangifte met hoofdaanvrager',
    jointTaxableIncome: 'Gezamenlijk belastbaar inkomen',
    monthlyTaxableIncome: 'Belastbaar inkomen per maand.',
    individualTaxableIncome: 'Afzonderlijk belastbaar inkomen',
    livingWage: 'Leefloon',
    disabilityIncome: 'Inkomensvervangende tegemoetkoming aan personen met een handicap',
    foreignIncome:
        'Van belasting vrijgestelde beroepsinkomsten uit het buitenland of verworven bij een Europese of internationale instelling',

    email: 'Email',
    phone: 'Telefoonnummer',
    contactPreference: 'Voorkeur contact via',
    EMAIL: 'E-mail',
    LETTER: 'Briefwisseling',

    refusals: 'Weigeringen',
    comment: 'Opmerking',
    createdDate: 'Aanmaak datum',
    addRefusal: 'Voeg weigering toe',

    sameAsPrimaryResident: 'Zelfde als hoofdaanvrager',

    residentialHistory: 'Minstens 3 jaar aaneensluitend gedomicilieerd in {{city}} in de afgelopen 10 jaar',
    aResidentOwnsProperty: 'Iemand uit het gezin is in het bezit van een eigendom',
    disabilityDocument: 'Attest van invaliditeit',

    cityTies: 'Binding met de stad',
    studyRelation: 'Studeren in {{city}}',
    workRelation: 'Werken in {{city}}',
    domiciliary: 'Domicilie',
    LAST_3_YEARS_DOMICILED: 'Laatste 3 jaar in {{city}} gedomicilieerd',
    AT_LEAST_3_YEARS_DOMICILED: 'Minstens 3 jaar in {{city}} gedomicilieerd',
    EVER_BEEN_DOMICILED: 'Ooit in {{city}} gedomicilieerd',
    NEVER_BEEN_DOMICILED: 'Nooit in {{city}} gedomicilieerd',
    NOW: 'Ja',
    IN_THE_NEXT_SIX_MONTHS: 'Neen, maar wel in de komende 6 maanden',
    DURING_THE_PAST_THREE_YEARS: 'Neen, maar wel in de loop van de voorbije 3 jaar',
    MORE_THAN_THREE_YEARS_AGO: 'Neen, maar wel langer dan 3 jaar geleden',
    NONE: 'Neen',

    downloadCertificate: 'Download attest',

    isManuallyAdded: 'Handmatig toegevoegd',
    remarks: 'Opmerkingen',

    caseManager: 'Dossierbeheerder',
    addCaseManager: 'Dossierbeheerder toevoegen',
    updateCaseManager: 'Wijzig dossierbeheerder',

    // preferences page
    residence: 'Woning',
    regions: 'Districten',
    elevator: 'Nood aan een lift',
    requiredAmountOfBedrooms: 'Aantal slaapkamers',
    propertyArea: 'Oppervlakte (m²)',
    maxRentPrice: 'Voorkeur maximum maandelijkse huurprijs',
    propertyTypePreference: 'Voorkeur woningtype',

    // bond with the city page
    bondWithTheCity: 'Binding met de stad',
    residentialRelation: 'Band met {{city}} als woonplaats',
    workStudyRelation: 'Band met {{city}} als plaats waar je werkt of naar school gaat',
    isCurrentlyRentingFromCity: 'Huurt reeds een woning van {{city}}',

    ResidentialRelation: {
        IN_THE_LAST_3_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 3 jaar, minstens 3 jaar lang',
        IN_THE_LAST_10_YEARS_AT_FOR_LEAST_3_YEARS: 'Ja, in de afgelopen 10 jaar, minstens 3 jaar lang',
        AT_SOMETIME_AT_LEAST_FOR_3_YEARS: 'Ja, ooit, minstens 3 jaar lang',
        NEVER_OR_LESS_THAN_3_YEARS: 'Nee, ik woonde nooit of minder dan 3 jaar in Leuven',
    },

    WorkStudyRelation: {
        IN_THE_LAST_3_YEARS: 'Ja, in de afgelopen 3 jaar',
        IN_THE_LAST_5_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 5 jaar, minstens 3 jaar lang',
        IN_THE_LAST_10_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 10 jaar, minstens 3 jaar lang',
        ONLY_FOR_A_SHORT_PERIOD_OF_TIME: 'Ja, maar voor kortere periode',
        NEVER_OR_LESS_THAN_3_YEARS: 'Nee, ik heb nooit of minder dan 3 jaar in Leuven gewerkt of opleiding gevolgd',
    },

    //financial page
    rentingPrice: 'Huurprijs geïndexeerd naar {{year}}',
    referenceIncome: 'Referentie inkomen',
    monthlyIndexedReferenceIncome: 'Geïndexeerd referentie inkomen per maand',
    indexedMonthlyChildSupport: 'Geïndexeerd groeipakket per maand',
    childSupport: '€ {{indexedAmount}} (op basis van € {{amount}} in {{year}})',
    forfaitChildSupportLabel: 'Forfait groeipakket per maand',
    forfaitChildSupport: `€ {{total}} (op basis van €{{forfait}} x {{children}})`,

    rentSubsidy: 'Huurpremie per maand',
    inclSubsidy: 'wordt in rekening gebracht',
    exclSubsidy: 'wordt niet in rekening gebracht',
    maximumRentingPrice: 'Maximum huurprijs',
    maximumRentingPrice_inclSubsidy: 'Maximum huurprijs inclusief huurpremie',
    maximumRentingPrice_exclSubsidy: 'Maximum huurprijs exclusief huurpremie',
    maximumRentPriceWarningShowAt: ' (vanaf €{{price}} solvabiliteit check)',

    validUntil: 'Geldig tot',
    assetType: 'Type attest',
    financialAsset: {
        BUDGET_RENTING_DOCUMENT: 'Vlaams attest voor budgethuren',
        RENT_SUBSIDY_DOCUMENT: 'Huurpremie',
    },

    //additional questions page
    additionalQuestions: 'Bijkomende vragen',
    addAdditionalQuestion: 'Stel vraag aan aanvrager',
    question: 'Vraag',
    addQuestion: 'Stel vraag',
    unansweredAdditionalQuestions: 'Onbeantwoorde bijkomende vragen',
    additionalQuestionDeleteWarningTitle: 'Verwijder vraag',
    additionalQuestionDeleteWarningText: 'Ben je zeker dat je deze vraag wilt verwijderen?',
};
