import { Warning } from '@mui/icons-material';
import { Alert, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRentalRequest } from '../../hooks';
import { useGetResident, useSortedErrors } from '../../utils';
import { IParams, IRentalRequestError, RentalRequestErrorType, Widget } from '../../../shared';

export const RentalRequestWarnings: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest } = useRentalRequest(id);

    const getResident = useGetResident(rentalRequest);
    const sortedErrors = useSortedErrors(rentalRequest);

    const sortedFilteredErrors = useMemo(() => {
        return sortedErrors
            ?.filter((e) => {
                if (e.type === RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE) {
                    return e.notEligibleReason !== null;
                }
                return true;
            })
            ?.slice(0, 3);
    }, [sortedErrors]);

    const countErrors = useMemo(
        () =>
            rentalRequest?.errors?.reduce((a: number, b: IRentalRequestError) => {
                return a + b.errors?.length + (!!b?.notEligibleReason ? 1 : 0);
            }, 0),
        [rentalRequest],
    );

    const getErrorSecondaryText = useCallback(
        (error: IRentalRequestError) => {
            if (error.type == RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE) {
                return (
                    <>
                        {error.notEligibleReason && (
                            <Alert
                                severity="error"
                                sx={{ borderRadius: '5px', '& .MuiAlert-icon': { alignItems: 'center' } }}
                            >
                                <Typography sx={{ display: 'block' }} variant="body2" component="span">
                                    {t(`errors.${error.notEligibleReason}`)}
                                </Typography>
                            </Alert>
                        )}
                    </>
                );
            }
            return (
                <>
                    <Alert
                        severity="warning"
                        sx={{ borderRadius: '5px', '& .MuiAlert-icon': { alignItems: 'center' } }}
                    >
                        <Typography sx={{ display: 'block' }} variant="body2" component="span">
                            {t(error.errors[0].message, { property: t('errors.' + error.errors[0].property) })}
                        </Typography>
                    </Alert>
                </>
            );
        },
        [t],
    );

    const getErrorMessage = (error: IRentalRequestError) => {
        switch (error.type) {
            case RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE:
                return (
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" sx={{ mb: '10px' }}>
                                {t('errors.eligible')}
                            </Typography>
                        }
                        secondary={getErrorSecondaryText(error)}
                        disableTypography
                    />
                );
            case RentalRequestErrorType.RESIDENT:
                const resident = getResident(error.residentId);
                return (
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" sx={{ mb: '10px' }}>
                                {resident?.firstName === '' || resident?.lastName === ''
                                    ? t('errors.unknown_name')
                                    : `${resident?.firstName} ${resident?.lastName}`}
                            </Typography>
                        }
                        secondary={getErrorSecondaryText(error)}
                        disableTypography
                    />
                );
            case RentalRequestErrorType.RENTAL_REQUEST:
                return (
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" sx={{ mb: '10px' }}>
                                {t('errors.rentalRequestError')}
                            </Typography>
                        }
                        secondary={getErrorSecondaryText(error)}
                        disableTypography
                    />
                );
        }
    };

    return (
        <Widget title={t('warnings')} icon={<Warning color="warning" />}>
            {sortedFilteredErrors && (
                <List sx={{ p: '0', mb: '10px' }}>
                    {sortedFilteredErrors.map((error, index) => (
                        <ListItem key={index} sx={{ p: '0' }}>
                            {getErrorMessage(error)}
                        </ListItem>
                    ))}
                </List>
            )}
            <Link onClick={() => navigate(`/rental-requests/${id}/warnings`)}>
                {t('showAllWarings', { amount: countErrors })}
            </Link>
        </Widget>
    );
};
