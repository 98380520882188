import { rentingApi } from '../../shared';
import { IIncomeSettings, ISaveIncomeSettings } from '../models';

class IncomeSettingsClient {
    public async getSettings(): Promise<IIncomeSettings> {
        const { data } = await rentingApi.get('/income-settings');
        return data;
    }

    public async updateSettings(settings: ISaveIncomeSettings): Promise<IIncomeSettings> {
        const { data } = await rentingApi.post('/income-settings', settings);
        return data;
    }
}

export const incomeSettingsClient = new IncomeSettingsClient();
