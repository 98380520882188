import { DeleteOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveModal } from '../../../shared';
import { useDeleteAdditionalQuestion } from '../../hooks';

interface Props {
    rentalRequestId: string;
    questionId: string;
}

export const DeleteAdditionalQuestion: FC<Props> = ({ rentalRequestId, questionId }) => {
    const { t } = useTranslation();
    const { mutateAsync: deleteQuestion } = useDeleteAdditionalQuestion();

    const onDelete = async () => {
        await deleteQuestion({ rentalRequestId, questionId });
    };
    return (
        <RemoveModal
            handleDelete={onDelete}
            button={
                <IconButton color="primary">
                    <DeleteOutlined />
                </IconButton>
            }
            title={t('additionalQuestionDeleteWarningTitle')}
            text={t('additionalQuestionDeleteWarningText')}
        />
    );
};
