import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox, ControlledInput, DialogTitleWithClose, IAllocation, ISaveRefusal } from '../../../shared';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useSaveRefusal } from '../../hooks';
import { useRefusalSchema } from '../../validators';

interface Props {
    show: boolean;
    handleClose: () => void;
    allocation?: IAllocation | null;
}

export const DenyAllocationModal: FC<Props> = ({ show, handleClose, allocation }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveRefusal, isPending } = useSaveRefusal();
    const disabled = !!allocation?.refusal;
    const lastRefusal =
        (allocation?.rentalRequest?.refusals?.filter((r) => r.createdByRequester)?.length || 0) + 1 ===
        window.carity.environment.maxRefusals;

    const form = useForm<ISaveRefusal>({
        resolver: yupResolver(useRefusalSchema()),
        mode: 'all',
        defaultValues: { createdByRequester: true },
    });

    const createdByRequesterWatch = form.watch('createdByRequester');

    useEffect(() => {
        if (allocation?.refusal) {
            form.reset({ text: allocation.refusal.text, createdByRequester: allocation.refusal.createdByRequester });
        } else {
            form.reset({ createdByRequester: true });
        }
    }, [form, allocation]);

    const onSubmit = async (item: ISaveRefusal) => {
        if (allocation) {
            await saveRefusal({
                allocationId: allocation.id,
                item: { ...item, createdByRequester: !!item.createdByRequester },
            });
            handleClose();
        }
    };

    return (
        <Dialog open={show}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    <DialogTitleWithClose onClose={handleClose}>{t('addRefusal')}</DialogTitleWithClose>
                    <DialogContent>
                        <Box sx={{ minWidth: 500 }}>
                            <ControlledInput multiline rows={5} name="text" label={t('comment')} disabled={disabled} />
                            <ControlledCheckbox
                                control={form.control}
                                name="createdByRequester"
                                label={t('createdByRequester')}
                                disabled={disabled}
                            />
                            {!allocation?.refusal && createdByRequesterWatch && lastRefusal && (
                                <Typography variant="caption" color="error">
                                    {t('lastRefusalInformation')}
                                </Typography>
                            )}
                        </Box>
                    </DialogContent>

                    {!allocation?.refusal && (
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isPending}
                                startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                            >
                                {t('confirm')}
                            </Button>
                            <Button onClick={handleClose} disabled={isPending}>
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    )}
                </form>
            </FormProvider>
        </Dialog>
    );
};
