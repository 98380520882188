import { MailTwoTone, SearchOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    formatDateTimeString,
    IMailLog,
    IParams,
    Page,
    SortOrder,
    Table,
    useQueryParams,
    Widget,
} from '../../../shared';
import { DetailMail, RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { useRentalRequest, useRentalRequestMailList } from '../../hooks';

export const RentalRequestCommunicationPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest, isLoading: rentalRequestIsLoading } = useRentalRequest(id);

    const [detailModal, setDetailModel] = useState<{ show: boolean; mail: IMailLog | null }>({
        show: false,
        mail: null,
    });
    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { data: mails, isLoading: mailsIsLoading } = useRentalRequestMailList(id, {
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('logTime'),
            valueFormatter: (value: string) => formatDateTimeString(value),
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'to',
            headerName: t('to'),
            minWidth: 120,
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'templateName',
            headerName: t('template'),
            minWidth: 120,
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            minWidth: 120,
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'actions',
            headerName: ' ',
            sortable: false,
            align: 'right',
            flex: 0.2,
            minWidth: 40,
            renderCell: (params) => (
                <IconButton size="large" onClick={() => setDetailModel({ show: true, mail: params.row })}>
                    <SearchOutlined />
                </IconButton>
            ),
        },
    ];

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={rentalRequestIsLoading}
        >
            <Widget icon={<MailTwoTone color="primary" />} hasTable title={t('communication')}>
                <Table
                    rows={mails?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={mails?.pagination.size || 0}
                    loading={mailsIsLoading}
                    sortModel={sort}
                    onSortModelChange={setSort}
                    onRowClick={(params) => setDetailModel({ show: true, mail: params.row })}
                />
            </Widget>
            <DetailMail
                show={detailModal.show}
                mail={detailModal.mail}
                handleClose={() => setDetailModel({ show: false, mail: null })}
            />
        </Page>
    );
};
