import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    ICaseManager,
    IMailLog,
    IRentalRequest,
    IRentalRequestList,
    IRentalRequestLog,
    IRentalRequestQuery,
    IStatusChange,
    ListModel,
    ListQueryParam,
    QueryKeys,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
} from '../../shared';
import { rentalRequestsClient } from '../clients';

const client = rentalRequestsClient;
const listKey = QueryKeys.RentalRequests;
const itemKey = QueryKeys.RentalRequest;

export function useRentalRequestList(...args: UseListParams<IRentalRequestList, IRentalRequestQuery>) {
    return useList(client, listKey, ...args);
}

export function useRentalRequest(...args: UseItemParams<IRentalRequest>) {
    return useItem(client, itemKey, ...args);
}

export function useRentalRequestLogList(
    rentalRequestId: string,
    query: ListQueryParam,
    options: Omit<UseQueryOptions<ListModel<IRentalRequestLog>, AxiosError>, 'queryKey'> = {},
): UseQueryResult<ListModel<IRentalRequestLog>, AxiosError> {
    return useQuery<ListModel<IRentalRequestLog>, AxiosError>({
        queryKey: [itemKey, rentalRequestId, query],
        queryFn: () => client.getLogsList(rentalRequestId, query),
        ...options,
    });
}

export function useRentalRequestMailList(
    rentalRequestId: string,
    query: ListQueryParam,
    options: Omit<UseQueryOptions<ListModel<IMailLog>, AxiosError>, 'queryKey'> = {},
): UseQueryResult<ListModel<IMailLog>, AxiosError> {
    return useQuery<ListModel<IMailLog>, AxiosError>({
        queryKey: [itemKey, rentalRequestId, query],
        queryFn: () => client.getMailList(rentalRequestId, query),
        ...options,
    });
}

export function useStartVerification(): UseMutationResult<IRentalRequest, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.startVerification(id),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useCancelVerification(): UseMutationResult<IRentalRequest, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.cancelVerification(id),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useApproveRentalRequest(): UseMutationResult<IRentalRequest, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.approveRentalRequest(id),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useDisapproveRentalRequest(): UseMutationResult<
    IRentalRequest,
    AxiosError,
    { id: string; statusChange: IStatusChange }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, statusChange }) => client.disapproveRentalRequest(id, statusChange),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useReverifyRentalRequest(): UseMutationResult<IRentalRequest, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.reverifyRentalRequest(id),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useRequestChangesRentalRequest(): UseMutationResult<
    IRentalRequest,
    AxiosError,
    { id: string; statusChange: IStatusChange }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, statusChange }) => client.requestChangesRentalRequest(id, statusChange),
        onSuccess: async (r, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useRentalRequestUpsertCaseManager(): UseMutationResult<
    IRentalRequest,
    AxiosError,
    { rentalRequestId: string; caseManager: ICaseManager }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ rentalRequestId, caseManager }) => client.upsertCaseManager(rentalRequestId, caseManager),
        onSuccess: async (r, { rentalRequestId }) => {
            await queryClient.setQueryData([itemKey, rentalRequestId], r);
        },
    });
}

export function useRentalRequestContractSigned(): UseMutationResult<IRentalRequest, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.contractSigned(id),
        onSuccess: async (r, { id }) => {
            await queryClient.setQueryData([itemKey, id], r);
        },
    });
}
