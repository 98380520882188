import { Alert } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IParams, useAllocationTrack } from '../../../shared';

interface Props {
    selected: number;
}

export const AllocationTrackAddCandidatesAlert: FC<Props> = ({ selected }) => {
    const { t } = useTranslation();
    const { id } = (useParams<keyof IParams>() as IParams) || '';

    const { data: allocationTrack } = useAllocationTrack(id);

    const amountOfProperties =
        (allocationTrack?.properties?.length || 0) -
        (allocationTrack?.allocations?.filter((allocation) => !allocation.refusal)?.length || 0);

    return (
        <Alert severity="info" sx={{ mb: 3 }}>
            {t('informationAlertAmountProperties', {
                propertyType: allocationTrack?.propertyType?.name,
                count: amountOfProperties > 0 ? amountOfProperties : 0,
                interpolation: { escapeValue: false },
            })}
            {t('informationAlertAmountCandidates', { count: selected })}
        </Alert>
    );
};
