export enum DashboardFilterType {
    SELECT = 'SELECT',
    DATE = 'DATE',
}

export interface IDashboardFilter {
    name: string;
    type: DashboardFilterType;
    target?: string;
    values?: { name: string; value: string }[];
}

export interface IAppliedDateFilterDto {
    type: DashboardFilterType.DATE;
    startDate: string;
    endDate: string;
}

export interface IAppliedSelectFilterDto {
    type: DashboardFilterType.SELECT;
    target: string;
    values: string[];
}

export type IAppliedDashboardFilterDto = IAppliedDateFilterDto | IAppliedSelectFilterDto;
