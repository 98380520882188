import { Box } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading, SideBar, SideNav } from '../../../shared';
import { useDashboards } from '../../hooks';
import { IDashboardList } from '../../models';
import { DashboardPage } from '../dashboard/dashboard.page';

export const ReportingPage: FC = () => {
    const { data: dashboards = [], isLoading } = useDashboards();
    const menuItems = useDashboardMenuItems(dashboards);
    const navigate = useNavigate();
    const params = useParams();
    const id = Number(params.id);
    const isValidId = !isNaN(id);

    useEffect(() => {
        const firstDashboard = menuItems[1];
        if (!isValidId && firstDashboard) navigate(firstDashboard.path);
    }, [menuItems, isValidId, navigate]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav items={menuItems} />
            </SideBar>
            <Box sx={{ flex: 1 }}>{isLoading || !isValidId ? <Loading /> : <DashboardPage />}</Box>
        </Box>
    );
};

function useDashboardMenuItems(
    dashboards: IDashboardList[],
): [{ text: string; key: string }, ...{ path: string; text: string }[]] {
    const { t } = useTranslation();

    return useMemo(
        () => [
            { text: t('navigation.reports'), key: 'reports' },
            ...dashboards.map(({ id, name }) => ({
                path: `/reports/dashboard/${id}`,
                text: name,
            })),
        ],
        [dashboards, t],
    );
}
