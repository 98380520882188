import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../../shared';
import { Chart, DashboardFilter } from '../../components';
import { useChartsByDashboard, useDashboard } from '../../hooks';
import { IAppliedDashboardFilterDto } from '../../models';

export const DashboardPage: FC = () => {
    const params = useParams();
    const id = Number(params.id);
    const { data: dashboard, isLoading } = useDashboard(id);
    const { data: charts = [] } = useChartsByDashboard(id);
    const availableFilters = dashboard?.filters || [];
    const hasFilters = availableFilters.length > 0;
    const [filters, setFilters] = useState<IAppliedDashboardFilterDto[]>([]);

    return (
        <Page
            title={dashboard?.name}
            filter={
                hasFilters && (
                    <DashboardFilter filters={filters} availableFilters={availableFilters} onChange={setFilters} />
                )
            }
            loading={isLoading}
        >
            <Grid container gap={4}>
                {charts.map((chart) => (
                    <Grid item xs={12} key={chart.id}>
                        <Chart id={chart.id} filters={filters} />
                    </Grid>
                ))}
            </Grid>
        </Page>
    );
};
