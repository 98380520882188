import { BaseClient, ContactType, IInvite, ISaveInvite, rentingApi } from '../../shared';

class InvitesClient extends BaseClient<IInvite, ISaveInvite> {
    constructor() {
        super(rentingApi, '/invites');
    }

    public async downloadInvite(
        contactType: ContactType,
        propertyId: string,
        rentalRequestId: string,
    ): Promise<string> {
        const { data } = await this.api.get<string>(`/invites/download`, {
            params: { contactType, propertyId, rentalRequestId },
        });
        return data;
    }
}
export const invitesClient = new InvitesClient();
