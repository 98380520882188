import { Link, Paper, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoutPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: 8,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                pb: 4,
            }}
        >
            <Stack spacing={2} p={4} alignItems="center">
                <img src={window.carity.environment.theme.logo} alt="Carity" width="80px" />
                <Typography variant="h2">{t('logoutTitle')}</Typography>
                <Link href="/">{t('goToLogin')}</Link>
            </Stack>
        </Paper>
    );
};
