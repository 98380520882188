import { useCallback, useMemo } from 'react';
import { compareResidents } from './compare-residents.util';
import { IRentalRequest, RentalRequestErrorType } from '../../shared';

export const useGetResident = (rentalRequest?: IRentalRequest): any =>
    useCallback(
        (id?: string) => {
            return rentalRequest?.residents.find((res) => res.id === id);
        },
        [rentalRequest],
    );

export const useSortedErrors = (rentalRequest?: IRentalRequest) => {
    const getResident = useGetResident(rentalRequest);
    return useMemo(() => {
        return rentalRequest?.errors?.sort((a, b) => {
            if (a.type === RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE) {
                return -1;
            } else if (a.type === RentalRequestErrorType.RENTAL_REQUEST) {
                return b.type === RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE ? 1 : -1;
            } else if (
                b.type === RentalRequestErrorType.RENTAL_REQUEST ||
                b.type === RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE
            ) {
                return 1;
            }
            const resA = getResident(a.residentId);
            const resB = getResident(b.residentId);

            return compareResidents(resA, resB);
        });
    }, [rentalRequest, getResident]);
};
