import { GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AllocationTrackStatus,
    AllocationTrackType,
    formatDateString,
    IAllocationManager,
    IAllocationTrackQuery,
    IProperty,
    IPropertyType,
    SortOrder,
    Table,
    useAllocationTrackList,
    useQueryParamsContext,
} from '../../../shared';

interface Props {
    setCount: (value: number) => void;
    filter: IAllocationTrackQuery;
}

export const AllocationTracksWithPropertyTypes: FC<Props> = ({ setCount, filter }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('allocation-tracks-with-property-types', {
        sort: [{ field: 'propertyType', sort: 'asc' as GridSortDirection }],
    });

    const { data: allocationTracks } = useAllocationTrackList({
        page,
        pageSize,
        status: filter?.['status'] ? (filter['status'] as AllocationTrackStatus) : undefined,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        type: AllocationTrackType.PROPERTY_TYPE,
    });

    useEffect(() => {
        setCount(allocationTracks?.data?.length || 0);
    }, [allocationTracks, setCount]);

    const columns: GridColDef[] = [
        {
            field: 'propertyType',
            headerName: t('propertyType'),
            width: 200,

            valueFormatter: (value: IPropertyType) => value?.name,
        },
        {
            field: 'properties',
            headerName: t('propertiesCount'),
            width: 150,
            sortable: false,
            valueFormatter: (values: IProperty[]) => values.length,
        },
        {
            field: 'allocationManager',
            headerName: t('allocationManager'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: IAllocationManager) => value?.name,
        },
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: string) => formatDateString(value),
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: AllocationTrackType) => t(value),
        },
    ];

    return (
        <>
            {(allocationTracks?.data || []).length > 0 && (
                <Table
                    rows={allocationTracks?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={allocationTracks?.pagination.size || 0}
                    sortModel={sort}
                    onSortModelChange={setSort}
                    onRowClick={(row) => navigate(`/allocation-tracks/${row.id}/candidates`)}
                />
            )}
        </>
    );
};
