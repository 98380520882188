import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveCaseManager } from '../save-case-manager/save-case-manager.component';
import { IRentalRequest, RentalRequestStatus } from '../../../shared';
import { useReverifyRentalRequest } from '../../hooks';

interface Props {
    rentalRequest: IRentalRequest;
}

export const RentalRequestTitle: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();
    const { mutateAsync: reverifyRentalRequest } = useReverifyRentalRequest();
    const [showSaveCaseManager, setShowSaveCaseManager] = useState(false);

    const primaryResident = useMemo(() => {
        return rentalRequest?.residents?.find((resident) => resident.isPrimaryResident);
    }, [rentalRequest]);

    const canReverifyRentalRequest = useMemo(() => {
        return (
            rentalRequest?.status === RentalRequestStatus.DISAPPROVED ||
            rentalRequest?.status === RentalRequestStatus.VERIFIED
        );
    }, [rentalRequest]);

    return (
        <>
            <Stack direction="row" sx={{ '.MuiLink-root': { fontSize: '12px', cursor: 'pointer' } }}>
                <Box>
                    <Typography variant="subtitle1" mt={1}>
                        {`${primaryResident?.firstName || ''} ${primaryResident?.lastName || ''}`}
                    </Typography>

                    {rentalRequest.caseManager ? (
                        <Typography variant="caption" sx={{ position: 'relative', top: '-5px' }}>
                            {`${t('caseManager')}: ${rentalRequest.caseManager.name} `}
                            <Link onClick={() => setShowSaveCaseManager(true)}>{t('edit')}</Link>
                        </Typography>
                    ) : (
                        <Link onClick={() => setShowSaveCaseManager(true)} sx={{ position: 'relative', top: '-5px' }}>
                            {t('addCaseManager')}
                        </Link>
                    )}
                </Box>

                <Stack direction="row" alignItems="center" ml={5}>
                    {rentalRequest?.score !== null && <Chip label={`${t('score')}: ${rentalRequest.score}`} />}
                    {rentalRequest?.status && <Chip label={t(rentalRequest.status)} sx={{ ml: 1 }} />}
                    {canReverifyRentalRequest && (
                        <Link onClick={() => reverifyRentalRequest({ id: rentalRequest.id })} sx={{ ml: 2 }}>
                            {t('reverifyRentalRequest')}
                        </Link>
                    )}
                </Stack>
            </Stack>

            <SaveCaseManager
                show={showSaveCaseManager}
                rentalRequest={rentalRequest}
                handleClose={() => setShowSaveCaseManager(false)}
            />
        </>
    );
};
