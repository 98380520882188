export default {
    allocationTracks: 'Toewijzingstrajecten',
    allocationTrack: 'Toewijzingstraject',
    currentAllocationTrack: 'Huidig toewijzingstraject',
    allocationTrackForPropertyType: 'Toewijzingstraject voor pand type: ',
    allocationTrackForProperties: 'Toewijzingstraject voor panden: ',
    noAllocationsFound: 'Er zijn geen toewijzingstrajecten terug gevonden.',
    toCurrentAllocationTrack: 'Bekijk huidige toewijzingstraject',
    newAllocationTrack: 'Nieuw toewijzingstraject',
    newAllocationTrackForProperty: 'Nieuw traject voor een pand',
    newAllocationTrackForType: 'Nieuw traject voor een pand type',
    candidates: 'Kandidaten',
    allocationManager: 'Trajectbeheerder',
    updateAllocationManager: 'Wijzig trajectbeheerder',
    startAllocationTrack: 'Start toewijzingstraject',
    closeAllocationTrack: 'Sluit toewijzingstraject',
    closeAllocationTrackWarningText:
        "Bent u zeker dat u het toewijzingstraject met naam '{{propertyTypeName}}' wilt sluiten?",
    CREATED: 'Aangemaakt',
    MATCHING: 'Toewijzing',
    INVITING: 'Uitnodiging',
    CLOSED: 'Gesloten',
    NOT_ALLOCATED: 'Niet toegewezen',
    propertiesCount: 'Aantal panden',
    nextAction: 'Volgende actie',
    actionMakeAppointment: 'Afspraak maken',
    actionGiveFeedback: 'Feedback geven',
    actionDaysRemaining_one: '{{count}} dag resterend',
    actionDaysRemaining_other: '{{count}} dagen resterend',
    actionDaysLate_one: '{{count}} dag te laat',
    actionDaysLate_other: '{{count}} dagen te laat',
    actionAppointmentPlanned: 'Bezoek ingepland op',

    viewPropertyDetails: 'Bekijk pand',
    deletePropertyFromAllocationTrackWarningTitle: 'Verwijder pand van toewijzingstraject.',
    deletePropertyFromAllocationTrackWarningText:
        'Bent u zeker dat u dit pand wilt verwijderen van dit toewijzingstraject?',
    addPropertyToAllocationTrack: 'Voeg pand toe',
    indexedPropertyPrice: 'Geïndexeerde maximum prijs',
    indexedPropertyPriceSingleParent: 'Geïndexeerde maximum prijs voor eenoudergezinnen',

    viewCandidateDetails: 'Bekijk dossier',
    inviteCandidate: 'Uitnodigen',
    deleteCandidateFromAllocationTrackWarningTitle: 'Verwijder kandidaat van toewijzingstraject.',
    deleteCandidateFromAllocationTrackWarningText:
        'Bent u zeker dat u deze kandidaat wilt verwijderen van dit toewijzingstraject?',
    addCandidateToAllocationTrack: 'Voeg kandidaat toe',
    refusalReason: 'Reden weigering',
    referenceIncome: 'Referentie inkomen',
    addCandidatesAllocationTrack: 'Voeg kandidaat toe',
    showRentWarning: '35-50% van inkomen nodig voor de huur, solvabiliteit check noodzakelijk.',

    informationAlertAmountProperties_one: 'Voor {{propertyType}} is er {{count}} pand beschikbaar. ',
    informationAlertAmountProperties_other: 'Voor {{propertyType}} zijn er {{count}} panden beschikbaar. ',
    informationAlertAmountCandidates_one: 'Er is momenteel {{count}} kandidaat geselecteerd.',
    informationAlertAmountCandidates_other: 'Er zijn momenteel {{count}} kandidaten geselecteerd.',

    noCandidates: 'Geen kandidaten',
    informationAlertTextNoCandidates_one:
        'Er zijn nog geen kandidaten voor dit toewijzingstracject en {{count}} pand beschikbaar.',
    informationAlertTextNoCandidates_other:
        'Er zijn nog geen kandidaten voor dit toewijzingstracject en {{count}} panden beschikbaar.',

    notEnoughCandidates: 'Te weinig kandidaten',
    informationAlertTextNotEnoughProperties_one: 'Er is {{count}} pand beschikbaar ',
    informationAlertTextNotEnoughProperties_other: 'Er zijn {{count}} panden beschikbaar ',
    informationAlertTextNotEnoughCandidates_one: 'maar slechts {{count}} kandidaat.',
    informationAlertTextNotEnoughCandidates_other: 'maar slechts {{count}} kandidaten.',

    toManyCandidates: 'Te veel kandidaten',
    informationAlertTextToManyCandidates_one: 'Er is {{count}} kandidaat ',
    informationAlertTextToManyCandidates_other: 'Er zijn {{count}} kandidaten ',
    informationAlertTextToManyProperties_one: 'maar slechts {{count}} pand beschikbaar.',
    informationAlertTextToManyProperties_other: 'maar slechts {{count}} panden beschikbaar.',

    sentInvite: 'Uitnodiging versturen',
    communicationMethod: 'Verzendwijze',
    showPropertyDetails: 'Pand details bekijken',
    showCandidateDetails: 'Bekijk kandidaat gegevens',
    removeAllocation: 'Verwijder allocatie',
    candidate: 'Kandidaat',
    invite: 'Uitnodiging',
    download: {
        LETTER: 'Download brief',
        EMAIL: 'Download mail',
    },
    downloadLetter: 'Download brief',
    sentEmail: 'Verstuur email',
    inviteDetails: 'Verstuurd op {{date}} via {{type}}',
    propertyHasInviteWarning: '(Er is al een uitnodiging verstuurd voor dit pand)',
    propertyAlreadyRefused: '(Dit pand is reeds geweigerd)',
    verifiedCandidates: 'Geverifieerde dossiers',

    appointment: 'Afspraak',
    makeAppointment: 'Plan een afspraak',
    alterAppointment: 'Wijzig een afspraak',
    appointmentDetails: 'Afspraak ingepland op {{date}}',

    removedRentalRequest: 'Verwijdert dossier',
    denyAllocation: 'Weiger toewijzing',
    createdByRequester: 'Geweigerd door kandidaat',
    denyDetails: 'Geweigerd op {{date}}',
    activeAllocations: 'Kandidaten',
    deniedAllocations: 'Weigeringen',
    refusalText: 'Reden van weigering',

    allocate: 'Bevestig toewijzing pand',
    allocateDetails: 'Toegewezen op {{date}}',
    allocatePropertyToCandidate: 'Bevestig de toewijzing van {{propertyName}} aan {{candidateName}}',

    deleteRefusal: 'Verwijder weigering',
    deleteRefusalWarningTitle: 'Verwijder weigering',
    deleteRefusalWarningText:
        'Bent u zeker dat u deze weigering wilt verwijderen? Dit kan invloed hebben op het voorkomen van de kandidaat in de toewijzingslijst.',

    lastRefusalInformation:
        'Dit is de laatste weigering voor deze kandidaat, na deze weigering zal deze kandidaat niet meer voorkomen bij mogelijke kandidaten. De kandidaat ontvangt een aangepaste mail om hiervan op de hoogte te worden gebracht.',
};
