import React, { FC } from 'react';
import { IParams, LabelValue, RentalRequestAssetType, Widget } from '../../../shared';
import { Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRentalRequest } from '../../hooks';
import { Stack, Typography } from '@mui/material';
import { formatAmount } from '../../utils';
import { DownloadAssets } from '../download-assets/download-assets.component';

export const RentalRequestRentingPrice: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: rentalRequest } = useRentalRequest(id);
    const { showRentWarning, forfaitChildSupport, rentSubsidy } = window.carity.environment;

    const amountOfChildren = rentalRequest?.residents.filter(({ isAdult }) => !isAdult).length || 0;
    const primaryFinancialData = rentalRequest?.residents.find(
        ({ isPrimaryResident }) => isPrimaryResident,
    )?.financialData;

    let useRentSubsidyForMaxRent;
    if (primaryFinancialData?.useRentSubsidyForMaxRent !== undefined) {
        useRentSubsidyForMaxRent = primaryFinancialData.useRentSubsidyForMaxRent ? 'inclSubsidy' : 'exclSubsidy';
    }

    return (
        <Widget
            icon={<Home color="primary" />}
            title={t('rentingPrice', { year: rentalRequest?.indexation?.referenceIndexation.year })}
        >
            <Stack spacing={2}>
                <LabelValue
                    label={t('monthlyIndexedReferenceIncome')}
                    value={
                        rentalRequest?.referenceIncome
                            ? `€ ${formatAmount(rentalRequest.referenceIncome / 12)}`
                            : undefined
                    }
                />

                {forfaitChildSupport ? (
                    <LabelValue
                        label={t('forfaitChildSupportLabel')}
                        value={t('forfaitChildSupport', {
                            total: forfaitChildSupport * amountOfChildren,
                            forfait: forfaitChildSupport,
                            children: amountOfChildren,
                        })}
                    />
                ) : (
                    <LabelValue
                        label={t('indexedMonthlyChildSupport')}
                        value={
                            rentalRequest?.indexedMonthlyChildSupport ? (
                                <Stack direction="row" spacing={2}>
                                    <Typography>
                                        {t('childSupport', {
                                            indexedAmount: formatAmount(rentalRequest.indexedMonthlyChildSupport),
                                            amount: formatAmount(rentalRequest.monthlyChildSupport),
                                            year: new Date(rentalRequest.submittedDate).getFullYear(),
                                        })}
                                    </Typography>
                                    <DownloadAssets
                                        rentalRequestAssets={rentalRequest.rentalRequestAssets}
                                        rentalRequestAssetType={RentalRequestAssetType.CHILD_SUPPORT_DOCUMENT}
                                    />
                                </Stack>
                            ) : undefined
                        }
                    />
                )}

                {rentSubsidy && (
                    <LabelValue
                        label={t('rentSubsidy')}
                        value={
                            primaryFinancialData?.hasRentSubsidy ? (
                                <Typography>
                                    {`€ ${primaryFinancialData?.rentSubsidy}, ${useRentSubsidyForMaxRent && t(useRentSubsidyForMaxRent)}`}
                                </Typography>
                            ) : undefined
                        }
                    />
                )}

                <LabelValue
                    label={t('maximumRentingPrice', {
                        context: rentSubsidy && !!primaryFinancialData?.hasRentSubsidy && useRentSubsidyForMaxRent,
                    })}
                    value={`€ ${rentalRequest?.maximumRentingPrice}${showRentWarning ? t('maximumRentPriceWarningShowAt', { price: rentalRequest?.maximumRentingPriceWarning }) : ''}`}
                />
            </Stack>
        </Widget>
    );
};
