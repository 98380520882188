import { ListAltTwoTone } from '@mui/icons-material';
import { Link, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTimeString, LabelValue, Widget } from '../../../shared';
import { useRentalRequestLogList } from '../../hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
    rentalRequestId: string;
}
export const RentalRequestHistory: FC<Props> = ({ rentalRequestId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: logs } = useRentalRequestLogList(rentalRequestId, {
        pageSize: 3,
        sortBy: 'createdDate',
        sortOrder: 'DESC',
    });

    return (
        <Widget title={t('history')} icon={<ListAltTwoTone color="primary" />}>
            <Stack spacing={2} mb={3}>
                {logs?.data?.map((log) => (
                    <LabelValue
                        key={log.id}
                        label={formatDateTimeString(log.createdDate)}
                        value={log.createdBy ? `${t(log.text)} - ${log.createdBy}` : t(log.text)}
                    />
                ))}
            </Stack>
            {logs?.pagination?.size && (
                <Link onClick={() => navigate(`/rental-requests/${rentalRequestId}/logs`)}>
                    {t('showAllLogs', { amount: logs?.pagination?.size })}
                </Link>
            )}
        </Widget>
    );
};
