import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { communicationSettingsClient, ICommunicationSettings } from '../index';
import { QueryKeys } from '../enums';

const client = communicationSettingsClient;
const listKey = QueryKeys.CommunicationSettings;

export function useCommunicationSettings() {
    return useQuery({
        queryKey: [listKey],
        queryFn: () => client.getCommunicationSettings(),
    });
}

export function useUpdateCommunicationSettings() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (settings: ICommunicationSettings) =>
            client.updateCommunication(
                settings,
                t('updateCommunicationSettingsSuccess'),
                t('updateCommunicationSettingsError'),
            ),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [listKey] }),
    });
}
