import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, LabelValue, Page, RentalRequestAssetType, Widget } from '../../../shared';
import { DownloadAssets, RentalRequestTitle, RentalRequestVerificationActions } from '../../components';
import { useRentalRequest } from '../../hooks';
import { Stack } from '@mui/material';
import { LocationCity } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const RentalRequestBondWithTheCityPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const city = window.carity.environment.city;
    const { data: rentalRequest, isLoading } = useRentalRequest(id);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={rentalRequest && <RentalRequestTitle rentalRequest={rentalRequest} />}
            actions={[<RentalRequestVerificationActions id={id} status={rentalRequest?.status} />]}
            loading={isLoading}
        >
            <Stack spacing={2}>
                <Widget icon={<LocationCity color="primary" />} title={t('bondWithTheCity')}>
                    <Stack spacing={2}>
                        <LabelValue
                            label={t('isCurrentlyRentingFromCity', { city })}
                            value={rentalRequest?.bondWithTheCity?.isCurrentlyRentingFromCity ? t('yes') : t('no')}
                        />
                        <LabelValue
                            label={t('residentialRelation', { city })}
                            value={
                                rentalRequest?.bondWithTheCity?.residentialRelation && (
                                    <Stack>
                                        {t(
                                            `ResidentialRelation.${rentalRequest?.bondWithTheCity?.residentialRelation}`,
                                        )}
                                        {rentalRequest?.bondWithTheCity?.rentalRequestAssets && (
                                            <DownloadAssets
                                                rentalRequestAssets={
                                                    rentalRequest?.bondWithTheCity?.rentalRequestAssets
                                                }
                                                rentalRequestAssetType={
                                                    RentalRequestAssetType.RESIDENTIAL_RELATION_DOCUMENT
                                                }
                                            />
                                        )}
                                    </Stack>
                                )
                            }
                        />

                        <LabelValue
                            label={t('workStudyRelation', { city })}
                            value={
                                rentalRequest?.bondWithTheCity?.workStudyRelation && (
                                    <Stack>
                                        {t(`WorkStudyRelation.${rentalRequest?.bondWithTheCity?.workStudyRelation}`)}
                                        {rentalRequest?.bondWithTheCity?.rentalRequestAssets && (
                                            <DownloadAssets
                                                rentalRequestAssets={
                                                    rentalRequest?.bondWithTheCity?.rentalRequestAssets
                                                }
                                                rentalRequestAssetType={
                                                    RentalRequestAssetType.WORK_STUDY_RELATION_DOCUMENT
                                                }
                                            />
                                        )}
                                    </Stack>
                                )
                            }
                        />
                    </Stack>
                </Widget>
            </Stack>
        </Page>
    );
};
