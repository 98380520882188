import { DialogContent, MenuItem, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AllocationTrackType,
    ControlledInput,
    ControlledSelect,
    IProperty,
    useManagerList,
    usePropertyTypeList,
} from '../../../shared';
import { useGetUnallocatedProperties } from '../../../properties/hooks';

interface Props {
    selectedTab: string;
    property?: IProperty;
}

export const CreateAllocationTrackForm: FC<Props> = ({ selectedTab, property }) => {
    const { t } = useTranslation();

    const { data: managers } = useManagerList();
    const { data: properties } = useGetUnallocatedProperties({ page: 1, pageSize: 1000 });
    const { data: propertyTypes } = usePropertyTypeList({ page: 1, pageSize: 1000 });

    return (
        <DialogContent>
            <Stack minWidth={500} spacing={2} mt={2}>
                {property ? (
                    <ControlledInput name="propertyId" label={t('property')} value={property.name} disabled required />
                ) : (
                    <>
                        {selectedTab === AllocationTrackType.PROPERTY && (
                            <ControlledSelect name="propertyId" label={t('property')} required>
                                {properties?.data?.map((property) => (
                                    <MenuItem value={property.id} key={property.id}>
                                        {property.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        )}

                        {selectedTab === AllocationTrackType.PROPERTY_TYPE && (
                            <ControlledSelect name="propertyTypeId" label={t('propertyType')} required>
                                {propertyTypes?.data?.map((propertyType) => (
                                    <MenuItem value={propertyType.id} key={propertyType.id}>
                                        {propertyType.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        )}
                    </>
                )}

                <ControlledSelect name="userId" label={t('allocationManager')} required>
                    {managers?.items?.map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                            {user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email}
                        </MenuItem>
                    ))}
                </ControlledSelect>
            </Stack>
        </DialogContent>
    );
};
