import { BaseClient, IProperty, ISaveProperty, ListModel, ListQueryParam, rentingApi } from '../../shared';

class PropertyClient extends BaseClient<IProperty, ISaveProperty> {
    constructor() {
        super(rentingApi, '/properties');
    }

    public async getUnallocatedProperties(query: ListQueryParam): Promise<ListModel<IProperty>> {
        const { data } = await this.api.get<ListModel<IProperty>>(`${this.basePath}/unallocated-properties`, {
            params: query,
        });
        return data;
    }
}

export const propertyClient = new PropertyClient();
