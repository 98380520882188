import { mailMessagesClient } from '../clients';
import { QueryKeys } from '../enums';
import { IMailMessage } from '../models';
import { useDelete, useSave } from './base.hook';
import { useItem, UseItemParams, useList, UseListParams } from './read-only.hook';

const client = mailMessagesClient;
const listKey = QueryKeys.MailMessages;
const itemKey = QueryKeys.MailMessage;

export function useMailMessageList(...args: UseListParams<IMailMessage>) {
    return useList(client, listKey, ...args);
}

export function useMailMessage(...args: UseItemParams<IMailMessage>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveMailMessage() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteMailMessage() {
    return useDelete(client, listKey);
}
