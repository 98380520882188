import { BaseClient, rentingApi } from '../../shared';
import { IIndexation, ISaveIndexation } from '../models';

class IndexationsClient extends BaseClient<IIndexation, ISaveIndexation> {
    constructor() {
        super(rentingApi, '/indexation');
    }
}

export const indexationsClient = new IndexationsClient();
