import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    DialogTitleWithClose,
    formatPropertyAddress,
    IParams,
    Loading,
    SortOrder,
    Table,
    useAddPropertiesToAllocationTrack,
    useGetUnallocatedPropertiesForAllocationTrack,
    useQueryParams,
} from '../../../shared';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const AllocationTrackAddPropertiesModal: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();

    const { id } = (useParams<keyof IParams>() as IParams) || '';
    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'name', sort: 'asc' }],
    });
    const { data: properties, isLoading } = useGetUnallocatedPropertiesForAllocationTrack(id, {
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>();
    const { mutateAsync: addProperties } = useAddPropertiesToAllocationTrack();

    const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
        setSelectedPropertyIds(ids as string[]);
    };

    const onSubmit = async () => {
        await addProperties({ id, propertyIds: selectedPropertyIds || [] });
        handleClose();
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('property'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => <>{formatPropertyAddress(params?.row?.address)}</>,
        },
        {
            field: 'region',
            headerName: t('region'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => params?.row?.address?.region,
        },
        {
            field: 'amountOfBedrooms',
            headerName: t('propertyAmountOfBedrooms'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
    ];

    return (
        <Dialog open={open} fullWidth maxWidth={false}>
            <DialogTitleWithClose onClose={handleClose}>{t('addPropertyToAllocationTrack')}</DialogTitleWithClose>
            <DialogContent>
                <Box sx={{ width: '100%' }}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Table
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                            rows={properties?.data || []}
                            columns={columns}
                            onSortModelChange={setSort}
                            sortModel={sort}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            rowCount={properties?.pagination?.size || 0}
                            sx={{
                                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                                    display: 'none',
                                },
                            }}
                        />
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button type="submit" onClick={() => onSubmit()} variant="contained">
                    {t('save')}
                </Button>
                <Button onClick={handleClose}>{t('cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};
