import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../shared';
import { IDashboardFilter } from '../../models';

interface Props {
    filter: IDashboardFilter;
}

export const DashboardSelectFilter: FC<Props> = ({ filter: { name, target, values: options = [] } }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const formName = target || name;
    const currentValues = watch(formName);

    const getOptionNameByValue = (value: string): string =>
        options?.find((option) => option.value === value)?.name || t('unknown');

    const formatSelectedValues = (values: string[] = []) =>
        options?.length === values?.length ? t('all') : values.map(getOptionNameByValue).join(', ');

    const allValues = useMemo(() => options.map(({ value }) => value), [options]);

    return (
        <ControlledSelect
            name={formName}
            label={name}
            renderValue={(values) => formatSelectedValues(values as string[])}
            items={allValues}
            multiple
            inline
        >
            <MenuItem value="all">
                <Checkbox checked={currentValues?.length === options.length} />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    <Checkbox checked={currentValues?.includes(option.value) || false} />
                    <ListItemText primary={option.name} />
                </MenuItem>
            ))}
        </ControlledSelect>
    );
};
