import { QueryKeys, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { indexationsClient } from '../clients';
import { IIndexation } from '../models';

const client = indexationsClient;
const listKey = QueryKeys.Indexations;
const itemKey = QueryKeys.Indexation;

export function useIndexationList(...args: UseListParams<IIndexation>) {
    return useList(client, listKey, ...args);
}

export function useIndexation(...args: UseItemParams<IIndexation>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveIndexation() {
    return useSave(client, listKey, itemKey);
}
